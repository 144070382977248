import { Box, Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import React, { useContext } from 'react';
import Icon from '../../../../assets/icons/Icon';
import { useStyles } from '../Dialogs.styles';
import { green800, grey1000 } from '../../helper/MuiStyles';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HEADER } from '../../../../data/translation/TranslationHeader';
import { AppContext } from '../../contexts/AppContext';

export default function MobileMenu({ entry }) {
    const { language } = useContext(AppContext);
    const location = useLocation();

    const [showDialog, setShowDialog] = useState(false);

    const ToggleModal = () => {
        setShowDialog(!showDialog);
    };

    const classes = useStyles();


    return (
        <>
            <IconButton
                color="secondary"
                onClick={ToggleModal}
                className={classes.icon_button_tertiary}
            >
                <Icon width={24} height={24} iconName="Menu" fill={entry === "special" ? "#FFF" : green800} />
            </IconButton>

            <Dialog
                fullScreen
                fullWidth={true}
                maxWidth="md"
                open={showDialog}
                onClose={ToggleModal}
            >
                <DialogContent sx={{
                    padding: '0 24px 24px 24px',
                }}
                >
                    {/* HEADER */}
                    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.app_header}>
                        <Box display="flex" alignItems="center">
                            <Link to={`/`} onClick={ToggleModal} style={{ textDecoration: 'none' }}>
                                <Typography className={classes.logo}>socialspace</Typography>
                            </Link>
                        </Box>
                        <IconButton color="secondary" onClick={ToggleModal}>
                            <Icon width={24} height={24} iconName="Close" fill={grey1000} />
                        </IconButton>
                    </Box>

                    <Box pt={5} display="flex" flexDirection="column">
                        <Link to={`/workspaces`} onClick={ToggleModal} className={classes.link}>
                            <Box display="flex" alignItems="center">
                                {location.pathname === '/workspaces' &&
                                    <Icon width={20} height={20} iconName="ArrowRight" fill={green800} />
                                }
                                <Typography ml={location.pathname === '/workspaces' ? 1 : 0} variant="h6Mobile" style={{ color: location.pathname === '/workspaces' ? green800 : '' }}>
                                    {HEADER[HEADER.map(object => object.section).indexOf('workspaces')][language]}
                                </Typography>
                            </Box>
                        </Link>
                        <Link to={`/hosts`} onClick={ToggleModal} className={classes.link}>
                            <Box display="flex" alignItems="center">
                                {location.pathname === '/hosts' &&
                                    <Icon width={20} height={20} iconName="ArrowRight" fill={green800} />
                                }
                            <Typography ml={location.pathname === '/hosts' ? 1 : 0} variant="h6Mobile" style={{ color: location.pathname === '/hosts' ? green800 : '' }}>
                                {HEADER[HEADER.map(object => object.section).indexOf('hosts')][language]}
                            </Typography>
                            </Box>
                        </Link>
                        <Link to={`/aboutus`} onClick={ToggleModal} className={classes.link}>
                            <Box display="flex" alignItems="center">
                                {location.pathname === '/aboutus' &&
                                    <Icon width={20} height={20} iconName="ArrowRight" fill={green800} />
                                }
                            <Typography ml={location.pathname === '/aboutus' ? 1 : 0} variant="h6Mobile" style={{ color: location.pathname === '/aboutus' ? green800 : '' }}>
                                {HEADER[HEADER.map(object => object.section).indexOf('about')][language]}
                            </Typography>
                            </Box>
                        </Link>
                        <Link to={`/becomehost`} onClick={ToggleModal} className={classes.link}>
                            <Box display="flex" alignItems="center">
                                {location.pathname.includes("becomehost") &&
                                    <Icon width={20} height={20} iconName="ArrowRight" fill={green800} />
                                }
                            <Typography ml={location.pathname.includes("becomehost") ? 1 : 0} variant="h6Mobile" style={{ color: location.pathname.includes("becomehost") ? green800 : '' }}>
                                {HEADER[HEADER.map(object => object.section).indexOf('become')][language]}
                            </Typography>
                            </Box>
                        </Link>
                    </Box>

                </DialogContent>

            </Dialog >
        </>

    )
}
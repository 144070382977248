export const HOMEPAGE_DESKTOP = [
    {
        section: 'caption_1',
        eng: 'Book unique workspaces and',
        ger: 'socialspace ist eine einzigartige',
    },
    {
        section: 'caption_2',
        eng: 'support non-profit organisations.',
        ger: 'Coworking-Community mit der',
    },
    // {
    //     section: 'caption_1',
    //     eng: 'socialspace is a unique',
    //     ger: 'socialspace ist eine einzigartige',
    // },
    // {
    //     section: 'caption_2',
    //     eng: 'coworking platform with a',
    //     ger: 'Coworking-Community mit der',
    // },
    // {
    //     section: 'caption_3',
    //     eng: 'mission to foster',
    //     ger: 'Mission zur Stärkung von',
    // },
    {
        section: 'header_button',
        eng: 'Book workspaces',
        ger: 'Arbeitsplätze buchen',
    },
    {
        section: 'caption_section1',
        eng: 'socialspace is a unique coworking platform.',
        ger: 'lässt dich Arbeitsplätze einfach und bequem finden - und ermöglicht positive Impulse.',
    },
    // {
    //     section: 'caption_section1',
    //     eng: 'makes finding workspaces easy and convenient.',
    //     ger: 'lässt dich Arbeitsplätze einfach und bequem finden - und ermöglicht positive Impulse.',
    // },
    {
        section: 'text_section1',
        eng: 'Our core offering empowers individuals, non-profit organisations and corporates to find and book workspaces offered by non-profit organisations.',
        ger: 'Wohltätige Organisationen können ihre nicht genutzten Büros und Arbeitsplätze vorübergehend an Personen und Organisationen vermieten.',
    },
    // {
    //     section: 'text_section1',
    //     eng: 'socialspace is a unique coworking platform fostering collaboration and sustainability, by connecting individuals, businesses, and non-profit organisations, enabling positive impact. Our core offering empowers individuals, non-profit organisations and corporates to find & book workspaces offered by non-profit organisations.',
    //     ger: 'Wohltätige Organisationen können ihre nicht genutzten Büros und Arbeitsplätze vorübergehend an Personen und Organisationen vermieten.',
    // },
    {
        section: 'caption_section2',
        eng: 'Book your unique coworking experience.',
        ger: 'Buche dein einzigartiges Coworking-Erlebnis.',
    },
    {
        section: 'text_section2',
        eng: 'We aim to make booking a workspace easy and convenient. It just takes three steps.',
        ger: 'Wir wollen die Buchung eines Arbeitsplatzes bei einer gemeinnützigen Organisation so einfach und bequem wie möglich machen.',
    },
    {
        section: 'title_step1_section2',
        eng: 'Search',
        ger: 'Suchen',
    },
    {
        section: 'text_step1_section2',
        eng: 'Explore and choose unique workspaces that suit your needs at a host you would like to support.',
        ger: 'Suche nach einem Arbeitsplatz, der deinen Bedürfnissen entspricht, oder nach einer Organisation, die du unterstützen möchtest.',
    },
    {
        section: 'title_step2_section2',
        eng: 'Request',
        ger: 'Anfragen',
    },
    {
        section: 'text_step2_section2',
        eng: 'Send a reservation request to the selected host. The host will contact you and confirm the booking.',
        ger: 'Sende eine Anfrage an die gemeinnützige Organisation über socialspace. Diese wird dich kontaktieren und die Buchung bestätigen.',
    },
    {
        section: 'title_step3_section2',
        eng: 'Collaborate',
        ger: 'Arbeiten',
    },
    {
        section: 'text_step3_section2',
        eng: 'Enjoy your unique coworking environment while enabling positive impact.',
        ger: 'Genieße das einzigartige Arbeitsumfeld bei der gemeinnützigen Organisation und leiste gleichzeitig einen positiven und nachhaltigen Beitrag.',
    },
    {
        section: 'caption_section3',
        eng: 'Why socialspace?',
        ger: 'Warum socialspace?',
    },
    {
        section: 'text_section3',
        eng: "socialspace redefines coworking, allowing you to support non-profit organisations simply by choosing to work in their unique office environments. It's a win-win: co-workers gain an inspiring workplace that aligns with their values and non-profit organisations gain valuable resources.",
        ger: 'Gemeinnützige Organisationen spielen eine wichtige Rolle in unserer Gesellschaft, indem sie sich für die verschiedenen sozialen, ökologischen und humanitären Probleme einsetzen, mit denen die Welt heute konfrontiert ist. Ohne sie hätten viele Menschen Schwierigkeiten, Zugang zu wichtigen Dienstleistungen zu erhalten, und wichtige Themen würden möglicherweise nicht die Aufmerksamkeit erhalten, die sie verdienen.',
    },
    {
        section: 'title_benefit1_section3',
        eng: 'Work in unique workspaces',
        ger: 'Keine Verschwendung von ungenutztem Raum',
    },
    {
        section: 'text_benefit1_section3',
        eng: "Work in and enjoy unique and inspiring coworking environments.",
        ger: 'Anstatt den Raum leer und ungenutzt zu lassen, kann er von anderen genutzt werden. Dies kann den Energieverbrauch, den Abfall und den CO2-Fußabdruck verringern und ist zudem kosteneffizient. Wenn wir diese Praktiken anwenden, können wir zu einer nachhaltigeren Zukunft beitragen.',
    },
    {
        section: 'title_benefit2_section3',
        eng: 'Support your charity',
        ger: 'Unterstütze deine gemeinnützige Organisation',
    },
    {
        section: 'text_benefit2_section3',
        eng: "Support non-profit organisations financially by working in their workspaces.",
        ger: 'Gemeinnützige Organisationen sind in der Regel nicht gewinnorientiert und auf die Großzügigkeit von Einzelpersonen, Stiftungen und Unternehmen angewiesen, um ihre Aktivitäten zu finanzieren. Ohne Spenden wären sie nicht in der Lage, zu arbeiten oder ihren Auftrag zu erfüllen. Mit Ihren Beiträgen können sie ihre Abhängigkeit von Spenden verringern.',
    },
    {
        section: 'title_benefit3_section3',
        eng: 'Join a unique community',
        ger: 'Tritt einer einzigartigen Community bei',
    },
    {
        section: 'text_benefit3_section3',
        eng: "Join a purpose-driven community that encourages knowledge sharing, networking and collaborative opportunities.",
        ger: 'Der Wertbeitrag von socialspace liegt in seinem individuellen Ansatz, eine Plattform bereitzustellen, auf der Coworker Räume finden können, die mit ihren Werten, ihrer Kultur und ihren Zielen übereinstimmen, während eine Gemeinschaft gefördert wird, die den Wissensaustausch, das Networking und die Möglichkeiten der Zusammenarbeit unterstützt.',
    },
    {
        section: 'vision_header',
        eng: 'Our mission is to help non-profit organisations utilise resources efficiently by enabling them to generate income through office letting, fostering collaboration and sustainable impact.',
        ger: 'Unsere Vision ist es, die Abhängigkeit von wohltätigen Organisationen zu verringern und sie zu befähigen ihre Vision zu verfolgen und ihre Wirkung zu maximieren.',
    },
    {
        section: 'vision_text',
        eng: 'Our vision is to create a network of sustainable workspaces that nurture innovation and collaboration, enabling non-profit organisations to diversify income streams and drive positive change.',
        ger: 'Dabei streben wir nach positiven sozialen, wirtschaftlichen und ökologischen Auswirkungen.',
    },
    {
        section: 'vision_button',
        eng: 'Learn more about us',
        ger: 'Lerne uns kennen',
    },
    // {
    //     section: 'stats1',
    //     eng: 'generated for non-profit organisations',
    //     ger: 'für gemeinnützige Organisationen geschaffen',
    // },
    {
        section: 'stats2',
        eng: 'listed non-profit organisations',
        ger: 'gelistete gemeinnützige Organisation',
    },
    {
        section: 'stats3',
        eng: 'coworking cities',
        ger: 'Coworking Städte',
    },
    {
        section: 'caption1_section4',
        eng: 'Explore and support non-profit organisations around the world.',
        ger: 'Die Welt ist auf wohltätige Organisationen angewiesen.',
    },
    // {
    //     section: 'caption1_section4_old',
    //     eng: 'The world is dependent on non-profit organisations.',
    //     ger: 'Die Welt ist auf wohltätige Organisationen angewiesen.',
    // },
    // {
    //     section: 'caption2_section4_old',
    //     eng: 'Non-profit organistaions are dependent on you.',
    //     ger: 'Wohltätige Organisationen sind auf externe Ressourcen angewiesen.',
    // },
    {
        section: 'text_section4',
        eng: 'socialspace supports a network of non-profit organisations united by the common goal of creating positive social, economic and environmental impact. Help us grow by telling your friends and your favourite organisations about socialspace.',
        ger: 'Wohltätige Organisationen können ihre nicht genutzten Büros und Arbeitsplätze vorübergehend an Personen und Organisationen vermieten.',
    },
    {
        section: 'footer_text',
        eng: 'While we start as a private project, our ambition is to evolve socialspace into a purpose-driven company, potentially operating as a not-for-profit social enterprise. Our commitment is to make a positive impact in the non-profit industry and drive innovation in the coworking world.',
        ger: 'socialspace begann als privates Projekt und wir befinden uns derzeit in der Prototyp-Testphase, die von Mai bis Juli 2023 in Berlin läuft. Wir schätzen Eure Teilnahme und Euer Feedback während dieser wichtigen Phase, da es uns helfen wird, unser Angebot zu verfeinern und zu verbessern, um den wachsenden Bedürfnissen unserer Community-Mitglieder gerecht zu werden. Besonders gespannt sind wir auf den Austausch mit den einzelnen Coworkern, von denen wir glauben, dass sie unser einzigartiges Coworking-Konzept maßgeblich mitgestalten werden.',
    },
    {
        section: 'terms',
        eng: 'Terms of use',
        ger: 'Nutzungsbedingungen',
    },
    {
        section: 'imprint',
        eng: 'Imprint',
        ger: 'Impressum',
    },
    {
        section: 'privacy',
        eng: 'Privacy policy',
        ger: 'Datenschutzerklärung',
    },
];
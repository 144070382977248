import React, { useState } from 'react'

const AppContext = React.createContext(null);

const AppProvider = ({ children }) => {
    const [language, setLanguage] = useState('eng');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    return (
        <AppContext.Provider value={{
            language, setLanguage,
            snackbarMessage, setSnackbarMessage,
            totalPrice, setTotalPrice,
            selectedDays, setSelectedDays,
            selectedMonths, setSelectedMonths,
            isSnackbarOpen, setIsSnackbarOpen,
        }}
        >
            {children}
        </AppContext.Provider>
    )
};

export {AppContext, AppProvider}
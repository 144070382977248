import { makeStyles } from '@mui/styles';
import { green100, green1000 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    footer_container: {
        backgroundColor: green1000,
        height: '40px',
    },
    footer: {
        color: green100,
    },
    icon_button_tertiary: {
        padding: '0 8px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0)',
        },
    },
    link: {
        marginLeft: '32px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },
}));
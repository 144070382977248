import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Grid, Paper, Box, Typography } from '@mui/material';
import { HOMEPAGE_WORKSPACES, HOSTS } from '../../../data/data';
import { useStyles } from './HostPageDesktop.styles';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import HostProfile from '../../../components/HostPage/HostProfile/HostProfile';
import { green1000, grey800 } from '../../../components/other/helper/MuiStyles';
import WorkspaceBoxMobile from '../../../components/WorkspaceBox/Homepage/mobile/WorkspaceBoxMobile';
import FooterDesktop from '../../../components/Footer/desktop/FooterDesktop';

export default function HostPageDesktop() {

    let { id } = useParams();
    const hostDetail = HOSTS.find(object => {
        return object.hostId === id;
    });

    const marginProp = '10vw';
    const styleProps = {
        backgroundImg: IMAGE_MAP.PARTNER_HEADER[hostDetail.name],
    };

    const classes = useStyles(styleProps);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Grid item xs={12} display="grid">
            <Paper className={classes.paper}>

                <Box display="flex" alignItems="center" justifyContent="center" className={classes.background_image}></Box>

                {/* HOST PROFILE & DESCRIPTION */}
                <Box mx={marginProp} mt={10} display="flex" justifyContent="space-between">
                    <Box display="flex" className={classes.host_box}>
                        <HostProfile host={hostDetail} />
                    </Box>
                    <Box pl={10} pr={'10vw'} display="flex" flexDirection="column">
                        {/* IMPACT */}
                        <Box mx={3} py={5} display="flex" flexDirection="column">
                            <Typography variant='h4' style={{ color: green1000 }}>
                                Host description
                            </Typography>
                <Typography pt={3} variant='body1' style={{ color: grey800, whiteSpace: 'pre-line' }}>
                        {hostDetail.longDescription}
                    </Typography>
                        </Box>
                    </Box>
                </Box>

                {/* WORKSPACES */}
                <Box mx={marginProp} pt={10} display="flex" flexDirection="column">
                    <Typography variant='h4Mobile' style={{ color: green1000 }}>
                        Workspaces of host
                    </Typography>
                </Box>
                <Box mx={marginProp} mb={7} display="flex" flexWrap="wrap">
                    {HOMEPAGE_WORKSPACES.filter(option => option.host === hostDetail.name).map((option, index) => (
                        <Link to={`/workspace/${option.socialspaceId}`} key={option.socialspaceId} className={classes.socialspace_box}>
                            <WorkspaceBoxMobile key={option.socialspaceId} socialspace={option} />
                        </Link>
                    ))}
                </Box>

                {/* FOOTER */}
                <FooterDesktop />


            </Paper>
        </Grid >
    )
}
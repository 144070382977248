import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    socialspace_box: {
        textDecoration: 'none',
        marginTop: '40px',
    },
    map_container: {
        width: '100%',
        height: '100vh',
    },
    host_box:{    
        maxHeight: 'calc(100vh - 80px)', 
        width: '50vw', 
        overflow: 'auto',    
        msOverflowStyle: 'none', /* IE and Edge */
        scrollbarWidth: 'none', /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },
    list_box: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: 'calc(100vh - 80px)',
        msOverflowStyle: 'none', /* IE and Edge */
        scrollbarWidth: 'none', /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}));
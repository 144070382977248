import makeStyles from "@mui/styles/makeStyles";
import { green100 } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    benefits_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgBenefitsIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '60vw',
        minWidth: '60vw',
    },
    criteria_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgCriteriaIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '60vw',
        minWidth: '60vw',
    },
    footer: {
        color: green100,
    },
    icon_button_tertiary: {
        padding: '0 8px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0)',
        },
    },
    link: {
        textDecoration: 'none',
        paddingBottom: '4px',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },  
    logo: {
        fontFamily: 'Poppins600',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.03em',
        color: '#FFF',
    }, 
    process_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgProcessIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '60vw',
        minWidth: '60vw',
    },
    support_illustration:{
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgSupportIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '60vw',
        minWidth: '60vw',
    },
}));
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './FeatureBox.styles';
import { grey1000, grey800 } from '../../other/helper/MuiStyles';
import Icon from '../../../assets/icons/Icon';

export default function FeatureBox({ officeTime, officeDescription, features, workspaceId }) {

    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column">
            <Typography pr={'7.5vw'} variant="body1" style={{ color: grey800, whiteSpace: 'pre-line' }}>
                {officeDescription}
            </Typography>
            <Box mt={4} display="flex">
                <Box pr={6} display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center">
                        <Icon width={20} height={20} iconName="Clock" fill={grey800} />
                        <Typography ml={2} variant='body1' style={{ color: grey800 }}>Office hours:</Typography>
                    </Box>
                    <Box mt={3} display="flex" alignItems="center">
                        <Icon width={20} height={20} iconName="Calendar" fill={grey800} />
                        <Typography ml={2} variant='body1' style={{ color: grey800 }}>Office days:</Typography>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center">
                        <Typography variant='body1'>{officeTime.hours}</Typography>
                    </Box>
                    <Box mt={3} display="flex" alignItems="center">
                        <Typography variant='body1'>{officeTime.days}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box mt={2.5} display="flex" flexWrap="wrap">
                <Box display="flex" alignItems="center" className={classes.feature}>
                    <Icon width={20} height={20} iconName={'Wifi'} fill={grey1000} />
                    <Typography ml={1} variant="body1">Wifi</Typography>
                </Box>
                {workspaceId === ('2' || '3') &&
                    <Box display="flex" alignItems="center" className={classes.feature}>
                        <Icon width={20} height={20} iconName={'Monitor'} fill={grey1000} />
                        <Typography ml={1} variant="body1">Monitor</Typography>
                    </Box>
                }
                {workspaceId !== '4' &&
                    <>
                        <Box display="flex" alignItems="center" className={classes.feature}>
                            <Icon width={20} height={20} iconName={'Printer'} fill={grey1000} />
                            <Typography ml={1} variant="body1">Printer</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" className={classes.feature}>
                            <Icon width={20} height={20} iconName={'People'} fill={grey1000} />
                            <Typography ml={1} variant="body1">Meeting room</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" className={classes.feature}>
                            <Icon width={20} height={20} iconName={'Coffee'} fill={grey1000} />
                            <Typography ml={1} variant="body1">Coffee</Typography>
                        </Box>
                    </>
                }
                {workspaceId === '4' &&
                    <>
                        <Box display="flex" alignItems="center" className={classes.feature}>
                            <Icon width={20} height={20} iconName={'Coffee'} fill={grey1000} />
                            <Typography ml={1} variant="body1">Shower</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" className={classes.feature}>
                            <Icon width={20} height={20} iconName={'Coffee'} fill={grey1000} />
                            <Typography ml={1} variant="body1">Bike</Typography>
                        </Box>
                    </>
                }
                <Box display="flex" alignItems="center" className={classes.feature}>
                    <Icon width={20} height={20} iconName={'Coffee'} fill={grey1000} />
                    <Typography ml={1} variant="body1">Kitchen</Typography>
                </Box>
            </Box>
        </Box>
    )
}
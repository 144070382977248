import React from 'react';
import { Box, Radio, Typography } from '@mui/material';
import { useStyles } from './MonthCalendarBoxMobile.styles';
import { green1000, grey200 } from '../../other/helper/MuiStyles';

export default function MonthCalendarBoxMobile({ currency, data, handleMonthChange, selectedMonths }) {

    const classes = useStyles();
    
    return (
        <Box display="flex" flexDirection="column" flexGrow={1} >
            {data.months.slice(5, 12).map((option, index) => {
                return (
                    <Box key={index} display="flex" alignItems="center" justifyContent="space-between" className={classes.month_box} onClick={() => handleMonthChange(option.month, option.status, option.price)} style={{ border: selectedMonths.includes(option.month) ? `2px solid ${green1000}` : `1px solid ${grey200}`, }}>
                        <Box display="flex" alignItems="center" >
                            <Radio
                                disableRipple
                                checked={selectedMonths.includes(option.month)}
                                value="selectDay"
                            />
                            <Typography ml={2} variant='h5Mobile' style={{width: '-webkit-fill-available', }}>
                                {option.month}
                            </Typography>
                        </Box>
                        <Typography variant='h5Mobile' style={{color: green1000}}>
                            {currency}{option.price}
                        </Typography>
                    </Box>
                )
            })}
        </Box>
    )
}

import makeStyles from "@mui/styles/makeStyles";
import { grey600 } from "../../other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    day_box: {
        width: '40px',
        height: '40px',
        borderRadius: '20px',
        // boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    day_name_box: {
        color: grey600,
        width: 'calc(100vw - 48px)/7)',
        textAlign: 'center',
        marginBottom: '24px'
    },
    month_box: {
        minWidth: '100px',
        width: '6vw',
        minHeight: '200px',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        padding: '16px',
    },
}));
import makeStyles from "@mui/styles/makeStyles";
import { green100 } from "../other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    paper: {
        minHeight: '100vh',
        backgroundColor: '#FFF',
    },
    socialspace_header: {
        // maxWidth: '70vw',
    },
    host_box: {
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
    },
    footer: {
        color: green100,
    },
    link: {
        marginLeft: '32px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },
    host_logo: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.hostImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '75px',
        width: 'auto',
    },
    office_picture1: {
        backgroundImage: props => `url(${props.officePicture1})`,
        width: '40vw',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        borderRadius: '8px',
        filter: 'drop-shadow(0px 5px 15px rgba(54, 79, 81, 0.2))',
    },
    office_picture2: {
        backgroundImage: props => `url(${props.officePicture2})`,
        height: props => props.smallPictureHeight,
        width: 'calc(100vw - 40vw - 10vw - 328px - 80px)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        borderRadius: '8px',
        filter: 'drop-shadow(0px 5px 15px rgba(54, 79, 81, 0.2))',
    },
    office_picture3: {
        backgroundImage: props => `url(${props.officePicture3})`,
        height: props => props.smallPictureHeight,
        width: 'calc(100vw - 40vw - 10vw - 328px - 80px)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        borderRadius: '8px',
        filter: 'drop-shadow(0px 5px 15px rgba(54, 79, 81, 0.2))',
    },
}));
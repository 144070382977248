import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useStyles } from '../CalendarBox.styles';
import { green100, green600, green800, grey1000, grey400, grey50, grey800 } from '../../../other/helper/MuiStyles';
import Icon from '../../../../assets/icons/Icon';

export default function DayCalendarBox({ currency, data, handleDayChange, checkIfSelected, currentMonthIndex, handlePrevMonth, allAvailableMonths, handleNextMonth }) {

    const mondayDays = data.days.filter(object => object.day === "Monday");
    const tuesdayDays = data.days.filter(object => object.day === "Tuesday");
    const wednesdayDays = data.days.filter(object => object.day === "Wednesday");
    const thursdayDays = data.days.filter(object => object.day === "Thursday");
    const fridayDays = data.days.filter(object => object.day === "Friday");
    const saturdayDays = data.days.filter(object => object.day === "Saturday");
    const sundayDays = data.days.filter(object => object.day === "Sunday");

    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} className={classes.calendar_box}>
            <Box mb={3} display="flex" alignItems="center" justifyContent="center">
                {currentMonthIndex > 0 ?
                    <IconButton
                        color="secondary"
                        onClick={handlePrevMonth}
                    >
                        <Icon width={20} height={20} iconName="ArrowLeft" fill={grey1000} />
                    </IconButton>
                    :
                    <Box width='36px'> </Box>
                }
                <Typography mx={2} variant="h6" style={{ width: '120px', textAlign: 'center' }}>{allAvailableMonths[currentMonthIndex]}</Typography>
                {currentMonthIndex < allAvailableMonths.length - 1 ?
                    <IconButton
                        color="secondary"
                        onClick={handleNextMonth}
                    >
                        <Icon width={20} height={20} iconName="ArrowRight" fill={grey1000} />
                    </IconButton>
                    :
                    <Box width='36px'> </Box>
                }
            </Box>
            
        <Box display="flex" justifyContent="space-between" flexGrow={1}>
            <Box display="flex" flexDirection="column">
                <Typography variant="body1" className={classes.day_name_box}>
                    Mon
                </Typography>
                {mondayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && mondayDays[0].date > 1) ? 14 : 0} mb={index === (mondayDays.length - 1) ? 0 : 4} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" onClick={() => handleDayChange(option)} className={classes.day_box} style={{ cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : grey400 }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }} >
                            <Typography mt={1} variant="body2" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? grey50 : grey800 }}>
                                {option.date}
                            </Typography>
                            <Typography mb={1} variant="h4" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? '#FFF' : '', opacity: option.status === 'Available' ? 1 : 0 }}>
                                {currency}{option.price}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body1" className={classes.day_name_box}>
                    Tue
                </Typography>
                {tuesdayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && tuesdayDays[0].date > 2) ? 14 : 0} mb={index === (tuesdayDays.length - 1) ? 0 : 4} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" onClick={() => handleDayChange(option)} className={classes.day_box} style={{ cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : grey400 }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }} >
                            <Typography mt={1} variant="body2" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? grey50 : grey800 }}>
                                {option.date}
                            </Typography>
                            <Typography mb={1} variant="h4" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? '#FFF' : '', opacity: option.status === 'Available' ? 1 : 0 }}>
                                {currency}{option.price}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body1" className={classes.day_name_box}>
                    Wed
                </Typography>
                {wednesdayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && wednesdayDays[0].date > 3) ? 14 : 0} mb={index === (wednesdayDays.length - 1) ? 0 : 4} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" onClick={() => handleDayChange(option)} className={classes.day_box} style={{ cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : grey400 }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }} >
                            <Typography mt={1} variant="body2" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? grey50 : grey800 }}>
                                {option.date}
                            </Typography>
                            <Typography mb={1} variant="h4" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? '#FFF' : '', opacity: option.status === 'Available' ? 1 : 0 }}>
                                {currency}{option.price}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body1" className={classes.day_name_box}>
                    Thu
                </Typography>
                {thursdayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && thursdayDays[0].date > 4) ? 14 : 0} mb={index === (thursdayDays.length - 1) ? 0 : 4} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" onClick={() => handleDayChange(option)} className={classes.day_box} style={{ cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : grey400 }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }} >
                            <Typography mt={1} variant="body2" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? grey50 : grey800 }}>
                                {option.date}
                            </Typography>
                            <Typography mb={1} variant="h4" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? '#FFF' : '', opacity: option.status === 'Available' ? 1 : 0 }}>
                                {currency}{option.price}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body1" className={classes.day_name_box}>
                    Fri
                </Typography>
                {fridayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && fridayDays[0].date > 5) ? 14 : 0} mb={index === (fridayDays.length - 1) ? 0 : 4} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" onClick={() => handleDayChange(option)} className={classes.day_box} style={{ cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : grey400 }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }} >
                            <Typography mt={1} variant="body2" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? grey50 : grey800 }}>
                                {option.date}
                            </Typography>
                            <Typography mb={1} variant="h4" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? '#FFF' : '', opacity: option.status === 'Available' ? 1 : 0 }}>
                                {currency}{option.price}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body1" className={classes.day_name_box}>
                    Sat
                </Typography>
                {saturdayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && saturdayDays[0].date > 6) ? 14 : 0} mb={index === (saturdayDays.length - 1) ? 0 : 4} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" onClick={() => handleDayChange(option)} className={classes.day_box} style={{ cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : grey400 }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }} >
                            <Typography mt={1} variant="body2" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? grey50 : grey800 }}>
                                {option.date}
                            </Typography>
                            <Typography mb={1} variant="h4" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? '#FFF' : '', opacity: option.status === 'Available' ? 1 : 0 }}>
                                {currency}{option.price}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body1" className={classes.day_name_box}>
                    Sun
                </Typography>
                {sundayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && sundayDays[0].date > 7) ? 14 : 0} mb={index === (sundayDays.length - 1) ? 0 : 4} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" onClick={() => handleDayChange(option)} className={classes.day_box} style={{ cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : grey400 }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }} >
                            <Typography mt={1} variant="body2" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? grey50 : grey800 }}>
                                {option.date}
                            </Typography>
                            <Typography mb={1} variant="h4" style={{ color: (option.status !== 'Available' || checkIfSelected(option)) ? '#FFF' : '', opacity: option.status === 'Available' ? 1 : 0 }}>
                                {currency}{option.price}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            </Box>
        </Box>
    )
}
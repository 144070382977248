import React from "react";

import { ReactComponent as CalendarIcon } from './calendar-regular.svg';
import { ReactComponent as ClipboardCheckIcon } from './clipboard-check-solid.svg';
import { ReactComponent as ClockIcon } from './clock-regular.svg';
import { ReactComponent as CloseIcon } from './cross-solid.svg';
import { ReactComponent as DoveIcon } from './dove-solid.svg';
import { ReactComponent as EarthIcon } from './earth-solid.svg';
import { ReactComponent as EducationIcon } from './graduation-cap-solid.svg';
import { ReactComponent as HeartIcon } from './heart-solid.svg';
import { ReactComponent as InstagramIcon } from './instagram-brands.svg';
import { ReactComponent as LanguageIcon } from './language-solid.svg';
import { ReactComponent as LeafIcon } from './leaf-solid.svg';
import { ReactComponent as LinkedInIcon } from './linkedin-brands.svg';
import { ReactComponent as OtherFeatureIcon } from './poo-storm-solid.svg';
import { ReactComponent as PawIcon } from './paw-solid.svg';
import { ReactComponent as PrintIcon } from './print-solid.svg';
import { ReactComponent as QuestionIcon } from './question-solid.svg';
import { ReactComponent as SociabilityIcon } from './people-group-solid.svg';
import { ReactComponent as SportIcon } from './football-solid.svg';
import { ReactComponent as SuccessIcon } from './circle-check-regular.svg';
import { ReactComponent as TwitterIcon } from './twitter-brands.svg';
// NEW ICONS
import { ReactComponent as DollarCircle } from './dollar-circle.svg';
import { ReactComponent as ElementPlus } from './element-plus.svg';
import { ReactComponent as Gift } from './gift.svg';
import { ReactComponent as ShieldTick } from './shield-tick.svg';
import { ReactComponent as StatusUp } from './status-up.svg';
import { ReactComponent as Share } from './share.svg';
import { ReactComponent as GlobalSearch } from './global-search.svg';
import { ReactComponent as ConvertShape } from './convertshape-2.svg';
import { ReactComponent as Verify } from './verify.svg';
import { ReactComponent as MedalStar } from './medal-star.svg';
import { ReactComponent as HeartTick } from './heart-tick.svg';
import { ReactComponent as Information } from './information.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as Global } from './global.svg';
import { ReactComponent as Award } from './award.svg';
import { ReactComponent as Blur } from './blur.svg';
import { ReactComponent as Lovely } from './lovely.svg';
import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Wifi } from './wifi.svg';
import { ReactComponent as Printer } from './printer.svg';
import { ReactComponent as Coffee } from './coffee.svg';
import { ReactComponent as People } from './people.svg';
import { ReactComponent as Monitor } from './monitor.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as LinkedIn } from './linkedin.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as UserOctagon } from './user-octagon.svg';

const icons = {
    CalendarIcon: CalendarIcon,
    ClipboardCheckIcon: ClipboardCheckIcon,
    ClockIcon: ClockIcon,
    CloseIcon: CloseIcon,
    DoveIcon: DoveIcon,
    EarthIcon: EarthIcon,
    EducationIcon: EducationIcon,
    HeartIcon: HeartIcon,
    InstagramIcon: InstagramIcon,
    LanguageIcon: LanguageIcon,
    LeafIcon: LeafIcon,
    LinkedInIcon: LinkedInIcon,
    OtherFeatureIcon: OtherFeatureIcon,
    PawIcon: PawIcon,
    PrintIcon: PrintIcon,
    QuestionIcon: QuestionIcon,
    SociabilityIcon: SociabilityIcon,
    SportIcon: SportIcon,
    SuccessIcon: SuccessIcon,
    TwitterIcon: TwitterIcon,
    // NEW ICONS - BULK
    ConvertShape: ConvertShape,
    DollarCircle: DollarCircle,
    ElementPlus: ElementPlus,
    Gift: Gift,
    GlobalSearch: GlobalSearch, 
    HeartTick: HeartTick,
    MedalStar: MedalStar,
    ShieldTick: ShieldTick,
    Share: Share,
    StatusUp: StatusUp,
    Verify: Verify,
    Information: Information,
    ArrowRight: ArrowRight,
    ArrowLeft: ArrowLeft,
    Global: Global,
    Award: Award,
    Blur: Blur,
    Lovely: Lovely,
    ArrowUp: ArrowUp,
    Menu: Menu,
    Close: Close,
    Clock: Clock,
    Calendar: Calendar,
    Wifi: Wifi,
    Printer: Printer,
    Coffee: Coffee,
    People: People,
    Twitter: Twitter,
    LinkedIn: LinkedIn,
    Instagram: Instagram,
    Monitor: Monitor,
    UserOctagon: UserOctagon,
}

export default function Icon(props) {
    const MyIcon = icons[props.iconName];
    return <MyIcon width={props.width ? props.width : 24} height={props.height ? props.height : 24} fill={props.fill} />;
}
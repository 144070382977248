import { makeStyles } from '@mui/styles';
import { green800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    app_header: {
        backgroundColor: '#FFF',
        height: '80px',
    },
    logo: {
        fontFamily: 'Poppins600',
        fontSize: '16px',
        lineHeight: '24px',
        color: green800,
    },    
}));
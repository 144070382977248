import React, { useContext, useEffect } from 'react';
import { Grid, Paper, Box, Typography } from '@mui/material';
import { useStyles } from './AboutUsPageDesktop.styles';
import { green1000, green50, green800, grey800 } from '../../../components/other/helper/MuiStyles';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import { ABOUTUS_DESKTOP } from '../../../data/translation/TranslationAboutUsDesktop';
import { AppContext } from '../../../components/other/contexts/AppContext';
import FooterDesktop from '../../../components/Footer/desktop/FooterDesktop';
import EmailRegistration from '../../../components/other/dialogs/EmailRegistration/EmailRegistration';

export default function AboutUsPageDesktop() {

    const { language } = useContext(AppContext);

    const horizontalMargin = '10vw';

    const styleProps = {
        backgroundImgMission: IMAGE_MAP.GENERAL['Mission'],
        backgroundImgMacPreview: IMAGE_MAP.GENERAL['MacPreview'],
        backgroundImgTeam: IMAGE_MAP.GENERAL['Team'],
        backgroundImgRoadtrip: IMAGE_MAP.ILLUSTRATIONS['RoadtripIllustration'],
        backgroundImgFooter: IMAGE_MAP.GENERAL['Mountain'],
    };

    const classes = useStyles(styleProps);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Grid item xs={12} display="grid">
            <Paper className={classes.paper}>

                {/* WELCOME */}
                <Grid container style={{ minHeight: 'calc(100vh - 80px)' }}>
                    <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                        <Box ml={horizontalMargin} py={8} display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
                            <Typography pr={8} variant='h1' style={{ color: green1000 }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('header')][language]}
                            </Typography>
                            <Typography pr={8} mt={3} variant='body1' style={{ color: grey800 }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('header_text')][language]}
                            </Typography>
                            <Box mt={5} display="flex">
                                <EmailRegistration />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                        <div className={classes.mac_preview} title="MacPreview"></div>
                    </Grid>
                </Grid>

                {/* STATEMENTS */}
                <Grid container>
                    <Grid item xs={6} display="flex" alignItems="center" style={{ backgroundColor: green50 }}>
                        <Box ml={horizontalMargin} py={8} pr={'10vw'} display="flex" flexDirection="column">
                            <Typography variant="h4" style={{ color: green1000 }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('phrase1')][language]}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display="flex" alignItems="center" justifyContent="center" style={{ backgroundColor: green1000 }}>
                        <Box mr={horizontalMargin} py={8} pl={'10vw'} display="flex" flexDirection="column">
                            <Typography variant="h4" style={{ color: green50 }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('phrase2')][language]}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                {/* VISION */}
                <Grid container style={{ margin: '48px 0' }}>
                    <Grid item xs={7} display="flex" alignItems="center" justifyContent="center">
                        <Box pr={'10vw'} py={8} display="flex" flexDirection="column" justifyContent="center" flexGrow={1} style={{ paddingLeft: '10vw' }}>
                            <Typography variant='h1' style={{ color: green1000 }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('vision_header')][language]}
                            </Typography>
                            <Typography mt={3} variant='body1' style={{ color: grey800 }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('vision_text')][language]}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid pr={'10vw'} item xs={5} display="flex" alignItems="center" justifyContent="center">
                        <div className={classes.mission_illustration} title="MissionIllustration"></div>
                    </Grid>
                </Grid>

                {/* WHY */}
                <Grid container style={{ backgroundColor: green50 }}>

                    <Grid py={8} pl={'10vw'} item xs={5} display="flex" alignItems="center" justifyContent="center">
                        <div className={classes.team_photo} title="TeamPhoto"></div>
                    </Grid>
                    <Grid px={'10vw'} item xs={7} display="flex" alignItems="center" justifyContent="center">
                        <Box py={8} pr={'5vw'} display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
                            <Typography variant='h1' style={{ color: green1000 }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('title_section4')][language]}
                            </Typography>
                            <Typography mt={3} variant='body1' style={{ color: grey800 }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('text_section4')][language]}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                {/* TESTING PHASE */}
                <Grid container style={{ margin: '48px 0' }}>
                    <Grid item xs={7} display="flex" alignItems="center" justifyContent="center">
                        <Box px={'10vw'} py={8} display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
                            <Typography variant='h1' style={{ color: green1000 }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('title_section5')][language]}
                            </Typography>
                            <Typography mt={3} variant='body1' style={{ color: grey800 }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('text1_section5')][language]}
                            </Typography>
                            <Typography mt={5} variant='h6' style={{ color: green800 }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('text2_section5')][language]}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid pr={'10vw'} item xs={5} display="flex" alignItems="center" justifyContent="center">
                        <div className={classes.roadtrip_illustration} title="RoadtripIllustration"></div>
                    </Grid>
                </Grid>

                {/* MISSION */}
                <Box display="flex" className={classes.background_image_footer}>
                    <Box display="flex" flexGrow={1} className={classes.image_content_container}>
                        <Box mx={'10vw'} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                            <Typography mt={'30vh'} variant="h1">
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('title_section6')][language]}
                            </Typography>
                            <Typography mb={'12.5vh'} mx={'10vw'} variant="body1" style={{ textAlign: 'center', color: '#FFF' }}>
                                {ABOUTUS_DESKTOP[ABOUTUS_DESKTOP.map(object => object.section).indexOf('text_section6')][language]}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {/* FOOTER */}
                <FooterDesktop />

            </Paper >
        </Grid >
    )
}
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    host_box: {
        width: '328px',        
        minHeight: '440px', 
        },
    partner_logo: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.partnerImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '100px',
        width: 'auto',
    },
}));
import makeStyles from "@mui/styles/makeStyles";
import { green100, green1000, grey50 } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    paper: {
        minHeight: '100vh',
        backgroundColor: '#FFF',
    },
    socialspace_header: {
        // maxWidth: '70vw',
    },
    host_box: {
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
    },
    feature: {
        backgroundColor: grey50,
        height: '40px',
        padding: '0 16px 0 12px',
        borderRadius: '8px',
        alignSelf: 'flex-start',
        marginRight: '12px',
        marginTop: '12px',
    },
    footer: {
        color: green100,
    },
    general_link: {
        textDecoration: 'none',
    },
    link: {
        textDecoration: 'none',
        paddingBottom: '4px',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },
    phone_number: {
        fontFamily: 'Poppins600',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.03em',
        color: green1000,
        textDecoration: `underline ${green1000}`,
    },
    host_logo: {
        display: 'flex',
        backgroundPosition: 'left',
        backgroundImage: props => `url(${props.hostImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '64px',
        width: 'auto',
    },
    contact_picture: {
        display: 'flex',
        backgroundPosition: 'left',
        backgroundImage: props => `url(${props.contactImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '72px',
        width: '72px',
    },
    day_month_box: {
        height: 'auto',
        boxSizing: 'border-box',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: props => props.hoverColor,
        },
    },
}));
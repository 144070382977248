import makeStyles from "@mui/styles/makeStyles";
import { green100 } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    icon_button_tertiary: {
        padding: '0 8px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0)',
        },
    },
    footer: {
        color: green100,
    },
    logo: {
        fontFamily: 'Poppins600',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.03em',
        color: '#FFF',
    },
}));
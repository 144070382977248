import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './PriceTimeBox.styles';
import { green1000, grey200 } from '../../other/helper/MuiStyles';
import { DayMonthContext } from '../../other/contexts/DayMonthContext';
import ReservationFormDesktop from '../../other/dialogs/ReservationForm/ReservationFormDesktop';

export default function PriceTimeBox({ workspace, totalPrice, selectedMonths, selectedDays, amountOfDays, setIsSnackbarOpen }) {

    const { isMonthSet } = useContext(DayMonthContext);

    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" className={classes.price_time_box}>
            <Box display="flex" flexDirection="column">

                {/* PRICE HEADER */}
                <Box mt={4} px={3} display="flex" alignItems="baseline">
                    <Typography variant='h2'>
                    {workspace.currency}{totalPrice === undefined ? 0 : totalPrice}
                    </Typography>
                    <Typography variant='body1' style={{ paddingLeft: '8px' }}>
                        for {isMonthSet ? selectedMonths.length : amountOfDays} {isMonthSet ? selectedMonths.length === 1 ? 'month' : 'months' : amountOfDays === 1 ? 'day' : 'days'}
                    </Typography>
                </Box>

                {/* INFO */}
                <Box px={3} pt={2} pb={4} display="flex" flexDirection="column">
                    <Typography mt={1} variant='body2' style={{ fontFamily: 'Poppins500', color: green1000 }}>
                        You won’t be charged yet.
                    </Typography>
                </Box>

                {/* PRICE CALC */}
                <Box px={3} display="flex" justifyContent="space-between">
                    <Typography variant='body2'>
                        {workspace.host}
                    </Typography>
                    <Typography variant='body2' style={{ paddingLeft: '16px', alignSelf: "flex-end" }}>
                    {workspace.currency}{totalPrice === undefined ? 0 : totalPrice}
                    </Typography>
                </Box>
                <Box px={3} pt={2} display="flex" justifyContent="space-between">
                    <Typography variant='body2'>
                        socialspace fee
                    </Typography>
                    <Typography pl={2} variant='body2' style={{ alignSelf: "flex-end" }}>
                    {workspace.currency}0
                    </Typography>
                </Box>

                {/* DIVIDER */}
                <Box mx={3} my={2} style={{ backgroundColor: grey200, height: '1px' }}></Box>

                {/* TOTAL */}
                <Box px={3} display="flex" justifyContent="space-between">
                    <Typography variant='body2' style={{ fontFamily: 'Poppins600' }}>
                        Total
                    </Typography>
                    <Typography variant='body2' style={{ paddingLeft: '16px', alignSelf: "flex-end", fontFamily: 'Poppins600' }}>
                    {workspace.currency}{totalPrice === undefined ? 0 : totalPrice}
                    </Typography>
                </Box>
            </Box>

            {/* RESERVATION BUTTON */}
            <Box px={3} py={4}>
                <ReservationFormDesktop selectedMonths={selectedMonths} selectedDays={selectedDays} workspace={workspace} totalPrice={totalPrice === undefined ? 0 : totalPrice} setIsSnackbarOpen={setIsSnackbarOpen} />
            </Box>

        </Box >
    )
}
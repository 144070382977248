import { Box, Button, Checkbox, Dialog, DialogContent, FormControlLabel, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import Icon from '../../../../assets/icons/Icon';
import { green1000, grey1000, grey600 } from '../../helper/MuiStyles';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './EmailRegistration.styles';
import { IMAGE_MAP } from '../../helper/ImgMapper';

export default function EmailRegistration({ entry }) {

    const [showDialog, setShowDialog] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const ToggleModal = () => {
        setShowDialog(!showDialog);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = {
            name: formData.get('name'),
            email: formData.get('email'),
        };

        const response = await fetch('/api/submitForm', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        });

        if (response.ok) {
            alert('Form submitted successfully!');
        } else {
            alert('Error submitting form.');
        }
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const styleProps = {
        lighthouse_illustration_img: IMAGE_MAP.ILLUSTRATIONS['LighthouseIllustration']
    };

    const classes = useStyles(styleProps);

    return (
        <>
            {entry === 'mobile' ?
                <Box display="flex" width="calc(100vw - 48px)">
                    <Button
                        sx={{
                            height: '48px',
                            padding: '12px 24px !important',
                            width: '100% !important',
                        }}
                        variant="contained"
                        color="primary"
                        onClick={ToggleModal}
                    >
                        Stay tuned
                    </Button>
                </Box>
                :
                <>
                    {entry === 'desktop-homepage' ?
                        <Box display="flex" onClick={ToggleModal} className={classes.space_box_desktop}>
                            <Box display="flex" alignItems="center">
                                <div className={classes.space_illustration} title="SpaceIllustration"></div>
                            </Box>
                            <Box p={2} pt={4} display="flex" flexDirection="column">
                                <Typography variant='h5' style={{ color: green1000 }}>
                                    Discover new workspaces first
                                </Typography>
                                <Typography mt={2} variant='body2' style={{ color: grey600 }}>
                                    Subscribe and receive the latest news directly in your inbox. As soon as hosts provide brand new workspaces we will let you know immediately.
                                </Typography>
                            </Box>
                        </Box>
                        :
                        <>
                            {entry === 'mobile-homepage' ?
                                <Box display="flex" flexDirection="column" onClick={ToggleModal} className={classes.space_box_mobile}>
                                    <Box display="flex" alignItems="center" style={{ height: '200px' }}>
                                        <div className={classes.space_illustration} title="SpaceIllustration"></div>
                                    </Box>
                                    <Box p={2} display="flex" flexDirection="column">
                                        <Typography variant='h6Mobile' style={{ color: green1000 }}>
                                        Discover new workspaces first
                                        </Typography>
                                        <Typography mt={2} variant='body2' style={{ color: grey600 }}>
                                        Subscribe and receive the latest news directly in your inbox. As soon as hosts provide brand new workspaces we will let you know immediately.
                                  </Typography>
                                    </Box>
                                </Box>
                                :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={ToggleModal}
                                >
                                    Stay tuned
                                </Button>
                            }
                        </>
                    }
                </>
            }

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={showDialog}
                onClose={ToggleModal}
            >
                <DialogContent>
                    {/* HEADER */}
                    <Box mb={5} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant={entry === 'mobile' || 'mobile-homepage' ? "h4Mobile" : "h3"}>
                            Stay tuned
                        </Typography>
                        <IconButton onClick={ToggleModal}>
                            <Icon width={20} height={20} iconName="CloseIcon" fill={grey1000} />
                        </IconButton>
                    </Box>

                    {/* RESERVATION PROCESS */}
                    <Box display="flex" flexDirection="column">
                        <Typography variant="body1">
                            Sign up and stay tuned for latest information and news on socialspace.
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Box mt={3} display="flex" flexDirection="column">
                                <Box mb={3} display="flex" flexDirection={entry === 'mobile' || 'mobile-homepage' ? "column" : "row"}>
                                    <TextField
                                        label="Name"
                                        name="name"
                                        variant="outlined"
                                        required
                                    />
                                    <TextField
                                        label="Email"
                                        name="email"
                                        type="email"
                                        variant="outlined"
                                        required
                                        style={{ marginLeft: entry === 'mobile' || 'mobile-homepage' ? '' : '24px', marginTop: entry === 'mobile' || 'mobile-homepage' ? '24px' : '', }}
                                    />
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                color="primary"
                                            />
                                        }
                                        label=""
                                    />
                                    <Typography variant="body3">I have read and understood the <Link to="/privacypolicy">Privacy Policy</Link> and agree to the processing of my personal data.</Typography>
                                </Box>
                                <Box display="flex" mt={3}>
                                    <Button disabled={!isChecked} type="submit" variant="contained" color="primary">
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>

                </DialogContent>

            </Dialog >
        </>

    )
}
import { makeStyles } from '@mui/styles';
import { green100, green1000 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    footer_container: {
        backgroundColor: green1000,
    },   
    footer_text: {
        color: green100,
    },
    link: {
        paddingBottom: '4px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },
}));
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    space_box_desktop: {
        minHeight: '200px',
        width: '680px',
        overflow: 'hidden',
        borderRadius: '8px',
        cursor: 'pointer',
    },
    space_box_mobile: {
        minHeight: '200px',
        width: '70vw',
        overflow: 'hidden',
        borderRadius: '8px',
    },
    space_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.lighthouse_illustration_img})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '136px',
        minWidth: '300px',
    },
}));
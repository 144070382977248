import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    background_image: {
        height: '55vh',
        backgroundImage: props => `url(${props.backgroundImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },    
    host_box: {
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
    },
    paper: {
        minHeight: '100vh',
    },
    un_goal: {
        height: '72px',
        paddingRight: '24px',
    },
    socialspace_stats: {
        backgroundColor: '#FFF',
        borderRadius: '16px',
    },
    socialspace_box: {
        width: '320px',
        textDecoration: 'none',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        '&:hover': {
            boxShadow: '0px 5px 25px rgba(54, 79, 81, 0.3)',
        },
        margin: '24px 32px 24px 0',
    },
    workspace_box:{
        textDecoration: 'none',
        width: 'calc((100vw - 10vw - 64px)*0.5)',
    }
}));
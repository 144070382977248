export const FOOTER = [
    {
        section: 'terms_of_use',
        eng: 'Terms of use',
        ger: 'Nutzungsbedinungen',
    },
    {
        section: 'imprint',
        eng: 'Imprint',
        ger: 'Impressum',
    },
    {
        section: 'privacy_policy',
        eng: 'Privacy policy',
        ger: 'Datenschutzerklärung',
    },
];
import React, { useContext, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useStyles } from './AboutUsPageMobile.styles';
import { AppContext } from '../../../components/other/contexts/AppContext';
import { ABOUTUS_MOBILE } from '../../../data/translation/TranslationAboutUsMobile';
import { green1000, green50, green800, grey800 } from '../../../components/other/helper/MuiStyles';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import { HOMEPAGE_MOBILE } from '../../../data/translation/TranslationHomepageMobile';
import { Link } from 'react-router-dom';
import Icon from '../../../assets/icons/Icon';
import EmailRegistration from '../../../components/other/dialogs/EmailRegistration/EmailRegistration';

export default function AboutUsPageMobile() {

    const { language } = useContext(AppContext);

    const styleProps = {
        backgroundImgMission: IMAGE_MAP.GENERAL['Mission'],
        backgroundImgRoadtrip: IMAGE_MAP.ILLUSTRATIONS['RoadtripIllustration'],
        backgroundImgFooter: IMAGE_MAP.GENERAL['Mountain'],
    };

    const classes = useStyles(styleProps);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            {/* WELCOME */}
            <Box mx={3} py={8} display="flex" flexDirection="column">
                <Typography variant='h1Mobile'>
                    {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('header')][language]}
                </Typography>
                <Typography mt={5} variant='body1' style={{ color: grey800 }}>
                    {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('header_text')][language]}
                </Typography>
                            <Box mt={4} display="flex">
                                <EmailRegistration entry={'mobile'} />
                            </Box>
            </Box>

            {/* STATEMENT 1 */}
            <Box px={3} py={8} display="flex" flexDirection="column" style={{ backgroundColor: green50 }}>
                <Typography variant="h4Mobile" style={{ color: green1000 }}>
                    {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('phrase1')][language]}
                </Typography>
            </Box>

            {/* STATEMENT 2 */}
            <Box px={3} py={8} display="flex" flexDirection="column" style={{ backgroundColor: green1000 }}>
                <Typography variant="h4Mobile" style={{ color: '#FFF' }}>
                    {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('phrase2')][language]}
                </Typography>
            </Box>

            {/* VISION */}
            <Box py={8} px={3} display="flex" flexDirection="column">
                <Typography mb={5} variant='h2Mobile' style={{ color: green1000 }}>
                    {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('vision_header')][language]}
                </Typography>
                <div className={classes.mission_illustration} title="MissionIllustration"></div>
                <Typography mt={5} variant='body1' style={{ color: grey800 }}>
                    {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('vision_text')][language]}
                </Typography>
            </Box>

            {/* GAP */}
            <Box px={3} py={8} display="flex" flexDirection="column" style={{ backgroundColor: green50 }}>
                <Typography variant='h2Mobile'>
                    {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('title_section4')][language]}
                </Typography>
                <Typography mt={5} variant='body1' style={{ color: grey800 }}>
                    {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('text_section4')][language]}
                </Typography>
            </Box>

            {/* TESTING */}
            <Box py={8} px={3} display="flex" flexDirection="column">
                <Typography variant='h2Mobile'>
                    {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('title_section5')][language]}
                </Typography>
                <Typography mt={3} mb={4} variant='body1' style={{ color: grey800 }}>
                    {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('text1_section5')][language]}
                </Typography>
                <div className={classes.roadtrip_illustration} title="RoadtripIllustration"></div>
                <Typography mt={6} variant='h6Mobile' style={{ color: green800 }}>
                    {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('text2_section5')][language]}
                </Typography>
            </Box>

            {/* MISSION */}
            <Box display="flex" className={classes.background_image_footer}>
                <Box display="flex" flexGrow={1} className={classes.image_content_container}>
                    <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                        <Typography mt={'20vh'} mx={'10vw'} variant="h1Mobile" style={{ textAlign: 'center' }}>
                            {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('title_section6')][language]}
                        </Typography>
                        <Typography mb={'5vh'} mx={3} variant="body2" style={{ color: '#FFF' }}>
                            {ABOUTUS_MOBILE[ABOUTUS_MOBILE.map(object => object.section).indexOf('text_section6')][language]}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* FOOTER */}
            <Box px={3} pt={4} display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: green1000 }}>
                <Box pb={3.5} display="flex" flexDirection="column">
                    <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography className={classes.logo}>socialspace</Typography>
                        <Box display="flex" alignItems="center" height='20px'>
                            <IconButton
                                component="a"
                                href="https://instagram.com/socialspacework?igshid=YmMyMTA2M2Y="
                                target="_blank"
                                rel="noopener noreferrer"
                                color="secondary"
                                className={classes.icon_button_tertiary}
                            >
                                <Icon width={18} height={18} iconName="Instagram" />
                            </IconButton>
                            <IconButton
                                component="a"
                                href="https://twitter.com/socialspacework?s=11&t=U-yVjKdFPsf1bZ9fImbBwA"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="secondary"
                                className={classes.icon_button_tertiary}
                            >
                                <Icon width={18} height={18} iconName="Twitter" />
                            </IconButton>
                            <IconButton
                                component="a"
                                href="https://www.linkedin.com/company/socialspacework/"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="secondary"
                                className={classes.icon_button_tertiary}
                            >
                                <Icon width={18} height={18} iconName="LinkedIn" />
                            </IconButton>
                        </Box>
                    </Box>
                    <Link to={`/termsofuse`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('terms')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/imprint`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('imprint')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/privacypolicy`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('privacy')][language]}
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </>
    )
}
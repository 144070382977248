import makeStyles from "@mui/styles/makeStyles";
import { green800 } from "../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    container: {
        minHeight: '100vh',
    },
    headline: {
        fontFamily: 'Poppins600',
    },
    link: {
        color: green800,
        fontFamily: 'Poppins600',
        wordWrap: 'break-word',
    },
    link_container: {
        textDecoration: `underline ${green800}`,
    },
}));
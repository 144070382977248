import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './JoinHostBoxMobile.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper';
import { green1000, grey600 } from '../../other/helper/MuiStyles';

export default function JoinHostBoxMobile() {

    const styleProps = {
        join_illustration_img: IMAGE_MAP.GENERAL['Journey']
    }

    const classes = useStyles(styleProps);

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.join_box}>
            <Box>
                <Box py={4} px={4}>
                    <div className={classes.join_illustration} title="HostLogo"></div>
                </Box>
                <Box px={3} pb={3} display="flex" flexDirection="column">
                    <Typography variant='h6Mobile' style={{color: green1000}}>
                        Join our journey
                    </Typography>
                    <Typography pt={3} variant='body2' style={{ color: grey600}}>
                    Monetize underutilized resources of your non-profit organisation. All income generated via socialspace goes into your pocket. You can always decide for yourself how much space you want to offer and how long you want to offer it.
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
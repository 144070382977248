import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    contact_box: {
        width: '328px',
        // borderRadius: '8px',
        // boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',  
    },
    contact_photo: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.contactImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '120px',
        width: '120px',
    },
}));
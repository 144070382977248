import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './WorkspaceBoxMobile.styles';
import { IMAGE_MAP } from '../../../other/helper/ImgMapper';
import { green800, grey600 } from '../../../other/helper/MuiStyles';
import Icon from '../../../../assets/icons/Icon';

export default function WorkspaceBoxMobile({ socialspace }) {

    const styleProps = {
        spaceImg: IMAGE_MAP.OFFICE_PREVIEW[socialspace.socialspaceId],
        hostLogo: IMAGE_MAP.PARTNERS[socialspace.host],
    }

    const classes = useStyles(styleProps);

    return (
        <Box display="flex" flexDirection="column" className={classes.socialspace_box}>
            <div className={classes.office_picture} title="OfficePicture"></div>
            <Box p={2} display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box mr={2} display="flex" flexDirection="column">
                        <Typography variant='h5Mobile' className={classes.workspace_text}>
                            {socialspace.workspaceType}
                        </Typography>
                        <Typography variant='body2' className={classes.host_text}>
                            {socialspace.host}
                        </Typography>
                    </Box>
                    <div className={classes.host_picture} title="HostLogo"></div>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between" >
                    <Box display="flex" flexDirection="column">
                        <Typography variant='body2'>
                            {socialspace.address.street}
                        </Typography>
                        <Typography variant='body2'>
                            {socialspace.address.postalCode} {socialspace.location}
                        </Typography>
                    </Box>
                </Box>
                <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                        <Box display="flex" alignItems="center" height="16px">
                            <Icon width={16} height={16} iconName="HeartIcon" fill={socialspace.rating > 0 ? green800 : grey600} />
                        </Box>
                        <Typography ml={1} variant='body2' style={{lineHeight: '28px', color: socialspace.rating > 0 ? '' : grey600}}>
                            {socialspace.rating > 0 ? socialspace.rating : 'No rating'}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="baseline">
                        <Typography variant='h3Mobile'>
                            {socialspace.currency}{socialspace.price}
                        </Typography>
                        <Typography variant='body2' style={{marginLeft: '4px'}}>
                            day
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
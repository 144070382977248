export const IMPRINT = [
    {
        section: 'title',
        eng: 'Imprint',
        ger: 'Impressum',
    },
    {
        section: 'text_generic_1',
        eng: 'Information according to ',
        ger: 'Angaben gemäß ',
    },
    {
        section: 'phillip_1',
        eng: 'Phillip Dürner ("Provider")',
        ger: 'Phillip Dürner („Anbieter“)',
    },
    {
        section: 'phillip_2',
        eng: 'Am Griesfeld 2a',
        ger: 'Am Griesfeld 2a',
    },
    {
        section: 'phillip_3',
        eng: '82178 Puchheim',
        ger: '82178 Puchheim',
    },
    {
        section: 'contact',
        eng: 'Contact',
        ger: 'Kontakt',
    },
    {
        section: 'phone',
        eng: 'Phone: +49 174 6832029',
        ger: 'Telefon: +49 174 6832029',
    },
    {
        section: 'mail',
        eng: 'E-mail: ',
        ger: 'E-Mail: ',
    },
    {
        section: 'text_generic_2',
        eng: 'Responsible for the content according to ',
        ger: 'Verantwortlich für den Inhalt nach ',
    },
    {
        section: 'text_generic_3',
        eng: 'Disclaimer:',
        ger: 'Haftungsausschluss:',
    },
    {
        section: 'title_1',
        eng: 'Contents',
        ger: 'Inhalte',
    },
    {
        section: 'text_1',
        eng: 'The contents of our pages have been created with the utmost care. We cannot guarantee the accuracy, completeness and timeliness of the content - in particular not the accuracy, completeness and timeliness of information about hosts, workspaces and prices. The website acts solely as an intermediary between guest and host and assumes no liability for the legal agreements concluded between guest and host. As a provider, we are responsible for our own content on these pages under the general laws according to § 7 Abs.1 TMG. According to §§ 8 to 10 TMG, we are not obligated to monitor transmitted or stored information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information under the general laws remain unaffected. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of any such infringements, we will remove the relevant content immediately.',
        ger: 'Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte – insbesondere zu für die Richtigkeit, Vollständigkeit und Aktualität der Informationen über Gastgeber, Arbeitsplätze und Preise – können wir jedoch keine Gewähr übernehmen. Die Website fungiert einzig als Vermittler zwischen Gast und Gastgeber und übernimmt keinerlei Haftung für die fortan zwischen Gast und Gastgeber abgeschlossenen rechtlichten Vereinbarungen. Als Anbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Anbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.',
    },
    {
        section: 'title_2',
        eng: 'Links',
        ger: 'Links',
    },
    {
        section: 'text_2',
        eng: 'Our website contains links to external third-party websites over whose content we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking. However, a permanent control of the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. If we become aware of any infringements, we will remove such links immediately.',
        ger: 'Unsere Website enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.',
    },
    {
        section: 'title_3',
        eng: 'Copyright',
        ger: 'Urheberrecht',
    },
    {
        section: 'text_3',
        eng: 'The contents and works created by the site operators on these pages are subject to German copyright law. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.',
        ger: 'Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.',
    },
    {
        section: 'title_4',
        eng: 'No advertising',
        ger: 'Keine Werbung',
    },
    {
        section: 'text_4',
        eng: 'The use of the contact data of the imprint for commercial advertising is expressly not desired and not permitted, unless the provider had previously given his written consent or there is already a business relationship. The providers and all persons named on this website hereby object to any commercial use and disclosure of their data for advertising or similar inadmissible purposes.',
        ger: 'Die Verwendung der Kontaktdaten des Impressums zur gewerblichen Werbung ist ausdrücklich nicht erwünscht und nicht zulässig, es sei denn der Anbieter hatte zuvor seine schriftliche Einwilligung erteilt oder es besteht bereits eine Geschäftsbeziehung. Die Anbieter und alle auf dieser Website genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten für Werbung oder ähnliche unzulässige Zweck.',
    },
    {
        section: 'title_5',
        eng: 'Terms of use',
        ger: 'Nutzungsbedinungen',
    },
    {
        section: 'text_5_1',
        eng: 'There are ',
        ger: 'Es bestehen ',
    },
    {
        section: 'text_5_2',
        eng: '. Insofar as special conditions for individual uses of this website deviate from the aforementioned numbers 1. to 4 or the terms of use, this will be expressly pointed out at the appropriate place. In this case, the special conditions apply in the respective individual case.',
        ger: '. Soweit davon sowie von den vorgenannten Nummern 1. bis 4. abweichende besondere Bedingungen für einzelne Nutzungen dieser Website auftreten, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Bedingungen.',
    },
];
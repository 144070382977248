import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { WORKSPACES } from '../../../data/data';
import WorkspaceBox from '../../../components/SpacesPage/WorkspaceBox/WorkspaceBox';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { useStyles } from './SpacesPageDesktop.styles';
import FooterSpacesPageDesktop from '../../../components/Footer/desktop/FooterSpacesPageDesktop';

const center = { lat: 20.607685, lng: 10.688126 };


export default function SpacesPageDesktop() {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_KEY,
    });

    const classes = useStyles();

    if (!isLoaded) {
        return <div>Loading</div>
    }

    return (
        <Grid item xs={12} display="grid">
            <Box display="flex" style={{ maxHeight: 'calc(100vh - 120px)' }}>
                <Box display="flex" className={classes.host_box}>
                    <Box px={5} display="flex" flexDirection="column" flexGrow={1} className={classes.list_box}>
                        {WORKSPACES?.sort((a, b) => ['1', '4', '3', '2'].indexOf(a.socialspaceId) - ['1', '4', '3', '2'].indexOf(b.socialspaceId)).map((option, index) => {
                            return (
                                <Link to={`/workspace/${option.socialspaceId}`} key={option.socialspaceId} className={classes.socialspace_box} style={{ marginBottom: index === WORKSPACES.length - 1 ? '40px' : 0 }}>
                                    <WorkspaceBox key={option.socialspaceId} socialspace={option} />
                                </Link>
                            )
                        })}
                    </Box>
                </Box>
                <Box display="flex" style={{ width: '50vw' }}>
                    <GoogleMap
                        center={center}
                        zoom={2.5}
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                    >
                        {WORKSPACES?.map((option, index) => {
                            return (
                                <Marker key={index} position={{ lat: option.address.lat, lng: option.address.lng }} />
                            )
                        })}
                    </GoogleMap>
                </Box>
            </Box>
            <FooterSpacesPageDesktop />
        </Grid >
    )
}
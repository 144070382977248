import { Box, Button, Dialog, DialogContent, IconButton, InputBase, Typography, TextField, FormControlLabel, Checkbox } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Icon from '../../../../assets/icons/Icon';
import { DayMonthContext } from '../../contexts/DayMonthContext';
import { grey1000, grey600 } from '../../helper/MuiStyles';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';

export default function ReservationFormDesktop({ selectedMonths, selectedDays, workspace, totalPrice, setIsSnackbarOpen }) {

    const { isMonthSet } = useContext(DayMonthContext);
    const { setSnackbarMessage } = useContext(AppContext);
    const [showDialog, setShowDialog] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [workspaceId, setWorkspaceId] = useState('');
    const [reservationDates, setReservationDates] = useState([]);
    const [isChecked, setIsChecked] = useState(false);

    function validateEmail(email) {
        var regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regEx.test(email)
    };

    const sendRequest = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            e.target,
            process.env.REACT_APP_EMAILJS_USER_ID
        )
            .then((result) => {
                console.log(result.text);
                console.log("message sent");
                e.target.reset();
            }, (error) => {
                console.log(error.text);
            });

        setSnackbarMessage('You have successfully requested a reservation. The host will get in contact with you.');
        setName('');
        setEmail('');
        setComment('');
        setWorkspaceId('');
        setIsSnackbarOpen(true);
        setReservationDates([]);
        ToggleModal();
        window.scrollTo(0, 0);
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const ToggleModal = () => {
        setShowDialog(!showDialog);
        setIsChecked(false);
    };

    useEffect(() => {
        if (selectedDays.length > 0 && selectedDays[0].days !== undefined) {
            let value = [];
            selectedDays.forEach(dayGroup => {
                dayGroup.days.forEach(day => {
                    value.push(`${day.date} ${dayGroup.month}`);
                });
            });
            setReservationDates(value);
        } else if (selectedMonths.length > 0) {
            setReservationDates(selectedMonths);
        }
    
        setWorkspaceId(workspace.socialspaceId);
    }, [selectedDays, selectedMonths, workspace.socialspaceId]);

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={ToggleModal}
                sx={{ height: '48px', padding: '12px 24px !important', }}
                disabled={isMonthSet ? selectedMonths.length < 1 ? true : false : selectedDays.length < 1 ? true : false}
            >
                Request reservation
            </Button>

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={showDialog}
                onClose={ToggleModal}
            >
                <DialogContent>
                    {/* HEADER */}
                    <Box mb={5} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h3">
                            Request reservation
                        </Typography>
                        <IconButton onClick={ToggleModal}>
                            <Icon width={20} height={20} iconName="CloseIcon" fill={grey1000} />
                        </IconButton>
                    </Box>

                    {/* RESERVATION PROCESS */}
                    <Box display="flex" flexDirection="column">
                        <Typography variant="body1" style={{ paddingBottom: '8px' }}>
                            You won’t be charged yet. socialspace shares your reservation request with the host. Please refer to our <Link to="/termsofuse">Terms of use</Link> for further information. Here's how the reservation process works:
                        </Typography>
                        <Box ml={3} mb={1} display="flex">
                            <Typography variant="body1" style={{ minWidth: '25px' }}>
                                1.
                            </Typography>
                            <Typography variant="body1">
                                Check information and provide your name and email for your host.
                            </Typography>
                        </Box>
                        <Box ml={3} mb={1} display="flex">
                            <Typography variant="body1" style={{ minWidth: '25px' }}>
                                2.
                            </Typography>
                            <Typography variant="body1">
                                Click 'Request reservation' to forward your reservation details to socialspace, which we'll then pass on to your host.
                            </Typography>
                        </Box>
                        <Box ml={3} mb={1} display="flex">
                            <Typography variant="body1" style={{ minWidth: '25px' }}>
                                3.
                            </Typography>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="body1">
                                    Your host will confirm your request and provide further booking and payment details.
                                </Typography>
                            </Box>
                        </Box>
                        <Typography variant="body1">
                            Remember, you can still cancel the reservation.
                        </Typography>
                    </Box>

                    {/* RESERVATION DETAILS */}
                    <Box mt={5} display="flex">
                        {/* LEFT COLUMN */}
                        <Box display="flex" flexDirection="column" style={{ minWidth: '275px' }}>
                            <Typography variant="body1" style={{ paddingBottom: '8px', fontFamily: 'Poppins600' }}>
                                Requested dates
                            </Typography>
                            {selectedDays?.map((option1, index1) => {
                                return (
                                    <Box key={index1} display="flex" flexDirection="column">
                                        {selectedDays[index1]?.days.map((option2, index2) => {
                                            return (
                                                <Box key={index2} display="flex" alignItems="center" justifyContent="space-between" style={{ borderBottom: (index1 === (selectedDays.length - 1) && index2 === (selectedDays[index1].days.length - 1)) ? `1px solid ${grey600}` : '' }}>
                                                    <Typography variant="body1" style={{ paddingBottom: '8px' }}>
                                                        {option2.date < 10 ? '0' + option2.date : option2.date} {option1.month} 2024
                                                    </Typography>
                                                    <Typography variant="body1" style={{ paddingBottom: '8px', marginLeft: '8px' }}>
                                                    {workspace.currency}{option2.price}
                                                    </Typography>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                )
                            })}
                            {selectedMonths?.map((option, index) => {
                                return (
                                    <Box key={index} display="flex" alignItems="center" justifyContent="space-between" style={{ borderBottom: (index === (selectedMonths.length - 1)) ? `1px solid ${grey600}` : '' }}>
                                        <Typography variant="body1" style={{ paddingBottom: '8px' }}>
                                            {option} 2024
                                        </Typography>
                                        <Typography variant="body1" style={{ paddingBottom: '8px', marginLeft: '8px' }}>
                                        {workspace.currency}{totalPrice / selectedMonths.length}
                                        </Typography>
                                    </Box>
                                )
                            })}
                            <Box pt={1} display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="body1" style={{ fontFamily: 'Poppins600' }}>
                                    Total
                                </Typography>
                                <Typography ml={1} variant="body1" style={{ fontFamily: 'Poppins600' }}>
                                {workspace.currency}{totalPrice}
                                </Typography>
                            </Box>
                        </Box>
                        {/* MIDDLE COLUMN */}
                        <Box pl={8} display="flex" flexDirection="column">
                            <Typography pb={1} variant="body1" style={{ fontFamily: 'Poppins600' }}>
                                Host
                            </Typography>
                            <Typography variant="body1">
                                {workspace.host}
                            </Typography>
                            <Typography variant="body1">
                                {workspace.address.postalCode} {workspace.location}
                            </Typography>
                            <Typography variant="body1">
                                {workspace.address.street}
                            </Typography>
                        </Box>
                        {/* RIGHT COLUMN */}

                        <Box pl={8} display="flex" flexDirection="column">
                            <Typography pb={1} variant="body1" style={{ fontFamily: 'Poppins600' }}>
                                Workspace type
                            </Typography>
                            <Typography variant="body1">
                                {workspace.workspaceType}
                            </Typography>
                        </Box>
                    </Box>

                    <form onSubmit={sendRequest}>
                        <Box mt={5} display="flex" flexDirection="column">
                            <TextField
                                label="Name"
                                name='name'
                                placeholder='Enter your name'
                                value={name}
                                onChange={(event) => { setName(event.target.value) }}
                                error={name.length < 3}
                            />
                            {/* MAIL */}
                            <TextField
                                label="Email"
                                name='email'
                                placeholder='Enter your email address'
                                value={email}
                                onChange={(event) => { setEmail(event.target.value) }}
                                error={!validateEmail(email)}
                                style={{ marginTop: '24px' }}
                            />
                            <TextField
                                label="Comment"
                                name='comment'
                                placeholder='Leave a note if you want'
                                value={comment}
                                multiline
                                onChange={(event) => { setComment(event.target.value) }}
                                style={{ margin: '24px 0' }}
                            />
                            {/* RESERVATION DATES */}
                            <InputBase
                                name='reservationDates'
                                value={reservationDates}
                                style={{ display: 'none' }}
                            >
                            </InputBase>
                            {/* WORKSPACE ID */}
                            <InputBase
                                name='workspaceId'
                                value={workspaceId}
                                style={{ display: 'none' }}
                            >
                                {/* CHECKBOX */}
                            </InputBase>
                            <Box display="flex" alignItems="center">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            color="primary"
                                        />
                                    }
                                    label=""
                                />
                                <Typography variant="body3">I have read and understood the <Link to="/privacypolicy">Privacy Policy</Link> and agree to the processing of my personal data.</Typography>
                            </Box>
                            <Box mt={3}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{ height: '48px', padding: '12px 24px !important', }}
                                    disabled={name.length < 3 || !validateEmail(email) || !isChecked}
                                >
                                    Request reservation
                                </Button>
                            </Box>
                        </Box>

                    </form>
                </DialogContent>

            </Dialog >
        </>

    )
}
import React, { useContext, useEffect, useRef } from 'react';
import { Box, Typography, ImageListItem, ImageList, IconButton } from '@mui/material';
import { useStyles } from './HostsPageMobile.styles';
import { AppContext } from '../../../components/other/contexts/AppContext';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import { HOSTS_PAGE_TEXT } from '../../../data/staticData';
import { HOSTS } from '../../../data/data';
import { Link } from 'react-router-dom';
import { green1000, grey800 } from '../../../components/other/helper/MuiStyles';
import { HOMEPAGE_MOBILE } from '../../../data/translation/TranslationHomepageMobile';
import JoinHostBoxMobile from '../../../components/JoinHostBox/mobile/JoinHostBoxMobile';
import HostBoxMobile from '../../../components/HostBox/mobile/HostBoxMobile';
import Icon from '../../../assets/icons/Icon';

export default function HostsPageMobile() {

    const { language } = useContext(AppContext);
    const refHosts = useRef(null);

    const styleProps = {
        backgroundImgHeader: IMAGE_MAP.GENERAL['Hosts_Header'],
        heightHostBox: `calc(${refHosts.current?.offsetHeight}px - 48px)`,
    };

    const classes = useStyles(styleProps);
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>

                {/* HEADER */}
                <Box display="flex" flexDirection="column" className={classes.background_image_header}>
                    <Box mx={3} mt={'10vh'} display="flex" flexDirection="column" className={classes.image_content_container}>
                        <Typography variant="h2Mobile" style={{color: '#FFF', maxWidth: '70vw'}}>
                            {HOSTS_PAGE_TEXT[HOSTS_PAGE_TEXT.map(object => object.section).indexOf('header')][language]}
                        </Typography>
                        <Typography mt={1} variant="body1" style={{ color: '#FFF', marginTop: '8px' }}>
                            {HOSTS_PAGE_TEXT[HOSTS_PAGE_TEXT.map(object => object.section).indexOf('text')][language]}
                        </Typography>
                    </Box>
                </Box>
                
                {/* HOSTS */}
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box display="flex" alignItems="center" justifyContent="center" style={{ margin: '64px 24px 24px 24px' }}>
                    <p className={classes.headline_caption}>
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('caption1_section4')][language]}<br />
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('caption2_section4')][language]}
                    </p>
                </Box>
                <Box display="flex" flexDirection="column" style={{ margin: '0 24px 24px 24px' }}>
                    <Typography variant='body1' style={{ color: grey800 }}>
                    Empowering global change, non-profit organisations thrive on resources to combat pressing social, humanitarian and environmental issues. Fuel their missions and uplift communities by supporting their transformative potential. Together, we can create a resilient world!
                    </Typography>
                </Box>
            </Box>
               {/* HOSTS */}
            <div className={classes.hosts_container}>
                <ImageList ref={refHosts} className={classes.image_list} cols={HOSTS.length + 1}>
                    {HOSTS?.map((option, index) => {
                        return (
                            <ImageListItem key={option.hostId}>
                                <Link to={`/host/${option.hostId}`} key={option.hostId} className={classes.host_box} style={{ marginLeft: index === 0 ? '24px' : '' }}>
                                    <HostBoxMobile host={option} />
                                </Link>
                            </ImageListItem>
                        )
                    })}
                    <Link to={`/becomehost`} className={classes.host_box}>
                        <JoinHostBoxMobile />
                    </Link>
                </ImageList>
            </div>
            {/* FOOTER */}
            <Box px={3} pt={4} display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: green1000 }}>
                <Box pb={3.5} display="flex" flexDirection="column">
                    <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography className={classes.logo}>socialspace</Typography>
                        <Box display="flex" alignItems="center" height='20px'>
                            <IconButton
                                component="a"
                                href="https://instagram.com/socialspacework?igshid=YmMyMTA2M2Y="
                                target="_blank"
                                rel="noopener noreferrer"
                                color="secondary"
                                className={classes.icon_button_tertiary}
                            >
                                <Icon width={18} height={18} iconName="Instagram" />
                            </IconButton>
                            <IconButton
                                component="a"
                                href="https://twitter.com/socialspacework?s=11&t=U-yVjKdFPsf1bZ9fImbBwA"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="secondary"
                                className={classes.icon_button_tertiary}
                            >
                                <Icon width={18} height={18} iconName="Twitter" />
                            </IconButton>
                            <IconButton
                                component="a"
                                href="https://www.linkedin.com/company/socialspacework/"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="secondary"
                                className={classes.icon_button_tertiary}
                            >
                                <Icon width={18} height={18} iconName="LinkedIn" />
                            </IconButton>
                        </Box>
                    </Box>
                    <Link to={`/termsofuse`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('terms')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/imprint`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('imprint')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/privacypolicy`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('privacy')][language]}
                        </Typography>
                    </Link>
                </Box>
            </Box>
            </>
    )
}
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    workspace_box: {
        height: props => props.maxHeightBox,
        boxSizing: 'border-box',
        borderRadius: '8px',
        border: props => props.borderSelected,
        padding: '16px',
        cursor: props => props.cursor,
        '&:hover': {
            backgroundColor: props => props.hoverColor,
        },
    },
}));
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { theme } from './components/other/helper/MuiStyles';
import WorkspacePage from './components/WorkspacePage/WorkspacePage';
import { DayMonthProvider } from './components/other/contexts/DayMonthContext';
import { AppProvider } from './components/other/contexts/AppContext';
import { FilterProvider } from './components/other/contexts/FilterContext';
import BecomeHostPageDesktop from './pages/BecomeHostPage/desktop/BecomeHostPageDesktop';
import TermsOfUsePage from './pages/TermsOfUsePage/TermsOfUsePage';
import ImprintPage from './pages/ImprintPage/ImprintPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import HomepageMobile from './pages/Homepage/mobile/HomepageMobile';
import HeaderDesktop from './components/Header/desktop/HeaderDesktop';
import HeaderMobile from './components/Header/mobile/HeaderMobile';
import HomepageDesktop from './pages/Homepage/desktop/HomepageDesktop';
import AboutUsPageDesktop from './pages/AboutUsPage/desktop/AboutUsPageDesktop';
import AboutUsPageMobile from './pages/AboutUsPage/mobile/AboutUsPageMobile';
import BecomeHostPageMobile from './pages/BecomeHostPage/mobile/BecomeHostPageMobile';
import SpacesPageMobile from './pages/SpacesPage/mobile/SpacesPageMobile';
import WorkspacePageMobile from './pages/WorkspacePage/mobile/WorkspacePageMobile';
import SpacesPageDesktop from './pages/SpacesPage/desktop/SpacesPageDesktop';
import HostsPageDesktop from './pages/HostsPage/desktop/HostsPageDesktop';
import HostsPageMobile from './pages/HostsPage/mobile/HostsPageMobile';
import HostPageMobile from './pages/HostPage/mobile/HostPageMobile';
import HostPageDesktop from './pages/HostPage/desktop/HostPageDesktop';
import RequestPageMobile from './pages/RequestPage/mobile/RequestPageMobile';

function App() {
 
  
  const [device, setDevice] = useState((window.innerWidth <= 750) ? 'mobile' : 'desktop');  
  
  useEffect(() => {
    function handleResize() {
      switch (true) {
        case window.innerWidth <= 750:
          setDevice('mobile');
          break;
        case window.innerWidth > 750:
          setDevice('desktop');
          break;
        default:
          setDevice('desktop');
          break;
      }
    }
    
    window.addEventListener('resize', handleResize)
    
  })

  return (
    <AppProvider>
      <DayMonthProvider>
        <FilterProvider>
          <Router>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
              {device === 'desktop' ? <HeaderDesktop /> : <HeaderMobile />}
                <Routes>
                  <Route exact path="/" element={device === 'desktop' ? <HomepageDesktop /> : <HomepageMobile />} />
                  <Route exact path="/aboutus" element={device === 'desktop' ? <AboutUsPageDesktop /> : <AboutUsPageMobile />} />
                  <Route path="/host/:id" element={device === 'desktop' ? <HostPageDesktop /> : <HostPageMobile/>} />
                  <Route exact path="/workspaces" element={device === 'desktop' ? <SpacesPageDesktop /> : <SpacesPageMobile />} />
                  <Route exact path="/hosts" element={device === 'desktop' ? <HostsPageDesktop /> : <HostsPageMobile />} />
                  <Route path="/workspace/:id" element={device === 'desktop' ? <WorkspacePage /> : <WorkspacePageMobile />} />
                  <Route path="/workspace/:id/request" element={device === 'desktop' ? <RequestPageMobile /> : <RequestPageMobile />} />
                  <Route exact path="/becomehost" element={device === 'desktop' ? <BecomeHostPageDesktop /> : <BecomeHostPageMobile />} />
                  <Route exact path="/termsofuse" element={<TermsOfUsePage device={device} />} />
                  <Route exact path="/imprint" element={<ImprintPage device={device} />} />
                  <Route exact path="/privacypolicy" element={<PrivacyPolicyPage device={device} />} />
                </Routes>
              </ThemeProvider>
            </StyledEngineProvider>
          </Router>
        </FilterProvider>
      </DayMonthProvider>
    </AppProvider>
  );
}

export default App;

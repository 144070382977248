import { makeStyles } from '@mui/styles';
import { green100, green50, green800 } from '../helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    icon_button_tertiary:{        
        '&:hover': {
            backgroundColor: green50,
        },
    },
    process_bubble: {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        border: `2px solid ${green800}`,
    },
    process_line: {
        height: '2px',
        width: '150px',
        backgroundColor: green800,
        marginTop: '13px',
        marginLeft: '-46px',        
        marginRight: '-46px'
    },
    process_step: {
        fontFamily: 'Poppins500', 
        width: '120px', 
        textAlign: 'center', 
        marginTop: '12px', 
        color: green800
    },
    selected_button:{
        backgroundColor: green50,   
        '&:hover': {
            backgroundColor: 'rgba(231, 242, 242, 0.8)',
        },
    },
    app_header: {
        height: '80px',
    },
    logo: {
        fontFamily: 'Poppins600',
        fontSize: '16px',
        lineHeight: '24px',
        color: green800,
    },
    link: {
        textDecoration: 'none',
        paddingTop: '12px',
        paddingBottom: '12px',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },
}));
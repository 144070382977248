import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    host_box: {
        overflow: 'hidden',
    },
    join_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.join_illustration_img})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '80px',
        width: 'auto',
    },
}));
import makeStyles from "@mui/styles/makeStyles";
import { green100 } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    background_image_footer: {
        height: 'calc(100vh - 80px)',
        backgroundImage: props => `url(${props.backgroundImgFooter})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    }, 
    footer: {
        color: green100,
    },  
    icon_button_tertiary: {
        padding: '0 8px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0)',
        },
    },
    image_content_container: {
        background: 'linear-gradient(to top, #0A6466 0%, rgba(10, 100, 102, 0) 20%, rgba(255, 255, 255, 0) 35%, #FFFFFF 95%)',
    },
    link: {
        textDecoration: 'none',
        paddingBottom: '4px',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },    
    logo: {
        fontFamily: 'Poppins600',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.03em',
        color: '#FFF',
    },  
    mission_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgMission})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '60vw',
        minWidth: '60vw',
    },
    roadtrip_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgRoadtrip})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '60vw',
        minWidth: '60vw',
    },
}));
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({    
    month_box: {
        flexGrow: 1,
        height: 'auto',
        boxSizing: 'border-box',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: props => props.hoverColor,
        },
    },
}));
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    workspace_box: {
        height: props => props.maxHeightBox,
        boxSizing: 'border-box',
        borderRadius: '8px',
        border: props => props.borderSelected,
        padding: '32px 24px 24px 24px',
        cursor: props => props.cursor,
        '&:hover': {
            backgroundColor: props => props.hoverColor,
        },
    },
    workspace_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.imgWorkspace})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '7.5vw',
        width: '12.5vw',
    },
}));
import React, { useContext, useEffect } from 'react';
import { Paper, Box, Grid, Typography } from '@mui/material';
import { useStyles } from './HostsPageDesktop.styles';
import { AppContext } from '../../../components/other/contexts/AppContext';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import { HOSTS_PAGE_TEXT } from '../../../data/staticData';
import { HOSTS } from '../../../data/data';
import { Link } from 'react-router-dom';
import JoinBox from '../../../components/other/HostBox/JoinBox';
import HostBox from '../../../components/other/HostBox/HostBox';
import { HOMEPAGE_DESKTOP } from '../../../data/translation/TranslationHomepageDesktop';
import { grey800 } from '../../../components/other/helper/MuiStyles';
import FooterDesktop from '../../../components/Footer/desktop/FooterDesktop';

export default function HostsPageDesktop() {

    const { language } = useContext(AppContext);

    const styleProps = {
        backgroundImgHeader: IMAGE_MAP.GENERAL['Hosts_Header'],
    };

    const classes = useStyles(styleProps);
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Grid item xs={12} display="grid">
            <Paper className={classes.paper}>

                {/* HEADER */}
                <Box display="flex" flexDirection="column" className={classes.background_image_header}>
                    <Box ml={'10vw'} mt={'10vh'} display="flex" flexDirection="column" className={classes.image_content_container}>
                        <Typography className={classes.headline}>
                            {HOSTS_PAGE_TEXT[HOSTS_PAGE_TEXT.map(object => object.section).indexOf('header')][language]}
                        </Typography>
                        <Typography variant="body1" style={{ color: '#FFF', maxWidth: '760px', marginTop: '8px' }}>
                            {HOSTS_PAGE_TEXT[HOSTS_PAGE_TEXT.map(object => object.section).indexOf('text')][language]}
                        </Typography>
                    </Box>
                </Box>
                
                {/* HOSTS */}
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Box display="flex" alignItems="center" justifyContent="center" style={{ marginTop: '80px', marginBottom: '24px' }}>
                        <p className={classes.headline_caption}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('caption1_section4')][language]}
                        </p>
                    </Box>
                    <Box display="flex" flexDirection="column" style={{ marginBottom: '64px', marginLeft: '18vw', marginRight: '18vw', textAlign: 'center', }}>
                        <Typography variant='body1' style={{ color: grey800 }}>
                        Empowering global change, non-profit organisations thrive on resources to combat pressing social, humanitarian and environmental issues. Fuel their missions and uplift communities by supporting their transformative potential. Together, we can create a resilient world!
                        </Typography>
                    </Box>
                </Box>
                <Box mb={4} display="flex" justifyContent="center" flexWrap="wrap">
                    {HOSTS?.map((option) => {
                        return (
                            <Link to={`/host/${option.hostId}`} key={option.hostId} className={classes.partner_box}>
                                <HostBox host={option} />
                            </Link>
                        )
                    })}
                    <Link to={`/aboutus`} className={classes.partner_box}>
                        <JoinBox />
                    </Link>
                </Box>
              
              {/* FOOTER */}
              <FooterDesktop />

            </Paper >
        </Grid >
    )
}
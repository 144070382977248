export const HOMEPAGE_TEXT = [
    {
        section: 'header',
        eng: 'Find a workspace and generate positive impact.',
        ger: 'Finde einen Arbeitsplatz und erzeuge positive Impulse.',
    },
    {
        section: 'header_button',
        eng: 'Explore workspaces',
        ger: 'Arbeitsplätze anzeigen',
    },
    {
        section: 'header_caption',
        eng: 'makes finding workspaces easy and convenient - whilst enabling social and sustainable impact.',
        ger: 'lässt dich Arbeitsplätze einfach und bequem finden - und ermöglicht positive Impulse.',
    },
    {
        section: 'header_subtitle1',
        eng: 'We are an online platform that serves as a bridge between non-profit organisations and guests in search of unique coworking spaces. We empower freelancers, remote workers, and organisations to work in environments that align with their values, culture, and objectives while fostering social and sustainable impact.',
        ger: 'Wohltätige Organisationen können ihre nicht genutzten Büros und Arbeitsplätze vorübergehend an Personen und Organisationen vermieten.',
    },
    {
        section: 'header_subtitle2',
        eng: 'Our mission is to provide every non-profit organisation the means to reduce their resource dependency by generating income through office letting. As a one-of-a-kind coworking community we are envisioning a vibrant and inclusive community where everyone thrives together.',
        ger: 'Wohltätige Organisationen können ihre nicht genutzten Büros und Arbeitsplätze vorübergehend an Personen und Organisationen vermieten.',
    },
    {
        section: 'host_button',
        eng: 'Explore hosts',
        ger: 'Gastgeber erkunden',
    },
    {
        section: 'vision_header',
        eng: 'Our mission is to reduce the dependency of non-profit organisations and enable them to pursue their vision and maximize impact.',
        ger: 'Unsere Vision ist es, die Abhängigkeit von wohltätige Organisationen zu verringern und sie zu befähigen ihre Vision zu verfolgen und ihre Wirkung zu maximieren.',
    },
    {
        section: 'vision_subtitle',
        eng: 'Our vision is to become a global leader in the shared workspace market for NGOs, creating a sustainable ecosystem that supports growth, innovation, and collaboration for social impact organisations and coworkers alike.',
        ger: 'Dabei streben wir nach positiven sozialen, wirtschaftlichen und ökologischen Auswirkungen.',
    },
    {
        section: 'vision_button',
        eng: 'Learn more about us',
        ger: 'Lerne uns kennen',
    },
    {
        section: 'why_socialspace_header',
        eng: 'Why socialspace?',
        ger: 'Warum socialspace?',
    },
    {
        section: 'why_socialspace_subtitle1',
        eng: 'Non-profit organisations play an essential role in our society by working towards addressing the various social, environmental, and humanitarian issues facing the world today. Without them, many people would struggle to access crucial services, and important issues might not receive the attention they deserve. These organisations are usually non-profit entities that rely on donations and volunteer work to carry out their missions.',
        ger: 'Warum socialspace?',
    },
    {
        section: 'why_socialspace_subtitle2',
        eng: 'By booking a workplace on socialspace you can financially support non-profit organisations so they can pursue their vision and maximize their impact.',
        ger: 'Warum socialspace?',
    },    
    {
        section: 'why_socialspace_subtitle',
        reasons: [
            {
                icon: 'DoveIcon',
                eng: 'Support your charity.',
                ger: 'Unterstütze deine wohltätige Organisation.'
            }, 
            {
                icon: 'LeafIcon',
                eng: 'Stop wasting underutilzed space.',
                ger: 'Verschwende keinen ungenutzten Raum.'
            },
            {
                icon: 'EarthIcon',
                eng: 'Work from anywhere.',
                ger: 'Arbeite von überall.'
            },
            {
                icon: 'ClipboardCheckIcon',
                eng: 'Validated hosts.',
                ger: 'Validierte Gastgeber.'
            },
        ]
    },
    {
        section: 'why_socialspace_button',
        eng: 'Sign up',
        ger: 'Registriere dich',
    },
    {
        section: 'bottom_caption1',
        eng: 'The world is dependent on non-profit organisations.',
        ger: 'Die Welt ist auf wohltätige Organisationen angewiesen.',
    },
    {
        section: 'bottom_caption2',
        eng: 'Non-profit organistaions are dependent on resources.',
        ger: 'Wohltätige Organisationen sind auf externe Ressourcen angewiesen.',
    },
    {
        section: 'bottom_subtitle1',
        eng: 'Non-profit organisations are critical around the world across various sectors, but they are resource dependent on donors.',
        ger: 'Wohltätige Organisationen sind weltweit in verschiedenen Bereichen von entscheidender Bedeutung, aber sie sind von Spendern abhängig.',
    },
    {
        section: 'bottom_subtitle2',
        eng: 'This, in turn, has a (potential) negative impact on them and their work.',
        ger: 'Dies wiederum hat (potenziell) negative Auswirkungen auf sie und ihre Arbeit.',
    },
    {
        section: 'footer_text',
        eng: 'socialspace began as a private project and we are currently in our prototype testing phase, which runs from May to August 2023 in Berlin. We value your participation and feedback during this essential stage, as it will help us refine and enhance our services to meet the evolving needs of our community members. We are particularly eager to engage with individual coworkers, who we believe will be instrumental in shaping our distinctive coworking concept.',
        ger: 'socialspace ist brandneu und befindet sich aktuell noch in der Testphase. Wir bedanken uns für eure Geduld und euer Verständnis während dieser ersten Testphase. Wir arbeiten hart an der Entwicklung dieses Produkts in der Hoffnung, dass es einen Mehrwert schafft und wohltätigen Organisationen auf der ganzen Welt ermöglicht, ihr volles Potenzial zu entfalten. Wir sind dankbar für eure Neugier und eure Unterstützung für socialspace.',
    },


];

export const LANGAUAGE_SWTICH_TEXT = [
    {
        section: 'header',
        eng: 'Choose language',
        ger: 'Sprache auswählen',
    },
    {
        section: 'text',
        eng: 'The following languages are currently available:',
        ger: 'Folgende Sprachen stehen derzeit zur Auswahl:',
    },
];

export const HOSTS_PAGE_TEXT = [
    {
        section: 'header',
        eng: 'Support non-profit hosts across many sectors from around the world.',
        ger: 'Unterstütze weltweit Gastgeber aus vielen verschiedenen Bereichen.',
    },
    {
        section: 'text',
        eng: 'We aim to build a global network of non-profit organisations united by the common goal of creating positive social, economic and environmental impact. Help us grow by telling your friends and your favourite organisations about socialspace.',
        ger: 'Unser Netzwerk besteht aus wohltätigen Organisationen mit dem gemeinsamen Ziel, positive soziale, wirtschaftliche und ökologische Auswirkungen zu erzielen. Hilf uns zu wachsen, indem du deinen Freunden und wohltätigen Organisationen von socialspace erzählst.',
    },
];

export const HOST_BOX_TEXT = [
    {
        section: 'title',
        eng: 'Workspaces on',
        ger: 'Arbeitsplätze auf',
    },
];

export const FOOTER_TEXT = [
    {
        section: 'title',
        eng: 'Workspaces on',
        ger: 'Arbeitsplätze auf',
    },
];
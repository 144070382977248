export const PRIVACY_POLICY = [
    {
        section: 'title',
        eng: 'Privacy policy',
        ger: 'Datenschutzerklärung',
    },
    {
        section: 'title_1',
        eng: 'Responsible person',
        ger: 'Verantwortlicher',
    },
    {
        section: 'text_1',
        eng: 'The persons responsible for processing your personal data on this website are:',
        ger: 'Die Verantwortlichen für die Verarbeitung Ihrer personenbezogenen Daten auf dieser Website sind:',
    },
    {
        section: 'phillip_1',
        eng: 'Am Griesfeld 2a',
        ger: 'Am Griesfeld 2a',
    },
    {
        section: 'phillip_2',
        eng: '82178 Puchheim',
        ger: '82178 Puchheim',
    },
    {
        section: 'contact',
        eng: 'Contact',
        ger: 'Kontakt',
    },
    {
        section: 'phone',
        eng: 'Phone: +49 174 6832029',
        ger: 'Telefon: +49 174 6832029',
    },
    {
        section: 'mail',
        eng: 'E-mail: ',
        ger: 'E-Mail: ',
    },
    {
        section: 'title_2',
        eng: 'Data protection officer',
        ger: 'Datenschutzbeauftragter',
    },
    {
        section: 'text_2',
        eng: 'Since socialspace is operated by private persons and less than ten persons are permanently engaged in the automated processing of personal data, the appointment of a data protection officer is not required.',
        ger: 'Da socialspace von Privatpersonen betrieben wird und weniger als zehn Personen ständig mit der automatisierten Verarbeitung personenbezogener Daten beschäftigt sind, ist die Bestellung eines Datenschutzbeauftragten nicht erforderlich.',
    },
    {
        section: 'title_3',
        eng: 'Purpose and legal basis of data processing',
        ger: 'Zweck und Rechtsgrundlage der Datenverarbeitung',
    },
    {
        section: 'text_3',
        eng: 'Personal data is processed exclusively on the basis of the statutory provisions, in particular the General Data Protection Regulation (DSGVO) and the Federal Data Protection Act (BDSG).',
        ger: 'Die Verarbeitung personenbezogener Daten erfolgt ausschließlich auf Grundlage der gesetzlichen Bestimmungen, insbesondere der Datenschutz-Grundverordnung (DSGVO) und des Bundesdatenschutzgesetzes (BDSG).',
    },
    {
        section: 'title_3_1',
        eng: 'Contact form',
        ger: 'Kontaktformular',
    },
    {
        section: 'text_3_1',
        eng: "If you send us inquiries via the contact form, your data from the form, including the contact data you provided, is collected and stored for the purpose of processing the inquiry and in case of follow-up questions. This data is sent via a serverless function on Vercel (Vercel CLI) to store the collected data on Google Sheets. For more information on data protection at Vercel, please see Vercel's privacy policy at: https://vercel.com/legal/privacy-policy.  Please note that Google's privacy policy (https://policies.google.com/privacy?hl=de) applies to the storage of data on Google Sheets. The processing of the personal data is based on your consent (Art. 6 para. 1 lit. a DSGVO). You can revoke this consent at any time by email to the contact address above.",
        ger: 'Wenn Sie uns über das Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit via E-Mail an die oben genannten Kontaktadresse widerrufen.',
    },
    {
        section: 'title_3_2',
        eng: 'Reservation form',
        ger: 'Reservierungsformular',
    },
    {
        section: 'text_3_2',
        eng: "When using the reservation form, personal data (name, surname, email & phone number, booking period, workstation type/location/host/price, as well as, other optional information) is collected. This data is sent via an interface (EmailJS) to socialspace.work.impact@gmail.com. We manually forward the data to the requested host so that further steps to complete the booking of workspaces, including payment, can be finalized directly between the guest and host. EmailJS processes the data in accordance with its privacy policy, which can be viewed at https://www.emailjs.com/legal/privacy-policy/. The processing is based on Art. 6 para. 1 lit. b DSGVO. The collected data is stored in our Gmail email account. You can revoke your consent for the processing of your personal data at any time by email to the contact address above. Please note that Google's privacy policy (https://policies.google.com/privacy?hl=de) applies to the storage of data in the Gmail email account.",
        ger: "",
    },
    {
        section: 'title_3_3',
        eng: 'Newsletter registration ("Stay tuned")',
        ger: 'Newsletter Registration',
    },
    {
        section: 'text_3_3',
        eng: "If you register for our newsletter, we collect your email address and name. This data is sent via a serverless function on Vercel (Vercel CLI) to store the collected data on Google Sheets. For more information on data protection at Vercel, please see Vercel's privacy policy at: https://vercel.com/legal/privacy-policy.  Please note that Google's privacy policy (https://policies.google.com/privacy?hl=de) applies to the storage of data on Google Sheets. The processing of the personal data is based on your consent (Art. 6 para. 1 lit. a DSGVO). You can revoke this consent at any time by email to the contact address above.",
        ger: "",
    },
    {
        section: 'title_3_4',
        eng: 'Contact form for interested hosts (“Become a host”)',
        ger: 'Become a Host - Kontaktform',
    },
    {
        section: 'text_3_4',
        eng: "When interested hosts use the contact form to inquire about listing their workspaces on socialspace, the following information is collected: name of organisation/company, address, contact person (name, email, phone number) and other information about office space, availability of workstations, pictures of workstations, etc.. This data is sent via a serverless function on Vercel (Vercel CLI) to store the collected data on Google Sheets for processing the request and in case of follow-up questions. For more information on data protection at Vercel, please see Vercel's privacy policy at: https://vercel.com/legal/privacy-policy.  Please note that Google's privacy policy (https://policies.google.com/privacy?hl=de) applies to the storage of data on Google Sheets. The processing of the personal data is based on your consent (Art. 6 para. 1 lit. a DSGVO). You can revoke this consent at any time by email to the contact address above.",
        ger: "",
    },
    {
        section: 'title_3_5',
        eng: 'Use of data for advertising and user feedback',
        ger: 'Nutzung von Daten für Werbung und Nutzerfeedback',
    },
    {
        section: 'text_3_5_1',
        eng: 'socialspace uses personal data - with the explicit consent of the users - for the following purposes:',
        ger: 'socialspace verwendet personenbezogene Daten – unter expliziter Zustimmung der Nutzer – für folgende Zwecke:',
    },
    {
        section: 'text_3_5_2',
        eng: 'Email marketing',
        ger: 'Werbung',
    },
    {
        section: 'text_3_5_3',
        eng: 'User feedback',
        ger: 'Nutzerfeedback',
    },
    {
        section: 'text_3_5_4',
        eng: 'The processing is based on Art. 6 para. 1 lit. a DSGVO. You can revoke this consent at any time via email to the above contact address.',
        ger: 'Die Verarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO. Sie können diese Einwilligung jederzeit via E-Mail an die oben genannten Kontaktadresse widerrufen.',
    },
    {
        section: 'title_4',
        eng: 'Data sharing',
        ger: 'Weitergabe von Daten',
    },
    {
        section: 'text_4_1',
        eng: 'We do not disclose your personal data to third parties for purposes other than those listed below. We will only share your personal data with third parties if:',
        ger: 'Eine Weitergabe Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:',
    },
    {
        section: 'text_4_2',
        eng: 'you have given your express consent to do so in accordance with Art. 6 (1) p. 1 lit. a DSGVO,',
        ger: 'Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,',
    },
    {
        section: 'text_4_3',
        eng: 'the disclosure is required under Art. 6 (1) p. 1 lit. f DSGVO for the assertion, exercise or defense of legal claims and there is no reason to believe that you have an overriding legitimate interest in not disclosing your data,',
        ger: 'die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,',
    },
    {
        section: 'text_4_4',
        eng: 'in the event that there is a legal obligation for disclosure pursuant to Art. 6 (1) p. 1 lit. c DSGVO,',
        ger: 'für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht,',
    },
    {
        section: 'text_4_5',
        eng: 'this is legally permissible and necessary according to Art. 6 para. 1 p. 1 lit. b DSGVO for the processing of contractual relationships with you.',
        ger: 'gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Wohnsitzes wenden.',
    },
    {
        section: 'title_5',
        eng: 'Duration of storage',
        ger: 'Dauer der Speicherung',
    },
    {
        section: 'text_5',
        eng: 'The personal data processed by us will be deleted or restricted as soon as the purpose of storage ceases to apply. Storage may take place beyond this if this has been provided for by the European or national legislator in Union regulations, laws or other regulations to which the person responsible is subject. Data will also be blocked or deleted if a storage period prescribed by the aforementioned standards expires, unless there is a need to continue storing the data for the conclusion or performance of a contract.',
        ger: 'Die von uns verarbeiteten personenbezogenen Daten werden gelöscht oder eingeschränkt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.',
    },
    {
        section: 'title_6',
        eng: 'Data subject rights',
        ger: 'Betroffenenrechte',
    },
    {
        section: 'text_6_1',
        eng: 'You have the right:',
        ger: 'Sie haben das Recht:',
    },
    {
        section: 'text_6_2',
        eng: 'in accordance with Art. 15 DSGVO, to request information about your personal data processed by us. In particular, you can request information about the processing purposes, the category of personal data, the categories of recipients to whom your data has been or will be disclosed, the planned storage period, the existence of a right to rectification, erasure, restriction of processing or objection, the existence of a right of complaint, the origin of your data if it has not been collected by us, as well as the existence of automated decision-making, including profiling, and, if applicable, meaningful information about its details;',
        ger: 'gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft Ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten zu verlangen;',
    },
    {
        section: 'text_6_3',
        eng: 'pursuant to Art. 16 DSGVO, to request the correction of incorrect or incomplete personal data stored by us without undue delay;',
        ger: 'gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;',
    },
    {
        section: 'text_6_4',
        eng: 'pursuant to Art. 17 DSGVO, to request the erasure of your personal data stored by us, unless the processing is necessary for the exercise of the right to freedom of expression and information, for compliance with a legal obligation, for reasons of public interest or for the assertion, exercise or defense of legal claims;',
        ger: 'gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;',
    },
    {
        section: 'text_6_5',
        eng: 'in accordance with Art. 18 DSGVO to request the restriction of the processing of your personal data, insofar as the accuracy of the data is disputed by you, the processing is unlawful, but you object to its erasure and we no longer require the data, but you need it for the assertion, exercise or defense of legal claims or you have objected to the processing in accordance with Art. 21 DSGVO;',
        ger: 'gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;',
    },
    {
        section: 'text_6_6',
        eng: 'pursuant to Art. 20 DSGVO, to receive your personal data that you have provided to us in a structured, common and machine-readable format or to request that it be transferred to another controller;',
        ger: 'gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;',
    },
    {
        section: 'text_6_7',
        eng: 'in accordance with Art. 7 (3) DSGVO, to revoke your consent once given to us at any time. This has the consequence that we may no longer continue the data processing , which was based on this consent, for the future;',
        ger: 'gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung , die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen;',
    },
    {
        section: 'text_6_8',
        eng: 'complain to a supervisory authority in accordance with Art. 77 DSGVO. As a rule, you can contact the supervisory authority of your usual place of residence or workplace or our place of residence for this purpose.',
        ger: 'gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Wohnsitzes wenden.',
    },
    {
        section: 'title_7',
        eng: 'Right of objection',
        ger: 'Widerspruchsrecht',
    },
    {
        section: 'text_7_1',
        eng: 'If your personal data is processed on the basis of legitimate interests pursuant to Art. 6 (1) p. 1 lit. f DSGVO, you have the right to object to the processing of your personal data pursuant to Art. 21 DSGVO, provided that there are grounds for doing so that arise from your particular situation or the objection is directed against direct advertising. In the latter case, you have a general right of objection, which is implemented by us without specifying a particular situation.',
        ger: 'Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.',
    },
    {
        section: 'text_7_2',
        eng: 'If you would like to exercise your right of revocation or objection, it is sufficient to send an e-mail to ',
        ger: 'Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an ',
    },
    {
        section: 'title_8',
        eng: 'Data security',
        ger: 'Datensicherheit',
    },
    {
        section: 'text_8_1',
        eng: 'Within the website visit, we use the widespread SSL procedure (Secure Socket Layer) in connection with the highest encryption level supported by your browser. As a rule, this is a 256-bit encryption. If your browser does not support 256-bit encryption, we use 128-bit v3 technology instead. You can tell whether an individual page of our website is transmitted in encrypted form by the closed display of the key or lock symbol in the lower status bar of your browser.',
        ger: 'Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftritts verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- bzw. Schloss-Symbols in der unteren Statusleiste Ihres Browsers.',
    },
    {
        section: 'text_8_2',
        eng: 'We also use appropriate technical and organisational security measures to protect your data against accidental or intentional manipulation, partial or complete loss, destruction or against unauthorized access by third parties. Our security measures are continuously improved in line with technological developments.',
        ger: 'Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.',
    },
    {
        section: 'title_9',
        eng: 'Vercel Hosting and Vercel Analytics',
        ger: 'Server-Log-Dateien',
    },
    {
        section: 'text_9_1',
        eng: 'We use Vercel, Inc (340 S Lemon Ave #4133, Walnut, CA 91789, USA) as the hosting provider for our website and Vercel Analytics to analyze and improve the use of our website. Vercel Analytics is a privacy-friendly web analytics service that does not use cookies and does not collect personally identifiable information. The data collected by Vercel Analytics includes general information such as number of visitors, pages viewed, dwell time, and similar information that helps us optimize the user experience and content of our website.',
        ger: 'In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:',
    },
    {
        section: 'text_9_2',
        eng: "Vercel and Vercel Analytics are used on the basis of Art. 6 (1) lit. f DSGVO, as we have a legitimate interest in constantly improving our website and presenting it in a technically flawless manner.For more information on data protection at Vercel, please see Vercel's privacy policy at: https://vercel.com/legal/privacy-policy.",
        ger: 'Besuchte Seite auf unserer Domain',
    },
    {
        section: 'title_10',
        eng: 'Google web fonts',
        ger: 'Google Web Fonts',
    },
    {
        section: 'text_10_1',
        eng: 'Our website uses web fonts from Google. The provider is Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.',
        ger: 'Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.',
    },
    {
        section: 'text_10_2',
        eng: 'By using these web fonts, it is possible to present you with the presentation of our website that we desire, regardless of which fonts are available to you locally. This is done by retrieving the Google Web Fonts from a Google server in the USA and the associated transfer of your data to Google. This is your IP address and which of our pages you have visited. The use of Google Web Fonts is based on Art. 6 para. 1 lit. f DSGVO. As the operator of this website, we have a legitimate interest in the optimal presentation and transmission of our website.',
        ger: 'Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns gewünschte Darstellung unserer Website zu präsentieren, unabhängig davon welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt über den Abruf der Google Web Fonts von einem Server von Google in den USA und der damit verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der optimalen Darstellung und Übertragung unseres Webauftritts.',
    },
    {
        section: 'text_10_3',
        eng: 'The company Google is certified for the us-European data protection agreement "Privacy Shield". This data protection agreement is intended to ensure compliance with the level of data protection applicable in the EU.',
        ger: 'Das Unternehmen Google ist für das us-europäische Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleisten.',
    },
    {
        section: 'text_10_4',
        eng: "Details about Google Web Fonts can be found at: ",
        ger: 'Einzelheiten über Google Web Fonts finden Sie unter: ',
    },
    {
        section: 'text_10_5',
        eng: " and further information in Google's privacy policy: ",
        ger: ' und weitere Informationen in den Datenschutzbestimmungen von Google:',
    },
    {
        section: 'title_11',
        eng: 'Social media',
        ger: 'Soziale Medien',
    },
    {
        section: 'title_11_1',
        eng: 'LinkedIn plug-in',
        ger: 'LinkedIn Plug-In',
    },
    {
        section: 'text_11_1_1',
        eng: 'Our website uses functions of the LinkedIn network. The provider is LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. Each time one of our pages containing LinkedIn functions is accessed, a connection to LinkedIn servers is established. LinkedIn is informed that you have visited our web pages with your IP address. If you click the "Recommend button" of LinkedIn and are logged into your account at LinkedIn, it is possible for LinkedIn to assign your visit to our website to you and your user account. We point out that we as the provider of the pages have no knowledge of the content of the transmitted data and its use by LinkedIn.',
        ger: 'Unsere Website verwendet Funktionen des Netzwerks LinkedIn. Anbieter ist die LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. Bei jedem Abruf einer unserer Seiten, die Funktionen von LinkedIn enthält, wird eine Verbindung zu Servern von LinkedIn aufgebaut. LinkedIn wird darüber informiert, dass Sie unsere Internetseiten mit Ihrer IP-Adresse besucht haben. Wenn Sie den "Recommend-Button" von LinkedIn anklicken und in Ihrem Account bei LinkedIn eingeloggt sind, ist es LinkedIn möglich, Ihren Besuch auf unserer Internetseite Ihnen und Ihrem Benutzerkonto zuzuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch LinkedIn haben.',
    },
    {
        section: 'text_11_1_2',
        eng: "The use of the LinkedIn plug-in is in the interest of an appealing presentation of our online offers and to make it easy to find the places we indicate on the website. This constitutes a legitimate interest within the meaning of Art. 6 (1) lit. f DSGVO. For more information, please see LinkedIn's privacy policy at:",
        ger: 'Die Nutzung des LinkedIn-Plug-Ins erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und um eine leichte Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von LinkedIn unter:',
    },
    {
        section: 'title_11_2',
        eng: 'Instagram plug-in',
        ger: 'Instagram Plug-In',
    },
    {
        section: 'text_11_2_1',
        eng: 'On our website, functions of the service Instagram are integrated. These functions are offered by Instagram Inc, 1601 Willow Road, Menlo Park, CA 94025, USA. If you are logged into your Instagram account, you can link the content of our pages to your Instagram profile by clicking on the Instagram button. This allows Instagram to associate the visit to our pages with your user account. We would like to point out that we, as the provider of the pages, have no knowledge of the content of the transmitted data or its use by Instagram.',
        ger: 'Auf unserer Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA 94025, USA. Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch Anklicken des Instagram-Buttons die Inhalte unserer Seiten mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten.',
    },
    {
        section: 'text_11_2_2',
        eng: "The use of the Instagram plug-in is based on Art. 6 (1) lit. f DSGVO. For more information, please see Instagram's privacy policy:",
        ger: 'Die Nutzung des Instagram-Plug-Ins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram:',
    },
    {
        section: 'title_11_3',
        eng: 'Twitter plug-in',
        ger: 'Twitter Plug-In',
    },
    {
        section: 'text_11_3_1',
        eng: 'On our pages, functions of the service Twitter are integrated. These functions are offered by Twitter Inc, 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. By using Twitter and the "Re-Tweet" function, the websites you visit are linked to your Twitter account and made known to other users. In the process, data is also transferred to Twitter. We would like to point out that we, as the provider of the pages, have no knowledge of the content of the transmitted data or its use by Twitter.',
        ger: 'Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen werden angeboten durch die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Durch das Benutzen von Twitter und der Funktion "Re-Tweet" werden die von Ihnen besuchten Websites mit Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt gegeben. Dabei werden auch Daten an Twitter übertragen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Twitter erhalten.',
    },
    {
        section: 'text_11_3_2',
        eng: "The use of the Twitter plug-in is based on Art. 6 (1) lit. f DSGVO. For more information, please refer to the privacy policy of Twitter at:",
        ger: 'Die Nutzung des Twitter-Plug-Ins erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Twitter unter:',
    },
    {
        section: 'title_12',
        eng: 'Browser plugin',
        ger: 'Aktualität und Änderung dieser Datenschutzerklärung',
    },
    {
        section: 'text_12',
        eng: 'The setting of cookies by your web browser can be prevented. However, some functions of our website could be restricted as a result. Likewise, you can prevent the collection of data relating to your website usage, including your IP address, together with subsequent processing by Google. This is possible by downloading and installing the browser plugin accessible via the following link: https://tools.google.com/dlpage/gaoptout?hl=de.',
        ger: 'Diese Datenschutzerklärung ist aktuell gültig und hat den Stand (01.05.2023). Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter ',
    },
    {
        section: 'title_13',
        eng: 'Google Maps API Integration',
        ger: 'Aktualität und Änderung dieser Datenschutzerklärung',
    },
    {
        section: 'text_13',
        eng: "We use Google Maps API, a service provided by Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA), to offer location-based features and enhance user experience on our website. Google Maps API does not require cookies and may collect data, such as location information, in accordance with Google's Privacy Policy. The use of Google Maps API is based on Art. 6 (1) lit. f DSGVO, as we have a legitimate interest in providing our users with an optimized and seamless experience on our platform. Google Inc. is certified under the US-European data protection agreement 'Privacy Shield', which aims to ensure compliance with the level of data protection applicable in the EU. For more information on data protection at Google and the Google Maps API, please refer to Google's privacy policy at: https://policies.google.com/privacy. By using our services and the location-based features offered through Google Maps API, you acknowledge and agree to the data collection, usage, and processing practices outlined in Google's Privacy Policy.",
        ger: 'Diese Datenschutzerklärung ist aktuell gültig und hat den Stand (01.05.2023). Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter ',
    },
    {
        section: 'title_14',
        eng: 'Up-to-datedness and changes',
        ger: 'Aktualität und Änderung dieser Datenschutzerklärung',
    },
    {
        section: 'text_14_1',
        eng: 'This data protection declaration is currently valid and has the status (01.05.2023). Due to the further development of our website and offers on it or due to changed legal or regulatory requirements, it may become necessary to change this privacy policy. The current data protection declaration can be accessed and printed out by you at any time on the website under ',
        ger: 'Diese Datenschutzerklärung ist aktuell gültig und hat den Stand (01.05.2023). Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter ',
    },
    {
        section: 'text_14_2',
        eng: '.',
        ger: ' von Ihnen abgerufen und ausgedruckt werden.',
    },
];
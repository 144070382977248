import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useStyles } from './FooterMobile.styles';
import { AppContext } from '../../other/contexts/AppContext';
import { FOOTER } from '../../../data/translation/TranslationFooter';

export default function FooterMobile() {

    const { language } = useContext(AppContext);

    const classes = useStyles();

    return (
        <Box px={3} pt={3} pb={2.5} display="flex" flexDirection="column" className={classes.footer_container}>

            <Link to={`/`} style={{ textDecoration: 'none' }}>
                <Typography variant="body3" className={classes.footer_text}>socialspace, 2024</Typography>
            </Link>

            <Box pt={3} display="flex" flexDirection="column" className={classes.footer_container}>
                <Link to={`/termsofuse`} className={classes.link}>
                    <Typography variant="body3" className={classes.footer_text}>{FOOTER[FOOTER.map(object => object.section).indexOf('terms_of_use')][language]}</Typography>
                </Link>
                <Link to={`/imprint`} className={classes.link}>
                    <Typography variant="body3" className={classes.footer_text}>{FOOTER[FOOTER.map(object => object.section).indexOf('imprint')][language]}</Typography>
                </Link>
                <Link to={`/privacypolicy`} className={classes.link}>
                    <Typography variant="body3" className={classes.footer_text}>{FOOTER[FOOTER.map(object => object.section).indexOf('privacy_policy')][language]}</Typography>
                </Link>
            </Box>

        </Box>


    )
}
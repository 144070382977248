import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    socialspace_box: {
        minHeight: '200px',
        width: '680px',
        overflow: 'hidden',
        borderRadius: '8px',
    },
    office_picture: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.spaceImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '300px',
        height: 'auto',
    },
    host_picture: {
        display: 'flex',
        flexGrow: 1,
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.hostLogo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '40px',
        minWidth: '80px',
    },
}));
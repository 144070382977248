import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { useStyles } from './FooterDesktop.styles';
import { AppContext } from '../../other/contexts/AppContext';
import { green1000 } from '../../other/helper/MuiStyles';
import { HOMEPAGE_DESKTOP } from '../../../data/translation/TranslationHomepageDesktop';
import Icon from '../../../assets/icons/Icon';

export default function FooterDesktop({specialWidth}) {

    const { language } = useContext(AppContext);

    const classes = useStyles();

    return (
        <Box px={specialWidth ? specialWidth : '10vw'} pt={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ backgroundColor: green1000 }}>
            <Box pb={1} display="flex" alignItems="center" justifyContent="space-between" style={{ width: '-webkit-fill-available', height: '24px' }}>
                <Typography variant="body3" className={classes.footer} width='30vw'>socialspace, 2024</Typography>
                <Box display="flex" alignItems="center" height='24px'>
                    <IconButton
                        component="a"
                        href="https://instagram.com/socialspacework?igshid=YmMyMTA2M2Y="
                        target="_blank"
                        rel="noopener noreferrer"
                        color="secondary"
                        className={classes.icon_button_tertiary}
                    >
                        <Icon width={18} height={18} iconName="Instagram" />
                    </IconButton>
                    <IconButton
                        component="a"
                        href="https://twitter.com/socialspacework?s=11&t=U-yVjKdFPsf1bZ9fImbBwA"
                        target="_blank"
                        rel="noopener noreferrer"
                        color="secondary"
                        className={classes.icon_button_tertiary}
                    >
                        <Icon width={18} height={18} iconName="Twitter" />
                    </IconButton>
                    <IconButton
                        component="a"
                        href="https://www.linkedin.com/company/socialspacework/"
                        target="_blank"
                        rel="noopener noreferrer"
                        color="secondary"
                        className={classes.icon_button_tertiary}
                    >
                        <Icon width={18} height={18} iconName="LinkedIn" />
                    </IconButton>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="flex-end" width='30vw'>
                    <Link to={`/termsofuse`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('terms')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/imprint`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('imprint')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/privacypolicy`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('privacy')][language]}
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </Box>


    )
}
import React from 'react';
import { Box, Radio, Typography } from '@mui/material';
import { useStyles } from './WorkspaceTypeBoxDesktop.styles';
import { green100, green1000, green50, grey200, grey800 } from '../../other/helper/MuiStyles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper';

export default function WorkspaceTypeBoxDesktop({ workspaceType, workspaceDescription, selectedWorkspaceType, fake, refWorkspaceType }) {

    const styleProps = {
        backgroundColorSelected: selectedWorkspaceType === workspaceType ? green100 : '',
        hoverColor: fake ? '' : selectedWorkspaceType === workspaceType ? '' : green50,
        cursor: fake ? '' : 'pointer',
        imgWorkspace: IMAGE_MAP.ILLUSTRATIONS[workspaceType],
        borderSelected: selectedWorkspaceType === workspaceType ? `2px solid ${green1000}` : `1px solid ${grey200}`,
        maxHeightBox: `${refWorkspaceType.current?.offsetHeight}px`,
    };

    const classes = useStyles(styleProps);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" className={classes.workspace_box} >
            <Box display="flex" flexDirection="column" alignItems="center">
                <div className={classes.workspace_illustration} title="WorkspaceIllustration"></div>
                <Box pt={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} style={{ width: '20vw' }}>
                    <Typography variant='h4' style={{ textAlign: 'center' }}>
                        {workspaceType}
                    </Typography>
                    <Typography pt={1} pb={2} variant='body2' style={{ color: grey800, textAlign: 'center' }}>
                        {workspaceDescription}
                    </Typography>
                </Box>
            </Box>
            <Radio
                disableRipple
                checked={selectedWorkspaceType === workspaceType}
                value="selectedWorkspaceType"
            />
        </Box>
    )
}
import makeStyles from "@mui/styles/makeStyles";
import { green100, green800, green900, grey1000 } from '../../../components/other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    background_image_header: {
        width: '100vw',
        backgroundImage: props => `url(${props.backgroundImgHeader})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
    // background_image_ocean: {
    //     height: '100%',
    //     backgroundImage: props => `url(${props.backgroundImgOcean})`,
    //     backgroundSize: 'cover',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center center',
    // },
    app_header: {
        width: '100vw',
        height: '80px',
    },
    logo_header: {
        fontFamily: 'Poppins600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFF',
    },
    containedPrimary: {
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px 16px',
        height: '40px',
        backgroundColor: green800,
        color: '#FFF',
        '&:hover': {
            backgroundColor: green900,
        },
    },
    image_content_container: {
        height: '100vh',
        background: 'linear-gradient(to bottom, rgba(4, 35, 37, 0.95), rgba(4, 35, 37, 0) 80%)',
    },
    headline: {
        color: '#FFF',
        textAlign: 'center',
    },
    headline_highlight: {
        marginLeft: '8px',
        color: '#FFF',
    },
    headline_caption: {
        fontFamily: 'Poppins600',
        color: grey1000,
        fontSize: '22px',
        lineHeight: '34px',
        letterSpacing: '0.005em',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    headline_text: {
        fontFamily: 'Poppins400',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.03em',
        color: grey1000,
        textAlign: 'center',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    highlight_number: {
        fontFamily: 'Poppins600',
        color: '#FFF',
        fontSize: '56px',
        lineHeight: '84px',
        letterSpacing: '0.002em',
        textAlign: 'center',
    },
    mission_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgMission})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '60vw',
        minWidth: '60vw',
    },
    partner_box: {
        textDecoration: 'none',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        '&:hover': {
            boxShadow: '0px 5px 25px rgba(54, 79, 81, 0.3)',
        },
        margin: '24px 24px 24px 0',
        height: props => props.heightWorkspaceBox,
    },
    icon_button_tertiary: {
        padding: '0 8px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0)',
        },
    },   
    logo: {
        fontFamily: 'Poppins600',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.03em',
        color: '#FFF',
    },  
    host_box: {
        width: '70vw',
        textDecoration: 'none',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        '&:hover': {
            boxShadow: '0px 5px 25px rgba(54, 79, 81, 0.3)',
        },
        margin: '24px 24px 24px 0',
        height: props => props.heightHostBox,
    },
    socialspace_box: {
        width: '70vw',
        textDecoration: 'none',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        '&:hover': {
            boxShadow: '0px 5px 25px rgba(54, 79, 81, 0.3)',
        },
        margin: '24px 24px 24px 0',
        height: props => props.heightWorkspaceBox,
    },
    text_box: {
        textAlign: 'center',
        width: 'calc(100vw)',
    },
    hosts_container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginBottom: '40px',
    },
    workspace_container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginBottom: '40px',
    },
    image_list: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    footer: {
        color: green100,
    },
    link: {
        textDecoration: 'none',
        paddingBottom: '4px',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },
}));
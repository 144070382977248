import { makeStyles } from '@mui/styles';
import { grey600 } from '../../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    socialspace_box: {
        overflow: 'hidden',
        borderRadius: '8px',
    },
    office_picture: {
        backgroundPosition: 'center center',
        backgroundImage: props => `url(${props.spaceImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minWidth: 'auto',
        height: '200px',
    },
    host_picture: {
        display: 'flex',
        flexGrow: 1,
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.hostLogo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '24px',
        maxWidth: '64px',
    },
    host_text: {
        width: 'calc(70vw - 64px - 32px - 16px)',
        color: grey600,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    middle_box: {        
        height: props => props.heightGrow,
    },
    workspace_text: {
        width: 'calc(70vw - 64px - 32px - 16px)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));
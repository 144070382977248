import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, ImageList, ImageListItem, IconButton } from '@mui/material';
import { useStyles } from './HostPageMobile.styles';
import { HOSTS, WORKSPACETYPE, HOMEPAGE_WORKSPACES } from '../../../data/data';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import ImageCarousel from '../../../components/ImageCarousel/ImageCarousel';
import { green1000, grey100, grey800 } from '../../../components/other/helper/MuiStyles';
import WorkspaceBoxMobile from '../../../components/WorkspaceBox/Homepage/mobile/WorkspaceBoxMobile';
import { Link } from 'react-router-dom';
import { HOMEPAGE_MOBILE } from '../../../data/translation/TranslationHomepageMobile';
import Icon from '../../../assets/icons/Icon';
import { AppContext } from '../../../components/other/contexts/AppContext';

export default function HostPageMobile() {
    const { language } = useContext(AppContext);
    let { id } = useParams();
    const refWorkspaces = useRef(null);

    const hostDetail = HOSTS.find(object => {
        return object.hostId === id;
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const styleProps = {
        partnerImg: IMAGE_MAP.PARTNERS[hostDetail.name],
        contactImg: IMAGE_MAP.GENERAL['Jasmina'],
        heightWorkspaceBox: `calc(${refWorkspaces.current?.offsetHeight}px - 48px)`,
        marginBottomWorkspaceTypeBox: WORKSPACETYPE.length - 1 ? '40px' : '0px',
    };

    const getImageUrls = (id) => {
        const host = HOSTS.find(
            (host) => host.hostId === id
        );

        if (!host) {
            return [];
        }

        const { pictures } = host;
        const imageUrls = pictures.map(
            (picture) => IMAGE_MAP.HOST_PICTURES[picture]
        );

        return imageUrls;
    };

    const images = getImageUrls(id);

    const classes = useStyles(styleProps);

    return (
        <>
            <ImageCarousel images={images} />
            <Box py={4} px={3}>
                <div className={classes.partner_logo} title="HostLogo"></div>
            </Box>
            <Box mx={3} pb={5} display="flex" flexDirection="column">
                <Typography variant='h3Mobile'>
                    {hostDetail.name}
                </Typography>
                <Typography mt={3} variant="body2" style={{ fontFamily: 'Poppins600' }}>
                    Headquarter
                </Typography>
                <Typography mt={0.5} variant="body1" style={{ color: grey800 }}>
                    {hostDetail.headquarter}
                </Typography>
                <Typography mt={3} variant="body2" style={{ fontFamily: 'Poppins600' }}>
                    Founded
                </Typography>
                <Typography mt={0.5} variant="body1" style={{ color: grey800 }}>
                    {hostDetail.founded}
                </Typography>
                <Typography mt={3} variant="body2" style={{ fontFamily: 'Poppins600' }}>
                    Sector
                </Typography>
                {hostDetail.sector.map((sector, index) => (
                    <Box key={index} mt={0.5} display="flex">
                        <Typography variant="body1" className={classes.bullet_point}>
                            •
                        </Typography>
                        <Typography variant="body1" style={{ color: grey800 }}>
                            {sector}
                        </Typography>
                    </Box>
                ))
                }
                <Typography mt={3} variant="body2" style={{ fontFamily: 'Poppins600' }}>
                    Region
                </Typography>
                {hostDetail.region.map((region, index) => (
                    <Box key={index} mt={0.5} display="flex">
                        <Typography variant="body1" className={classes.bullet_point}>
                            •
                        </Typography>
                        <Typography variant="body1" style={{ color: grey800 }}>
                            {region}
                        </Typography>
                    </Box>
                ))
                }
                <Typography mt={3} variant="body2" style={{ fontFamily: 'Poppins600' }}>
                    Website
                </Typography>
                <Typography mt={0.5} variant="body1">
                    <a href={hostDetail.website} target="_blank" rel="noopener noreferrer" className={classes.link_extern}>{hostDetail.website}</a>
                </Typography>
            </Box>

            {/* IMPACT */}
            <Box mx={3} py={5} display="flex" flexDirection="column" style={{ borderTop: `1px solid ${grey100}` }}>
                <Typography variant='h4Mobile' style={{ color: green1000 }}>
                    Host description
                </Typography>
                <Typography pt={3} variant='body1' style={{ color: grey800, whiteSpace: 'pre-line' }}>
                        {hostDetail.longDescription}
                    </Typography>
            </Box>

            {/* WORKSPACES */}
            <Box mx={3} pt={5} pb={1} display="flex" flexDirection="column" style={{ borderTop: `1px solid ${grey100}` }}>
                <Typography variant='h4Mobile' style={{ color: green1000 }}>
                    Workspaces of host
                </Typography>
            </Box>
            <div className={classes.workspace_container}>
                <ImageList ref={refWorkspaces} className={classes.image_list} cols={HOMEPAGE_WORKSPACES.length + 1}>
                    {HOMEPAGE_WORKSPACES.filter(option => option.host === hostDetail.name).map((option, index) => (
                            <ImageListItem key={option.socialspaceId}>
                                <Link to={`/workspace/${option.socialspaceId}`} key={option.socialspaceId} className={classes.socialspace_box} style={{ marginLeft: index === 0 ? '24px' : '' }}>
                                    <WorkspaceBoxMobile key={option.socialspaceId} socialspace={option} />
                                </Link>
                            </ImageListItem>
                        ))}
                </ImageList>
            </div>

            {/* FOOTER */}
            <Box px={3} pt={4} display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: green1000 }}>
                <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography className={classes.logo}>socialspace</Typography>
                    <Box display="flex" alignItems="center" height='20px'>
                        <IconButton
                            component="a"
                            href="https://instagram.com/socialspacework?igshid=YmMyMTA2M2Y="
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="Instagram" />
                        </IconButton>
                        <IconButton
                            component="a"
                            href="https://twitter.com/socialspacework?s=11&t=U-yVjKdFPsf1bZ9fImbBwA"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="Twitter" />
                        </IconButton>
                        <IconButton
                            component="a"
                            href="https://www.linkedin.com/company/socialspacework/"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="LinkedIn" />
                        </IconButton>
                    </Box>
                </Box>
                <Box pb={3.5} display="flex" flexDirection="column">
                    <Link to={`/termsofuse`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('terms')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/imprint`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('imprint')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/privacypolicy`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('privacy')][language]}
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </>
    )
}
export const HOMEPAGE_MOBILE = [
    {
        section: 'caption_1',
        eng: 'Book a workspace and support non-profit organisations.',
        ger: 'Fördere',
    },
    // {
    //     section: 'caption_1',
    //     eng: 'Foster',
    //     ger: 'Fördere',
    // },
    // {
    //     section: 'caption_2',
    //     eng: 'while coworking',
    //     ger: 'durch Coworking',
    // },
    {
        section: 'header_button',
        eng: 'Book workspace',
        ger: 'Arbeitsplatz buchen',
    },
    // {
    //     section: 'caption_section1',
    //     eng: 'makes finding workspaces easy and convenient.',
    //     ger: 'lässt dich Arbeitsplätze einfach und bequem finden - und ermöglicht positive Impulse.',
    // },
    {
        section: 'caption_section1',
        eng: 'socialspace is a unique coworking platform.',
        ger: 'lässt dich Arbeitsplätze einfach und bequem finden - und ermöglicht positive Impulse.',
    },
    {
        section: 'text_section1',
        eng: 'Our core offering empowers individuals, non-profit organisations and corporates to find and book workspaces offered by non-profit organisations.',
        ger: 'Wohltätige Organisationen können ihre nicht genutzten Büros und Arbeitsplätze vorübergehend an Personen und Organisationen vermieten.',
    },
    // {
    //     section: 'text_section1',
    //     eng: 'socialspace is a unique coworking platform fostering collaboration and sustainability, by connecting individuals, businesses, and non-profit organisations, enabling positive impact.',
    //     ger: 'Wohltätige Organisationen können ihre nicht genutzten Büros und Arbeitsplätze vorübergehend an Personen und Organisationen vermieten.',
    // },
    {
        section: 'caption_section2',
        eng: 'Why socialspace?',
        ger: 'Warum socialspace?',
    },
    {
        section: 'text_section2',
        eng: 'Non-profit organisations play an essential role in our society by addressing various social, environmental, and humanitarian issues facing the world today. Without them, many people would struggle to access crucial services, and important issues might not receive the attention they deserve. socialspace helps you to empower non-profit organisations.',
        ger: 'Gemeinnützige Organisationen spielen eine wichtige Rolle in unserer Gesellschaft, indem sie sich für die verschiedenen sozialen, ökologischen und humanitären Probleme einsetzen, mit denen die Welt heute konfrontiert ist. Ohne sie hätten viele Menschen Schwierigkeiten, Zugang zu wichtigen Dienstleistungen zu erhalten, und wichtige Themen würden möglicherweise nicht die Aufmerksamkeit erhalten, die sie verdienen.',
    },
    {
        section: 'title_benefit1_section2',
        eng: 'Support your charity',
        ger: 'Unterstütze deine gemeinnützige Organisation',
    },
    {
        section: 'title_benefit2_section2',
        eng: 'Stop wasting unused space',
        ger: 'Keine Verschwendung von ungenutztem Raum',
    },
    {
        section: 'title_benefit3_section2',
        eng: 'Join a unique community',
        ger: 'Tritt einer einzigartigen Community bei',
    },
    {
        section: 'caption_section3',
        eng: 'Book your unique coworking experience.',
        ger: 'Buche dein einzigartiges Coworking-Erlebnis.',
    },
    {
        section: 'text_section3',
        eng: 'We aim to make booking a workspace easy and convenient. It just takes three steps.',
        ger: 'Wir wollen die Buchung eines Arbeitsplatzes bei einer gemeinnützigen Organisation so einfach und bequem wie möglich machen.',
    },
    {
        section: 'title_step1_section3',
        eng: 'Search workspace',
        ger: 'Arbeitsplatz suchen',
    },
    {
        section: 'text_step1_section3',
        eng: 'Explore and choose unique workspaces that suit your needs at a host you would like to support.',
        ger: 'Suche nach einem Arbeitsplatz, der deinen Bedürfnissen entspricht, oder nach einer Organisation, die du unterstützen möchtest.',
    },
    {
        section: 'title_step2_section3',
        eng: 'Request workspace',
        ger: 'Arbeitsplatz anfragen',
    },
    {
        section: 'text_step2_section3',
        eng: 'Send a reservation request to the selected host. The host will contact you and confirm the booking.',
        ger: 'Sende eine Anfrage an die gemeinnützige Organisation über socialspace. Diese wird dich kontaktieren und die Buchung bestätigen.',
    },
    {
        section: 'title_step3_section3',
        eng: 'Enjoy working',
        ger: 'Genieße die Arbeit',
    },
    {
        section: 'text_step3_section3',
        eng: 'Enjoy your unique coworking environment while enabling positive impact.',
        ger: 'Genieße das einzigartige Arbeitsumfeld bei der gemeinnützigen Organisation und leiste gleichzeitig einen positiven und nachhaltigen Beitrag.',
    },
    {
        section: 'vision_header',
        eng: 'Our mission is to help non-profit organisations utilise resources efficiently by enabling them to generate income through office letting, fostering collaboration and sustainable impact.',
        ger: 'Unsere Vision ist es, die Abhängigkeit von wohltätigen Organisationen zu verringern und sie zu befähigen ihre Vision zu verfolgen und ihre Wirkung zu maximieren.',
    },
    {
        section: 'vision_text',
        eng: 'Our vision is to create a network of sustainable workspaces that nurture innovation and collaboration, enabling non-profit organisations to diversify income streams and drive positive change.',
        ger: 'Dabei streben wir nach positiven sozialen, wirtschaftlichen und ökologischen Auswirkungen.',
    },
    {
        section: 'vision_button',
        eng: 'Find out more',
        ger: 'Lerne uns kennen',
    },
    // {
    //     section: 'stats1',
    //     eng: 'generated for non-profit organisations',
    //     ger: 'für gemeinnützige Organisationen geschaffen',
    // },
    {
        section: 'stats2',
        eng: 'listed non-profit organisation',
        ger: 'gelistete gemeinnützige Organisation',
    },
    {
        section: 'stats3',
        eng: 'coworking cities',
        ger: 'Coworking Städte',
    },
    {
        section: 'caption1_section4',
        eng: 'The world is dependent on non-profit organisations.',
        ger: 'Die Welt ist auf wohltätige Organisationen angewiesen.',
    },
    {
        section: 'caption2_section4',
        eng: 'Non-profit organistaions are dependent on you.',
        ger: 'Wohltätige Organisationen sind auf externe Ressourcen angewiesen.',
    },
    {
        section: 'text_section4',
        eng: 'Since May 2023 we are in our prototype test phase in Berlin, which runs until August 2023. Throughout, we seek to both better understand our hosts’ and guests’ demands, and to evaluate the underlying assumptions and overall feasibility of socialspace’s unique coworking concept.',
        ger: 'Wohltätige Organisationen können ihre nicht genutzten Büros und Arbeitsplätze vorübergehend an Personen und Organisationen vermieten.',
    },
    {
        section: 'footer_text',
        eng: 'While we start as a private project, our ambition is to evolve socialspace into a purpose-driven company, potentially operating as a not-for-profit social enterprise. Our commitment is to make a positive impact in the non-profit industry and drive innovation in the coworking world.',
        ger: 'socialspace begann als privates Projekt und wir befinden uns derzeit in der Prototyp-Testphase, die von Mai bis Juli 2023 in Berlin läuft. Wir schätzen Eure Teilnahme und Euer Feedback während dieser wichtigen Phase, da es uns helfen wird, unser Angebot zu verfeinern und zu verbessern, um den wachsenden Bedürfnissen unserer Community-Mitglieder gerecht zu werden. Besonders gespannt sind wir auf den Austausch mit den einzelnen Coworkern, von denen wir glauben, dass sie unser einzigartiges Coworking-Konzept maßgeblich mitgestalten werden.',
    },
    {
        section: 'terms',
        eng: 'Terms of use',
        ger: 'Nutzungsbedingungen',
    },
    {
        section: 'imprint',
        eng: 'Imprint',
        ger: 'Impressum',
    },
    {
        section: 'privacy',
        eng: 'Privacy policy',
        ger: 'Datenschutzerklärung',
    },
];
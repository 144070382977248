import React, { useContext, useEffect } from 'react';
import { Paper, Box, Grid, Typography, Button } from '@mui/material';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import { useStyles } from './HomepageDesktop.styles';
import { HOMEPAGE_WORKSPACES, HOSTS } from '../../../data/data';
import { Link } from 'react-router-dom';
import { green100, green1000, green50, green600, grey800 } from '../../../components/other/helper/MuiStyles';
import HostBox from '../../../components/other/HostBox/HostBox';
import { AppContext } from '../../../components/other/contexts/AppContext';
import JoinBox from '../../../components/other/HostBox/JoinBox';
import WorkspaceBox from '../../../components/Homepage/WorkspaceBox/WorkspaceBox';
import Icon from '../../../assets/icons/Icon';
import { HOMEPAGE_DESKTOP } from '../../../data/translation/TranslationHomepageDesktop';
import FooterDesktop from '../../../components/Footer/desktop/FooterDesktop';
import EmailRegistration from '../../../components/other/dialogs/EmailRegistration/EmailRegistration';

export default function HomepageDesktop() {

    const { language } = useContext(AppContext);

    const styleProps = {
        backgroundImgHeader: IMAGE_MAP.GENERAL['Homepage_Picture'],
        backgroundImgKid: IMAGE_MAP.GENERAL['Kid'],
        backgroundImgOcean: IMAGE_MAP.GENERAL['Ocean'],
        backgroundImgMission: IMAGE_MAP.GENERAL['Mission'],
        backgroundImgStep1: IMAGE_MAP.GENERAL['Step1'],
        backgroundImgStep2: IMAGE_MAP.GENERAL['Step2'],
        backgroundImgStep3: IMAGE_MAP.GENERAL['Step3'],
    };

    const classes = useStyles(styleProps);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Grid item xs={12} display="grid">
            <Paper className={classes.paper}>

                {/* HEADER */}
                <Box display="flex" className={classes.background_image_header}>
                    <Box display="flex" flexGrow={1} className={classes.image_content_container}>
                        <Box display="flex" flexDirection="column" justifyContent="flex-end" className={classes.text_box}>
                            <Typography variant="h2" className={classes.headline}>
                                {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('caption_1')][language]}
                            </Typography>
                            <Typography variant="h2" className={classes.headline}>
                                {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('caption_2')][language]}
                            </Typography>
                            <Box mt={4} display="flex">
                                <Button
                                    sx={{
                                        height: '48px',
                                        padding: '12px 24px !important',
                                    }}
                                    component={Link}
                                    to={`/workspaces`}
                                    variant="contained"
                                    color="primary">
                                    {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('header_button')][language]}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* SOCIALSPACE - CLIENT*/}
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Box display="flex" alignItems="center" justifyContent="center" style={{ margin: '80px 20vw 24px 20vw' }}>
                        <Typography variant='h3' style={{ textAlign: 'center', }}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('caption_section1')][language]}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" style={{ marginBottom: '64px', marginLeft: '20vw', marginRight: '20vw', textAlign: 'center', }}>
                        <Typography variant='body1' style={{ color: grey800 }}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('text_section1')][language]}
                        </Typography>
                    </Box>
                </Box>

                {/* WORKSPACES */}
                <Box display="flex" justifyContent="center" flexWrap="wrap">
                    {HOMEPAGE_WORKSPACES
                    .filter(option => ['1', '4', '5'].includes(option.socialspaceId))                    
                    .sort((a, b) => ['4', '1', '5'].indexOf(a.socialspaceId) - ['4', '1', '5'].indexOf(b.socialspaceId))
                    .map(option => (
                        <Link to={`/workspace/${option.socialspaceId}`} key={option.socialspaceId} className={classes.socialspace_box}>
                            <WorkspaceBox key={option.socialspaceId} socialspace={option} />
                        </Link>
                    ))
                    }
                    <Box className={classes.partner_box}>
                        <EmailRegistration entry={'desktop-homepage'} />
                    </Box>
                </Box>

                {/* HOW IT WORKS */}
                <Box mt={'144px'} display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ background: green50 }}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={classes.highlight_box}>
                        <Typography pt={'80px'} px={3} variant='h2' style={{ textAlign: 'center' }}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('caption_section2')][language]}
                        </Typography>
                        <Typography mt={'8px'} px={3} variant='body1' style={{ color: grey800, textAlign: 'center' }}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('text_section2')][language]}
                        </Typography>
                        <Box pt={8} pb={10} display="flex" justifyContent="space-between">
                            <Box display="flex" flexDirection="column" alignItems="center" style={{ width: 'calc((80vw)/3)' }}>
                                <div className={classes.step1_illustration} title="Step1Illustration"></div>
                                <Box mt={'40px'} display="flex">
                                    <Typography variant='h4' style={{ color: green600 }}>
                                        01
                                    </Typography>
                                    <Typography pl={1.5} variant='h4' style={{ color: green1000 }}>
                                        {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('title_step1_section2')][language]}
                                    </Typography>
                                </Box>
                                <Typography mt={'8px'} px={'4vw'} variant='body1' style={{ color: grey800, textAlign: 'center' }}>
                                    {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('text_step1_section2')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" alignItems="center" style={{ width: 'calc((80vw)/3)' }}>
                                <div className={classes.step2_illustration} title="Step2Illustration"></div>
                                <Box mt={'40px'} display="flex">
                                    <Typography variant='h4' style={{ color: green600 }}>
                                        02
                                    </Typography>
                                    <Typography pl={1.5} variant='h4' style={{ color: green1000 }}>
                                        {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('title_step2_section2')][language]}
                                    </Typography>
                                </Box>
                                <Typography mt={'8px'} px={'4vw'} variant='body1' style={{ color: grey800, textAlign: 'center' }}>
                                    {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('text_step2_section2')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" alignItems="center" style={{ width: 'calc((80vw)/3)' }}>
                                <div className={classes.step3_illustration} title="Step3Illustration"></div>
                                <Box mt={'40px'} display="flex">
                                    <Typography variant='h4' style={{ color: green600 }}>
                                        03
                                    </Typography>
                                    <Typography pl={1.5} variant='h4' style={{ color: green1000 }}>
                                        {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('title_step3_section2')][language]}
                                    </Typography>
                                </Box>
                                <Typography mt={'8px'} px={'4vw'} variant='body1' style={{ color: grey800, textAlign: 'center' }}>
                                    {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('text_step3_section2')][language]}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    {/* WHY SOCIALSPACE */}
                    <Box pb={10} mx={'10vw'} display="flex" flexDirection="column" style={{ backgroundColor: green50 }}>
                        <Typography variant='h2'>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('caption_section3')][language]}
                        </Typography>
                        <Typography mt={'24px'} pr={'15vw'} variant='body1' style={{ color: grey800 }}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('text_section3')][language]}</Typography>
                        <Box mt={'48px'} display="flex" justifyContent="space-between">
                            <Box display="flex" flexDirection="column" style={{ width: 'calc(75vw/3)' }}>
                                <Icon width={56} height={56} iconName="Award" fill={green1000} />
                                <Typography mt={2} pr={6} variant='h5' style={{ color: green1000 }}>
                                    {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('title_benefit1_section3')][language]}
                                </Typography>
                                <Typography mt={2} pr={6} variant='body1' style={{ color: grey800 }}>
                                    {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('text_benefit1_section3')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" style={{ width: 'calc(75vw/3)' }}>
                                <Icon width={56} height={56} iconName="Lovely" fill={green1000} />
                                <Typography mt={2} pr={6} variant='h5' style={{ color: green1000 }}>
                                    {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('title_benefit2_section3')][language]}
                                </Typography>
                                <Typography mt={2} pr={6} variant='body1' style={{ color: grey800 }}>
                                    {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('text_benefit2_section3')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" style={{ width: 'calc(75vw/3)' }}>
                                <Icon width={56} height={56} iconName="UserOctagon" fill={green1000} />
                                <Typography mt={2} pr={6} variant='h5' style={{ color: green1000 }}>
                                    {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('title_benefit3_section3')][language]}
                                </Typography>
                                <Typography mt={2} pr={6} variant='body1' style={{ color: grey800 }}>
                                    {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('text_benefit3_section3')][language]}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box py={8} px={'10vw'} display="flex" justifyContent="space-between" style={{ backgroundColor: green1000 }}>
                    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: 'calc(75vw/2)' }}>
                        <Typography className={classes.highlight_number}>
                            2
                        </Typography>
                        <Typography variant="body1" style={{ color: green100, textAlign: 'center' }}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('stats2')][language]}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: 'calc(75vw/2)' }}>
                        <Typography className={classes.highlight_number}>
                            3
                        </Typography>
                        <Typography variant="body1" style={{ color: green100, textAlign: 'center' }}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('stats3')][language]}
                        </Typography>
                    </Box>
                </Box>

                {/* SOCIALSPACE - NGO*/}
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Box display="flex" alignItems="center" justifyContent="center" style={{ marginTop: '80px', marginBottom: '24px' }}>
                        <p className={classes.headline_caption}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('caption1_section4')][language]}
                        </p>
                    </Box>
                    <Box display="flex" flexDirection="column" style={{ marginBottom: '64px', marginLeft: '18vw', marginRight: '18vw', textAlign: 'center', }}>
                        <Typography variant='body1' style={{ color: grey800 }}>
                            {HOMEPAGE_DESKTOP[HOMEPAGE_DESKTOP.map(object => object.section).indexOf('text_section4')][language]}
                        </Typography>
                    </Box>
                </Box>

                {/* HOSTS */}
                <Box mb={4} display="flex" justifyContent="center" flexWrap="wrap">
                    {HOSTS?.map((option) => {
                        return (
                            <Link to={`/host/${option.hostId}`} key={option.hostId} className={classes.partner_box}>
                                <HostBox host={option} />
                            </Link>
                        )
                    })}
                    <Link to={`/aboutus`} className={classes.partner_box}>
                        <JoinBox />
                    </Link>
                </Box>

                {/* FOOTER */}
                <FooterDesktop />
            </Paper >
        </Grid >
    )
}
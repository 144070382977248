import makeStyles from "@mui/styles/makeStyles";
import { green100, grey1000 } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    background_image_header: {
        height: 'calc(100vh - 80px)',
        backgroundImage: props => `url(${props.backgroundImgHeader})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
    footer: {
        color: green100,
    },  
    headline_caption: {
        fontFamily: 'Poppins600',
        color: grey1000,
        fontSize: '22px',
        lineHeight: '34px',
        letterSpacing: '0.005em',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    host_box: {
        width: '70vw',
        textDecoration: 'none',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        '&:hover': {
            boxShadow: '0px 5px 25px rgba(54, 79, 81, 0.3)',
        },
        margin: '24px 24px 24px 0',
        height: props => props.heightHostBox,
    },
    hosts_container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginBottom: '40px',
    },
    icon_button_tertiary: {
        padding: '0 8px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0)',
        },
    },
    image_list: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    link: {
        textDecoration: 'none',
        paddingBottom: '4px',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },    
    logo: {
        fontFamily: 'Poppins600',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.03em',
        color: '#FFF',
    },  
    partner_box: {
        margin: '0 24px 48px 24px',
        textDecoration: 'none',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        '&:hover': {
            boxShadow: '0px 5px 25px rgba(54, 79, 81, 0.3)',
        },
    },
}));
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './HostProfile.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper';
import { grey800 } from '../../other/helper/MuiStyles';

export default function HostProfile({ host }) {

    const styleProps = {
        partnerImg: IMAGE_MAP.PARTNERS[host.name]
    }

    const classes = useStyles(styleProps);

    return (
        <Box display="flex" flexDirection="column" className={classes.host_box}>
            {/* LOGO */}
            <Box p={3}>
                <div className={classes.partner_logo} title="PartnerLogo"></div>
            </Box>

            {/* ADDRESS */}
            <Box px={4} pb={5} pt={1}>
                <Typography variant='h5'>
                    {host.name}
                </Typography>
                <Typography mt={3} variant="body2" style={{ fontFamily: 'Poppins600' }}>
                    Headquarter
                </Typography>
                <Typography mt={0.5} variant="body1" style={{ color: grey800 }}>
                {host.headquarter}
                </Typography>
                <Typography mt={3} variant="body2" style={{ fontFamily: 'Poppins600' }}>
                    Founded
                </Typography>
                <Typography mt={0.5} variant="body1" style={{ color: grey800 }}>
                {host.founded}
                </Typography>
                <Typography mt={3} variant="body2" style={{ fontFamily: 'Poppins600' }}>
                    Sector
                </Typography>
                {host.sector.map((sector, index) => (
                    <Box key={index} mt={0.5} display="flex">
                        <Typography variant="body1" className={classes.bullet_point}>
                            •
                        </Typography>
                        <Typography variant="body1" style={{ color: grey800 }}>
                            {sector}
                        </Typography>
                    </Box>
                ))
                }
                <Typography mt={3} variant="body2" style={{ fontFamily: 'Poppins600' }}>
                    Region
                </Typography>
                {host.region.map((region, index) => (
                    <Box key={index} mt={0.5} display="flex">
                        <Typography variant="body1" className={classes.bullet_point}>
                            •
                        </Typography>
                        <Typography variant="body1" style={{ color: grey800 }}>
                            {region}
                        </Typography>
                    </Box>
                ))
                }
                <Typography mt={3} variant="body2" style={{ fontFamily: 'Poppins600' }}>
                    Website
                </Typography>
                <Typography mt={0.5} variant="body1">
                    <a href={host.website} target="_blank" rel="noopener noreferrer" className={classes.link_extern}>{host.website}</a>
                </Typography>
            </Box>

        </Box>
    )
}
import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useStyles } from './BecomeHostPageMobile.styles';
import { AppContext } from '../../../components/other/contexts/AppContext';
import { green100, green1000, green200, green50, green600, grey800 } from '../../../components/other/helper/MuiStyles';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import { HOMEPAGE_MOBILE } from '../../../data/translation/TranslationHomepageMobile';
import { Link } from 'react-router-dom';
import { BECOME_HOST } from '../../../data/translation/TranslationBecomeHost';
import { HashLink } from 'react-router-hash-link';
import Icon from '../../../assets/icons/Icon';
import EmailRegistration from '../../../components/other/dialogs/EmailRegistration/EmailRegistration';

export default function BecomeHostPageMobile() {

    const { language } = useContext(AppContext);

    const [showStep1Section2, setShowStep1Section2] = useState(false);
    const [showStep2Section2, setShowStep2Section2] = useState(false);
    const [showStep3Section2, setShowStep3Section2] = useState(false);
    const [showStep4Section2, setShowStep4Section2] = useState(false);
    const [showStep1Section4, setShowStep1Section4] = useState(false);
    const [showStep2Section4, setShowStep2Section4] = useState(false);
    const [showStep3Section4, setShowStep3Section4] = useState(false);
    const [showStep4Section4, setShowStep4Section4] = useState(false);


    const styleProps = {
        backgroundImgBenefitsIllustration: IMAGE_MAP.ILLUSTRATIONS['BenefitsIllustration'],
        backgroundImgProcessIllustration: IMAGE_MAP.ILLUSTRATIONS['ProcessIllustration'],
        backgroundImgCriteriaIllustration: IMAGE_MAP.ILLUSTRATIONS['CriteriaIllustration'],
        backgroundImgSupportIllustration: IMAGE_MAP.ILLUSTRATIONS['SupportIllustration'],
    };

    const classes = useStyles(styleProps);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            {/* WELCOME */}
            <Box mx={3} py={8} display="flex" flexDirection="column" justifyContent="center">
                <Typography variant='h1Mobile'>
                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('header')][language]}
                </Typography>
                <Typography mt={5} variant='body1' style={{ color: grey800 }}>
                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('header_text')][language]}
                </Typography>             
                <Box mt={4} display="flex">
                                <EmailRegistration entry={'mobile'} />
                            </Box>
                <HashLink smooth to='/becomehost/#benefits' style={{ textDecoration: 'none' }}>
                    <Box mt={7} display="flex" alignItems="flex-start">
                        <Typography variant='h6Mobile' style={{ minWidth: '30px', color: green600 }}>
                            01
                        </Typography>
                        <Typography mr={1} pl={1} variant='h6Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('title_section1')][language]}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '24px', minHeight: '24px' }}>
                            <Icon width={20} height={20} iconName="ArrowRight" fill={green1000} />
                        </Box>
                    </Box>
                </HashLink>
                <HashLink smooth to='/becomehost/#process' style={{ textDecoration: 'none' }}>
                    <Box mt={2} display="flex" alignItems="flex-start">
                        <Typography variant='h6Mobile' style={{ minWidth: '30px', color: green600 }}>
                            02
                        </Typography>
                        <Typography mr={1} pl={1} variant='h6Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('title_section2')][language]}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '24px', minHeight: '24px' }}>
                            <Icon width={20} height={20} iconName="ArrowRight" fill={green1000} />
                        </Box>
                    </Box>
                </HashLink>
                <HashLink smooth to='/becomehost/#criteria' style={{ textDecoration: 'none' }}>
                    <Box mt={2} display="flex" alignItems="flex-start">
                        <Typography variant='h6Mobile' style={{ minWidth: '30px', color: green600 }}>
                            03
                        </Typography>
                        <Typography mr={1} pl={1} variant='h6Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('title_section3')][language]}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '24px', minHeight: '24px' }}>
                            <Icon width={20} height={20} iconName="ArrowRight" fill={green1000} />
                        </Box>
                    </Box>
                </HashLink>
                <HashLink smooth to='/becomehost/#support' style={{ textDecoration: 'none' }}>
                    <Box mt={2} display="flex" alignItems="flex-start">
                        <Typography variant='h6Mobile' style={{ minWidth: '30px', color: green600 }}>
                            04
                        </Typography>
                        <Typography mr={1} pl={1} variant='h6Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('title_section4')][language]}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '24px', minHeight: '24px' }}>
                            <Icon width={20} height={20} iconName="ArrowRight" fill={green1000} />
                        </Box>
                    </Box>
                </HashLink>   
            </Box>

            {/* 01 */}
            <Box id="benefits" px={3} py={8} display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: green50 }}>
                <Typography mb={5} variant='h2Mobile'>
                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('title_section1')][language]}
                </Typography>
                <div className={classes.benefits_illustration} title="BenefitsIllustration"></div>
                <Typography mt={5} variant='body1' style={{ color: grey800 }}>
                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('text_section1')][language]}
                </Typography>
                <HashLink smooth to='/becomehost/#benefits_hosting' style={{ textDecoration: 'none' }}>
                    <Box mt={7} display="flex" alignItems="flex-start">
                        <Typography variant='h6Mobile' style={{ minWidth: '30px', color: green600 }}>
                            01
                        </Typography>
                        <Typography mr={1} pl={1} variant='h6Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle1_section1')][language]}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '24px', minHeight: '24px' }}>
                            <Icon width={20} height={20} iconName="ArrowRight" fill={green1000} />
                        </Box>
                    </Box>
                </HashLink>
                <HashLink smooth to='/becomehost/#benefits_guests' style={{ textDecoration: 'none' }}>
                    <Box mt={2} display="flex" alignItems="flex-start">
                        <Typography variant='h6Mobile' style={{ minWidth: '30px', color: green600 }}>
                            02
                        </Typography>
                        <Typography mr={1} pl={1} variant='h6Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle2_section1')][language]}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '24px', minHeight: '24px' }}>
                            <Icon width={20} height={20} iconName="ArrowRight" fill={green1000} />
                        </Box>
                    </Box>
                </HashLink>
            </Box>

            {/* 01 / 01 */}
            <Box id="benefits_hosting" display="flex" style={{ backgroundColor: green50 }}>
                <Box py={8} mx={3} display="flex" flexDirection="column" style={{ borderTop: `2px solid ${green200}` }}>
                    <Box display="flex" alignItems="flex-start">
                        <Typography variant='h3Mobile' style={{ minWidth: '38px', color: green600 }}>
                            01
                        </Typography>
                        <Typography variant='h3Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle1_section1')][language]}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box mt={8} display="flex" flexDirection="column" alignItems="center">
                            <Icon width={64} height={64} iconName="DollarCircle" fill={green1000} />
                            <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                                {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('benefit1_1_section1')][language]}
                            </Typography>
                        </Box>
                        <Box mt={6} display="flex" flexDirection="column" alignItems="center">
                            <Icon width={64} height={64} iconName="StatusUp" fill={green1000} />
                            <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                                {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('benefit1_2_section1')][language]}
                            </Typography>
                        </Box>
                        <Box mt={6} display="flex" flexDirection="column" alignItems="center">
                            <Icon width={64} height={64} iconName="ElementPlus" fill={green1000} />
                            <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                                {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('benefit1_3_section1')][language]}
                            </Typography>
                        </Box>
                        <Box mt={6} display="flex" flexDirection="column" alignItems="center">
                            <Icon width={64} height={64} iconName="Gift" fill={green1000} />
                            <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                                {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('benefit1_4_section1')][language]}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box >

            {/* 01 / 02 */}
            <Box id="benefits_guests" display="flex" style={{ backgroundColor: green50 }}>
                <Box py={8} mx={3} display="flex" flexDirection="column" style={{ borderTop: `2px solid ${green200}` }}>
                    <Box display="flex" alignItems="flex-start">
                        <Typography variant='h3Mobile' style={{ minWidth: '38px', color: green600 }}>
                            02
                        </Typography>
                        <Typography variant='h3Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle2_section1')][language]}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box mt={8} display="flex" flexDirection="column" alignItems="center">
                            <Icon width={64} height={64} iconName="GlobalSearch" fill={green1000} />
                            <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                                {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('benefit2_1_section1')][language]}
                            </Typography>
                        </Box>
                        <Box mt={6} display="flex" flexDirection="column" alignItems="center">
                            <Icon width={64} height={64} iconName="Share" fill={green1000} />
                            <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                                {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('benefit2_2_section1')][language]}
                            </Typography>
                        </Box>
                        <Box mt={6} display="flex" flexDirection="column" alignItems="center">
                            <Icon width={64} height={64} iconName="ConvertShape" fill={green1000} />
                            <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                                {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('benefit2_3_section1')][language]}
                            </Typography>
                        </Box>
                        <Box mt={6} display="flex" flexDirection="column" alignItems="center">
                            <Icon width={64} height={64} iconName="ShieldTick" fill={green1000} />
                            <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                                {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('benefit2_4_section1')][language]}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box >


            {/* 02 */}
            <Box id="process" px={3} py={8} display="flex" flexDirection="column" justifyContent="center">
                <Typography mb={5} variant='h2Mobile'>
                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('title_section2')][language]}
                </Typography>
                <div className={classes.process_illustration} title="ProcessIllustration"></div>
                <Typography mt={5} variant='body1' style={{ color: grey800 }}>
                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('text_section2')][language]}
                </Typography>
                <Box mt={7} display="flex" flexDirection="column" style={{ borderTop: `1px solid ${green100}` }}>
                    <Box py={3} display="flex" flexDirection="column" style={{ borderBottom: `1px solid ${green100}` }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => { setShowStep1Section2(!showStep1Section2) }}>
                            <Box display="flex">
                                <Typography variant='h5Mobile' style={{ width: '24px', color: green600 }}>
                                    01
                                </Typography>
                                <Typography pl={1.5} mr={2} variant='h5Mobile' style={{ color: green1000 }}>
                                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle1_section2')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" style={{ transform: showStep1Section2 ? '' : 'rotate(-180deg)', transition: '.6s ease' }}>
                                <Icon width={24} height={24} iconName="ArrowUp" fill={green1000} />
                            </Box>
                        </Box>
                        {showStep1Section2 &&
                            <>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    Provide personal and organisational information. Tell us a bit about yourself and your organisation. We're keen to learn more about your mission, goals, and the kind of workspace you offer.
                                </Typography>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    Share information about the workspace. Tell us a bit about yourself and your organisation. We're keen to learn more about your mission, goals, and the kind of workspace you offer.
                                </Typography>
                            </>
                        }
                    </Box>
                    <Box py={3} display="flex" flexDirection="column" style={{ borderBottom: `1px solid ${green100}` }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => { setShowStep2Section2(!showStep2Section2) }}>
                            <Box display="flex">
                                <Typography variant='h5Mobile' style={{ width: '24px', color: green600 }}>
                                    02
                                </Typography>
                                <Typography pl={1.5} mr={2} variant='h5Mobile' style={{ color: green1000 }}>
                                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle2_section2')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" style={{ transform: showStep2Section2 ? '' : 'rotate(-180deg)', transition: '.6s ease' }}>
                                <Icon width={24} height={24} iconName="ArrowUp" fill={green1000} />
                            </Box>
                        </Box>
                        {showStep2Section2 &&
                            <>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    Upload high-quality images showcasing the workspace. A picture is worth a thousand words! Help potential guests visualize your workspace by providing clear, well-lit photos that highlight its best features and give a sense of the overall atmosphere.
                                </Typography>
                            </>
                        }
                    </Box>
                    <Box py={3} display="flex" flexDirection="column" style={{ borderBottom: `1px solid ${green100}` }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => { setShowStep3Section2(!showStep3Section2) }}>
                            <Box display="flex">
                                <Typography variant='h5Mobile' style={{ width: '24px', color: green600 }}>
                                    03
                                </Typography>
                                <Typography pl={1.5} mr={2} variant='h5Mobile' style={{ color: green1000 }}>
                                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle3_section2')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" style={{ transform: showStep3Section2 ? '' : 'rotate(-180deg)', transition: '.6s ease' }}>
                                <Icon width={24} height={24} iconName="ArrowUp" fill={green1000} />
                            </Box>
                        </Box>
                        {showStep3Section2 &&
                            <>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    socialspace to review and approve the listing request based on the qualification criteria. Our team will carefully review your submission, ensuring that it meets our guidelines for not-for-profit organisations, non-profit associations, and overall suitability for our community.
                                </Typography>
                            </>
                        }
                    </Box>
                    <Box py={3} display="flex" flexDirection="column" style={{ borderBottom: `1px solid ${green100}` }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => { setShowStep4Section2(!showStep4Section2) }}>
                            <Box display="flex">
                                <Typography variant='h5Mobile' style={{ width: '24px', color: green600 }}>
                                    04
                                </Typography>
                                <Typography pl={1.5} mr={2} variant='h5Mobile' style={{ color: green1000 }}>
                                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle4_section2')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" style={{ transform: showStep4Section2 ? '' : 'rotate(-180deg)', transition: '.6s ease' }}>
                                <Icon width={24} height={24} iconName="ArrowUp" fill={green1000} />
                            </Box>
                        </Box>
                        {showStep4Section2 &&
                            <>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    As socialspace is currently in its prototype test phase, we kindly ask for your understanding if we cannot list your workspace immediately. We're working diligently to ensure a seamless experience for both hosts and guests as we grow our platform.
                                </Typography>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    We apologize for any inconvenience during the onboarding process and appreciate your patience. Rest assured, we are committed to improving and automating our onboarding process in the future. Your feedback is invaluable in helping us achieve this goal. Thank you for joining us on this exciting journey and for contributing to the success of the socialspace community.
                                </Typography>
                            </>
                        }
                    </Box>
                </Box>
            </Box>

            {/* 03 */}
            <Box id="criteria" px={3} py={8} display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: green50 }}>
                <Typography mb={5} variant='h2Mobile'>
                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('title_section3')][language]}
                </Typography>
                <div className={classes.criteria_illustration} title="CriteriaIllustration"></div>
                <Typography mt={5} variant='body1' style={{ color: grey800 }}>
                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('text_section3')][language]}
                </Typography>
                <HashLink smooth to='/becomehost/#criteria_npo' style={{ textDecoration: 'none' }}>
                    <Box mt={7} display="flex" alignItems="flex-start">
                        <Typography variant='h6Mobile' style={{ minWidth: '30px', color: green600 }}>
                            01
                        </Typography>
                        <Typography mr={1} pl={1} variant='h6Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle1_section3')][language]}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '24px', minHeight: '24px' }}>
                            <Icon width={20} height={20} iconName="ArrowRight" fill={green1000} />
                        </Box>
                    </Box>
                </HashLink>
                <HashLink smooth to='/becomehost/#criteria_disclaimer' style={{ textDecoration: 'none' }}>
                    <Box mt={2} display="flex" alignItems="flex-start">
                        <Typography variant='h6Mobile' style={{ minWidth: '30px', color: green600 }}>
                            02
                        </Typography>
                        <Typography mr={1} pl={1} variant='h6Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle2_section3')][language]}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '24px', minHeight: '24px' }}>
                            <Icon width={20} height={20} iconName="ArrowRight" fill={green1000} />
                        </Box>
                    </Box>
                </HashLink>
            </Box>

            {/* 03 / 01 */}
            <Box id="criteria_npo" display="flex" style={{ backgroundColor: green50 }}>
                <Box py={8} mx={3} display="flex" flexDirection="column" style={{ borderTop: `2px solid ${green200}` }}>
                    <Box display="flex" alignItems="flex-start">
                        <Typography variant='h3Mobile' style={{ minWidth: '38px', color: green600 }}>
                            01
                        </Typography>
                        <Typography variant='h3Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle1_section3')][language]}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box mt={8} display="flex" flexDirection="column" alignItems="center">
                            <Icon width={64} height={64} iconName="MedalStar" fill={green1000} />
                            <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                                {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('criteria1_section3')][language]}
                            </Typography>
                        </Box>
                        <Box mt={6} display="flex" flexDirection="column" alignItems="center">
                            <Icon width={64} height={64} iconName="HeartTick" fill={green1000} />
                            <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                                {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('criteria2_section3')][language]}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box >


            {/* 03 / 02 */}
            <Box id="criteria_disclaimer" display="flex" style={{ backgroundColor: green50 }}>
                <Box py={8} mx={3} display="flex" flexDirection="column" style={{ borderTop: `2px solid ${green200}` }}>
                    <Box display="flex" alignItems="flex-start">
                        <Typography variant='h3Mobile' style={{ minWidth: '38px', color: green600 }}>
                            02
                        </Typography>
                        <Typography variant='h3Mobile' style={{ color: green1000 }}>
                            {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle2_section3')][language]}
                        </Typography>
                    </Box>
                    <Typography mt={5} variant='body1' style={{ color: grey800 }}>
                        {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('text_subtitle2_section3')][language]}
                    </Typography>
                </Box>
            </Box >

            {/* 04 */}
            <Box id="support" px={3} py={8} display="flex" flexDirection="column" justifyContent="center">
                <Typography mb={5} variant='h2Mobile'>
                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('title_section4')][language]}
                </Typography>
                <div className={classes.support_illustration} title="SupportIllustration"></div>
                <Typography mt={5} variant='body1' style={{ color: grey800 }}>
                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('text_section4')][language]}
                </Typography>
                <Box mt={7} display="flex" flexDirection="column" style={{ borderTop: `1px solid ${green100}` }}>
                    <Box py={3} display="flex" flexDirection="column" style={{ borderBottom: `1px solid ${green100}` }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => { setShowStep1Section4(!showStep1Section4) }}>
                            <Box display="flex">
                                <Typography variant='h5Mobile' style={{ width: '24px', color: green600 }}>
                                    01
                                </Typography>
                                <Typography pl={1.5} mr={2} variant='h5Mobile' style={{ color: green1000 }}>
                                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle1_section4')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" style={{ transform: showStep1Section4 ? '' : 'rotate(-180deg)', transition: '.6s ease' }}>
                                <Icon width={24} height={24} iconName="ArrowUp" fill={green1000} />
                            </Box>
                        </Box>
                        {showStep1Section4 &&
                            <>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    We understand the importance of presenting your workspace in the best possible light. Our team is available to help you craft a compelling profile that highlights your organisation's unique features and appeals to potential guests.
                                </Typography>
                            </>
                        }
                    </Box>
                    <Box py={3} display="flex" flexDirection="column" style={{ borderBottom: `1px solid ${green100}` }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => { setShowStep2Section4(!showStep2Section4) }}>
                            <Box display="flex">
                                <Typography variant='h5Mobile' style={{ width: '24px', color: green600 }}>
                                    02
                                </Typography>
                                <Typography pl={1.5} mr={2} variant='h5Mobile' style={{ color: green1000 }}>
                                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle2_section4')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" style={{ transform: showStep2Section4 ? '' : 'rotate(-180deg)', transition: '.6s ease' }}>
                                <Icon width={24} height={24} iconName="ArrowUp" fill={green1000} />
                            </Box>
                        </Box>
                        {showStep2Section4 &&
                            <>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    To make the hosting experience as smooth as possible, we provide guidance on key aspects of managing your workspace, including:
                                </Typography>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    <b>Booking:</b> Tips on managing reservation requests and scheduling, and best practices for communication with guests.
                                </Typography>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    <b>Hosting process:</b> Advice on creating a welcoming environment for guests, ensuring their comfort, and addressing any concerns that may arise.
                                </Typography>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    <b>Payment:</b> Assistance with setting up and managing payment processes, including invoicing and receipts.
                                </Typography>
                            </>
                        }
                    </Box>
                    <Box py={3} display="flex" flexDirection="column" style={{ borderBottom: `1px solid ${green100}` }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => { setShowStep3Section4(!showStep3Section4) }}>
                            <Box display="flex">
                                <Typography variant='h5Mobile' style={{ width: '24px', color: green600 }}>
                                    03
                                </Typography>
                                <Typography pl={1.5} mr={2} variant='h5Mobile' style={{ color: green1000 }}>
                                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle3_section4')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" style={{ transform: showStep3Section4 ? '' : 'rotate(-180deg)', transition: '.6s ease' }}>
                                <Icon width={24} height={24} iconName="ArrowUp" fill={green1000} />
                            </Box>
                        </Box>
                        {showStep3Section4 &&
                            <>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    To further support your hosting journey, we offer access to a range of templates and tools designed to streamline the management of your workspace and enhance the guest experience.
                                </Typography>
                            </>
                        }
                    </Box>
                    <Box py={3} display="flex" flexDirection="column" style={{ borderBottom: `1px solid ${green100}` }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => { setShowStep4Section4(!showStep4Section4) }}>
                            <Box display="flex">
                                <Typography variant='h5Mobile' style={{ width: '24px', color: green600 }}>
                                    04
                                </Typography>
                                <Typography pl={1.5} mr={2} variant='h5Mobile' style={{ color: green1000 }}>
                                    {BECOME_HOST[BECOME_HOST.map(object => object.section).indexOf('subtitle4_section4')][language]}
                                </Typography>
                            </Box>
                            <Box display="flex" style={{ transform: showStep4Section4 ? '' : 'rotate(-180deg)', transition: '.6s ease' }}>
                                <Icon width={24} height={24} iconName="ArrowUp" fill={green1000} />
                            </Box>
                        </Box>
                        {showStep4Section4 &&
                            <>
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    Please be aware that host organisations are responsible for managing their booking and payment processes independently. While socialspace provides guidance and support in these areas, the actual responsibility for hosting lies with the host organisation. For more information, please refer to our usage terms, which outline our exclusion of liability regarding the hosting process.
                                </Typography>
                            </>
                        }
                    </Box>
                </Box>
            </Box>

            {/* FOOTER */}
            <Box px={3} pt={4} display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: green1000 }}>
                <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography className={classes.logo}>socialspace</Typography>
                    <Box display="flex" alignItems="center" height='20px'>
                        <IconButton
                            component="a"
                            href="https://instagram.com/socialspacework?igshid=YmMyMTA2M2Y="
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="Instagram" />
                        </IconButton>
                        <IconButton
                            component="a"
                            href="https://twitter.com/socialspacework?s=11&t=U-yVjKdFPsf1bZ9fImbBwA"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="Twitter" />
                        </IconButton>
                        <IconButton
                            component="a"
                            href="https://www.linkedin.com/company/socialspacework/"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="LinkedIn" />
                        </IconButton>
                    </Box>
                </Box>
                <Box pb={3.5} display="flex" flexDirection="column">
                    <Link to={`/termsofuse`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('terms')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/imprint`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('imprint')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/privacypolicy`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('privacy')][language]}
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </>
    )
}
import React, { useState } from 'react'

const FilterContext = React.createContext(null);

const FilterProvider = ({ children }) => {
    const [location, setLocation] = useState(['Berlin']);
    const [workspaceType, setWorkspaceType] = useState(['Private Desk']);

    return (
        <FilterContext.Provider value={{
            location, setLocation,
            workspaceType, setWorkspaceType,
        }}
        >
            {children}
        </FilterContext.Provider>
    )
};

export {FilterContext, FilterProvider}
export const BECOME_HOST = [
    {
        section: 'header',
        eng: 'Welcome to socialspace!',
        ger: 'Willkommen bei socialspace!',
    },
    {
        section: 'header_text',
        eng: "At socialspace, our goal is to create a vibrant community of hosts and guests who are committed to fostering collaboration, innovation, and social impact. Thank you for considering joining us as a host. We're excited to have you on board and look forward to supporting you on this journey.",
        ger: 'Unser Ziel bei socialspace ist es, eine lebendige Gemeinschaft von Gastgebern und Gästen zu schaffen, die sich für die Förderung von Zusammenarbeit, Innovation und sozialer Einflussnahme einsetzen. Vielen Dank, dass Sie in Erwägung ziehen, sich uns als Gastgeber anzuschließen. Wir sind begeistert, Sie an Bord zu haben und freuen uns darauf, Sie auf dieser Reise zu unterstützen.',
    },
    {
        section: 'title_section1',
        eng: "Benefits of joining socialspace",
        ger: 'Vorteile einer Partnerschaft mit socialspace',
    },
    {
        section: 'text_section1',
        eng: "By joining us as a host, you're opening your doors to a world of collaboration, sustainable impact, and shared growth. Let's take a moment to explore the benefits of hosting with socialspace and how your organisation can thrive by connecting with our community of coworkers, SMMEs, and NGOs.",
        ger: 'Wenn Sie sich uns als Gastgeber anschließen, öffnen Sie Ihre Tür für Zusammenarbeit, nachhaltige Wirkung und gemeinsames Wachstum. Erfahren Sie mehr über die Vorteile des Hostings bei socialspace und darüber, wie Ihr Unternehmen durch die Verbindung mit unserer Gemeinschaft von Mitarbeitern, KMUs und NGOs erfolgreich sein kann.',
    },
    {
        section: 'subtitle1_section1',
        eng: "Benefits of hosting with socialspace",
        ger: 'Vorteile des Gastgebens mit socialspace',
    },
    {
        section: 'subtitle2_section1',
        eng: "Benefits of connecting with guests",
        ger: 'Vorteile der Vernetzung mit Gästen',
    },
    {
        section: 'benefit1_1_section1',
        eng: "Generate revenue",
        ger: 'Einnahmen generieren',
    },
    {
        section: 'benefit1_2_section1',
        eng: "Enhance your reputation",
        ger: 'Steigern Sie Ihr Ansehen',
    },
    {
        section: 'benefit1_3_section1',
        eng: "Expand your network",
        ger: 'Erweitern Sie Ihr Netzwerk',
    },
    {
        section: 'benefit1_4_section1',
        eng: "Access valuable resources",
        ger: 'Zugang zu wertvollen Ressourcen',
    },
    {
        section: 'benefit2_1_section1',
        eng: "Diverse perspectives",
        ger: 'Unterschiedliche Perspektiven',
    },
    {
        section: 'benefit2_2_section1',
        eng: "Cross-sector collaboration",
        ger: 'Sektorenübergreifende Zusammenarbeit',
    },
    {
        section: 'benefit2_3_section1',
        eng: "Skill-sharing and learning",
        ger: 'Austausch von Fachwissen und Lernen',
    },
    {
        section: 'benefit2_4_section1',
        eng: "Strengthen the social impact ecosystem",
        ger: 'Stärkung des sozialen Ökosystems',
    },
    {
        section: 'title_section2',
        eng: "Process of listing workspaces on socialspace",
        ger: 'Vorgehensweise beim Inserieren von Arbeitsplätzen auf socialspace',
    },
    {
        section: 'text_section2',
        eng: "We understand that you're excited to share your workspace with our community, and we're here to guide you through the process step by step. By following these simple steps, you'll be on your way to becoming a successful socialspace host.",
        ger: 'Wir freuen uns, dass Sie Ihren Arbeitsbereich mit unserer Community teilen möchten, und wir werden Sie Schritt für Schritt durch den Prozess führen. Wenn Sie diese einfachen Schritte befolgen, sind Sie auf dem besten Weg, ein erfolgreicher Gastgeber auf socialspace zu werden.',
    },
    {
        section: 'subtitle1_section2',
        eng: "Complete a contact form",
        ger: 'Kontaktformular ausfüllen',
    },   
    {
        section: 'text1_subtitle1_section2',
        eng: "We understand that you're excited to share your workspace with our community, and we're here to guide you through the process step by step. By following these simple steps, you'll be on your way to becoming a successful socialspace host.",
        ger: 'Wir freuen uns, dass Sie Ihren Arbeitsbereich mit unserer Community teilen möchten, und wir werden Sie Schritt für Schritt durch den Prozess führen. Wenn Sie diese einfachen Schritte befolgen, sind Sie auf dem besten Weg, ein erfolgreicher Gastgeber auf socialspace zu werden.',
    }, 
    {
        section: 'text1_subtitle1_section2',
        eng: "Complete a contact form",
        ger: 'Kontaktformular ausfüllen',
    },
    {
        section: 'subtitle2_section2',
        eng: "Submit workspace photos",
        ger: 'Fotos vom Arbeitsplatz einsenden',
    },
    {
        section: 'subtitle3_section2',
        eng: "Review and approval",
        ger: 'Überprüfung und Genehmigung',
    },
    {
        section: 'subtitle4_section2',
        eng: "Disclaimer",
        ger: 'Disclaimer',
    },
    {
        section: 'title_section3',
        eng: "Host qualification criteria",
        ger: 'Aufnahmekriterien für Gastgeber',
    },
    {
        section: 'text_section3',
        eng: "At socialspace, we take pride in fostering a community of like-minded organisations that share our values and vision. To maintain this high standard, we have established a set of qualification criteria for hosts. We look for hosts that demonstrate a strong commitment to our mission of fostering collaboration and sustainability. We seek organisations that value innovation, sustainability, and community-building as much as we do.",
        ger: 'Wir bei socialspace sind stolz darauf, eine Gemeinschaft von gleichgesinnten Organisationen zu fördern, die unsere Werte und Visionen teilen. Um diesen hohen Standard zu halten, haben wir eine Reihe von Qualifikationskriterien für Gastgeber aufgestellt. Wir suchen nach Gastgebern, die ein starkes Engagement für unsere Mission der Förderung von Zusammenarbeit und Nachhaltigkeit zeigen. Wir suchen nach Organisationen, die Innovation, Nachhaltigkeit und den Aufbau einer Gemeinschaft genauso schätzen wie wir.',
    },
    {
        section: 'subtitle1_section3',
        eng: "Non-profit organisations and associations",
        ger: 'Nicht gewinnorientierte Organisationen und gemeinnützige Vereine',
    },
    {
        section: 'subtitle2_section3',
        eng: "Disclaimer",
        ger: 'Disclaimer',
    },
    {
        section: 'criteria1_section3',
        eng: "Not-for-profit organisations and companies",
        ger: 'Nicht gewinnorientierte Organisationen und Unternehmen',
    },
    {
        section: 'criteria2_section3',
        eng: "non-profit associations",
        ger: 'Gemeinnützige Vereine',
    },
    {
        section: 'text_subtitle2_section3',
        eng: "Please note that as part of the onboarding process, you may be asked to provide evidence or proof of your non-profit status to ensure compliance with our qualification criteria. We appreciate your cooperation in maintaining the integrity of the socialspace community.",
        ger: 'Bitte beachten Sie, dass Sie im Rahmen des Onboarding-Prozesses möglicherweise aufgefordert werden, Nachweise oder Belege für Ihren gemeinnützigen Status vorzulegen, um die Einhaltung unserer Qualifikationskriterien sicherzustellen. Wir schätzen Ihre Kooperation, um die Integrität der Community von socialspace zu wahren.',
    },
    {
        section: 'title_section4',
        eng: "Suppport for hosts",
        ger: 'Unterstützung für Gastgeber',
    },
    {
        section: 'text_section4',
        eng: "At socialspace, we're committed to helping our hosts make the most of their listing and ensuring a seamless experience for both hosts and guests. To help you get started and manage your hosting journey effectively, we offer the following support:",
        ger: 'Wir bei socialspace wollen unseren Gastgebern helfen, das Beste aus ihrem Eintrag zu machen und eine nahtlose Erfahrung für Gastgeber und Gäste zu gewährleisten. Um Ihnen den Einstieg zu erleichtern und Ihre Reise als Gastgeber effektiv zu gestalten, bieten wir Ihnen den folgenden Support:',
    },
    {
        section: 'subtitle1_section4',
        eng: "Assistance with creating attractive profiles",
        ger: 'Unterstützung bei der Erstellung attraktiver Profile',
    },
    {
        section: 'subtitle2_section4',
        eng: "Guidance on workspace operations",
        ger: 'Anleitung zum Betrieb der Arbeitsplätze',
    },
    {
        section: 'subtitle3_section4',
        eng: "Provision of templates and tools for effective hosting",
        ger: 'Bereitstellung von Vorlagen und Tools für effektives Gastgeben',
    },
    {
        section: 'subtitle4_section4',
        eng: "Disclaimer",
        ger: 'Disclaimer',
    },
    
];
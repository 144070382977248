import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './HostBox.styles';
import { IMAGE_MAP } from '../helper/ImgMapper';
import { grey800 } from '../helper/MuiStyles';

export default function HostBox({ host, entryPoint }) {

    const styleProps = {
        partnerImg: IMAGE_MAP.PARTNERS[host.name]
    }

    const classes = useStyles(styleProps);

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.host_box}>
            <Box>
                <Box py={4} px={6}>
                    <div className={classes.partner_logo} title="HostLogo"></div>
                </Box>
                <Box px={4} pb={4}>
                    <Typography variant='h6'>
                        {host.name}
                    </Typography>
                    <Typography pt={3} variant='body2' style={{ color: grey800 }}>
                        {host.shortDescription}
                    </Typography>
                </Box>
            </Box>
            {entryPoint === "WorkspacePage" &&
                <Box pl={4} pb={4}>
                    <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={`/host/${host.hostId}`}
                    >
                        Find out more
                    </Button>
                </Box>
            }

        </Box>
    )
}
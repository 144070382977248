import makeStyles from "@mui/styles/makeStyles";
import { green100 } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    mac_preview: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgMacPreview})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '40vw',
        minWidth: '40vw',
    },
    paper: {
        minHeight: '100vh',
    },
    mission_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgMission})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '25vw',
        minWidth: '25vw',
    },
    team_photo: {
        backgroundImage: props => `url(${props.backgroundImgTeam})`,
        width: '40vw',
        height: '40vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        borderRadius: '8px',
        filter: 'drop-shadow(0px 5px 15px rgba(54, 79, 81, 0.2))',
    },
    roadtrip_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgRoadtrip})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '25vw',
        minWidth: '25vw',
    },
    background_image_footer: {
        height: 'calc(100vh - 80px)',
        backgroundImage: props => `url(${props.backgroundImgFooter})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },     
    icon_button_tertiary: {
        padding: '0 8px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0)',
        },
    },
    image_content_container: {
        background: 'linear-gradient(to top, #0A6466 0%, rgba(10, 100, 102, 0) 20%, rgba(255, 255, 255, 0) 35%, #FFFFFF 95%)',
    },
    footer: {
        color: green100,
    },
    link: {
        marginLeft: '32px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },
}));
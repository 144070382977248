import React from 'react';
import { AppBar, Typography, Box, Button } from '@mui/material';
import { useStyles } from './BookBarMobile.styles';
import { Link } from 'react-router-dom';

export default function BookBarMobile({ workspace, totalPrice }) {

    const classes = useStyles();

    return (
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
            <Box px={3} display="flex" alignItems="center" className={classes.banner}>
                <Box flexGrow={1}>
                    <Typography variant="h3Mobile">
                    {workspace.currency}{totalPrice}
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/workspace/${workspace.socialspaceId}/request`}
                    sx={{ height: '48px', padding: '12px 24px !important', }}
                >
                    Request
                </Button>
            </Box>
        </AppBar>
    );
};

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    socialspace_box: {
        minHeight: '200px',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
    },
    office_picture: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.spaceImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '300px',
        height: 'auto',
    },
    host_picture: {
        display: 'flex',
        flexGrow: 1,
        backgroundPosition: 'right',
        backgroundImage: props => `url(${props.hostLogo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '45px',
        width: 'auto',
    },
}));
import React, { useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './ImprintPage.styles';
import { AppContext } from '../../components/other/contexts/AppContext';
import { IMPRINT } from '../../data/translation/TranslationImprint';
import { Link } from 'react-router-dom';
import FooterDesktop from '../../components/Footer/desktop/FooterDesktop';
import FooterMobile from '../../components/Footer/mobile/FooterMobile';


export default function ImprintPage({ device }) {

    const { language } = useContext(AppContext);

    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <>
        <Box px={device === "desktop" ? "10vw" : "24px"} py={8} display="flex" flexDirection="column" className={classes.container}>
            <Typography variant={device === "desktop" ? "h2" : "h2Mobile"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('title')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('text_generic_1')][language]}<span className={classes.headline}>§ 5 TMG</span>:
            </Typography>
            <Typography mt={3} variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('phillip_1')][language]}
            </Typography>
            <Typography variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('phillip_2')][language]}
            </Typography>
            <Typography variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('phillip_3')][language]}
            </Typography>
            <Typography mt={3} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('contact')][language]}:
            </Typography>
            <Typography variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('phone')][language]}
            </Typography>
            <Typography variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('mail')][language]}<a href="mailto:socialspace.work.impact@gmail.com" className={classes.link}>socialspace.work.impact@gmail.com</a>
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('text_generic_2')][language]}<span className={classes.headline}>§ 55 Abs. 2 RStV</span>:
            </Typography>
            <Typography mt={3} variant={device === "desktop" ? "body1" : "body2"}>
                Phillip Dürner
            </Typography>
            <Typography variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('phillip_2')][language]}
            </Typography>
            <Typography variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('phillip_3')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "h6" : "h6Mobile"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('text_generic_3')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                1. {IMPRINT[IMPRINT.map(object => object.section).indexOf('title_1')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('text_1')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                2. {IMPRINT[IMPRINT.map(object => object.section).indexOf('title_2')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('text_2')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                3. {IMPRINT[IMPRINT.map(object => object.section).indexOf('title_3')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('text_3')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                4. {IMPRINT[IMPRINT.map(object => object.section).indexOf('title_4')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('text_4')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                5. {IMPRINT[IMPRINT.map(object => object.section).indexOf('title_5')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('text_5_1')][language]}
                <Link to={`/termsofuse`} className={classes.link_container}>
                    <span className={classes.link}>{IMPRINT[IMPRINT.map(object => object.section).indexOf('title_5')][language]}</span>
                </Link>
                {IMPRINT[IMPRINT.map(object => object.section).indexOf('text_5_2')][language]}
            </Typography>
        </Box>
        {device === "desktop" ? <FooterDesktop /> : <FooterMobile />}
        </>
    )
}
import makeStyles from "@mui/styles/makeStyles";
import { grey1000 } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    background_image_header: {
        height: '65vh',
        backgroundImage: props => `url(${props.backgroundImgHeader})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
    headline: {
        color: '#FFF',        
        fontFamily: 'Poppins600',
        fontSize: '40px',
        lineHeight: '60px',
        letterSpacing: '0.002em',
        maxWidth: '760px'
    },
    headline_caption: {
        fontFamily: 'Poppins600',
        color: grey1000,
        fontSize: '26px',
        lineHeight: '39px',
        letterSpacing: '0.005em',
        textAlign: 'center',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    partner_box: {
        margin: '0 24px 48px 24px',
        textDecoration: 'none',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        '&:hover': {
            boxShadow: '0px 5px 25px rgba(54, 79, 81, 0.3)',
        },
    },
}));
import React, { useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './TermsOfUsePage.styles';
import { AppContext } from '../../components/other/contexts/AppContext';
import { TERMS_OF_USE } from '../../data/translation/TranslationTermsOfUse';
import FooterDesktop from '../../components/Footer/desktop/FooterDesktop';
import FooterMobile from '../../components/Footer/mobile/FooterMobile';


export default function TermsOfUsePage({ device }) {

    const { language } = useContext(AppContext);

    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <>
        <Box px={device === "desktop" ? "10vw" : "24px"} py={8} display="flex" flexDirection="column" className={classes.container}>
            <Typography variant={device === "desktop" ? "h2" : "h2Mobile"}>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('title')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"}>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_generic')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                1. {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('title_1')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_1')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                2. {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('title_2')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>2.1 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_2_1')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>2.2 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_2_2')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                3. {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('title_3')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>3.1 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_3_1')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>3.2 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_3_2')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                4. {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('title_4')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>4.1 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_4_1')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>4.2 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_4_2')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                5. {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('title_5')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>5.1 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_5_1')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>5.2 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_5_2')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>5.3 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_5_3')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>5.4 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_5_4')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                6. {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('title_6')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_6')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                7. {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('title_7')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>7.1 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_7_1')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>7.2 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_7_2')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>7.3 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_7_3')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>7.4 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_7_4')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                8. {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('title_8')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_8')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                9. {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('title_9')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>9.1 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_9_1')][language]}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                <span className={classes.headline}>9.2 </span>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_9_2_1')][language]}
                <a href="http://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer" className={classes.link}>http://ec.europa.eu/consumers/odr/</a>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_9_2_2')][language]}
            </Typography>
            <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                10. {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('title_10')][language]} {device}
            </Typography>
            <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                {TERMS_OF_USE[TERMS_OF_USE.map(object => object.section).indexOf('text_10')][language]}
            </Typography>
        </Box>
        {device === "desktop" ? <FooterDesktop /> : <FooterMobile />}
        </>
    )
}
import React, { useState } from 'react'

const DayMonthContext = React.createContext(null);

const DayMonthProvider = ({ children }) => {
    const [isMonthSet, setIsMonthSet] = useState(false);

    return (
        <DayMonthContext.Provider value={{
            isMonthSet, setIsMonthSet
        }}
        >
            {children}
        </DayMonthContext.Provider>
    )
};

export {DayMonthContext, DayMonthProvider}
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, IconButton, Button, Checkbox, FormControlLabel, InputBase, TextField } from '@mui/material';
import { useStyles } from './RequestPageMobile.styles';
import { WORKSPACES } from '../../../data/data';
import Icon from '../../../assets/icons/Icon';
import { green1000, grey600 } from '../../../components/other/helper/MuiStyles';
import { HOMEPAGE_MOBILE } from '../../../data/translation/TranslationHomepageMobile';
import { AppContext } from '../../../components/other/contexts/AppContext';
import emailjs from '@emailjs/browser';

export default function RequestPageMobile() {

    const { language, totalPrice, selectedDays, selectedMonths, setIsSnackbarOpen } = useContext(AppContext);
    const navigate = useNavigate();

    let { id } = useParams();

    const workspace = WORKSPACES.find(object => {
        return object.socialspaceId === id;
    });


    const { setSnackbarMessage } = useContext(AppContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [workspaceId, setWorkspaceId] = useState('');
    const [reservationDates, setReservationDates] = useState([]);
    const [isChecked, setIsChecked] = useState(false);

    function validateEmail(email) {
        var regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regEx.test(email)
    };

    const sendRequest = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            e.target,
            process.env.REACT_APP_EMAILJS_USER_ID
        )
            .then((result) => {
                console.log(result.text);
                console.log("message sent");
                e.target.reset();
            }, (error) => {
                console.log(error.text);
            });

        setSnackbarMessage('You have successfully requested a reservation. The host will get in contact with you.');
        setName('');
        setEmail('');
        setComment('');
        setWorkspaceId('');
        setIsSnackbarOpen(true);
        setReservationDates([]);
        navigate(`/workspace/${workspace.socialspaceId}`);
        window.scrollTo(0, 0);
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (selectedDays.length > 0 && selectedDays[0].days !== undefined) {
            let value = [];
            selectedDays.forEach(dayGroup => {
                dayGroup.days.forEach(day => {
                    value.push(`${day.date} ${dayGroup.month}`);
                });
            });
            setReservationDates(value);
        } else if (selectedMonths.length > 0) {
            setReservationDates(selectedMonths);
        }
    
        setWorkspaceId(workspace.socialspaceId);
    }, [selectedDays, selectedMonths, workspace.socialspaceId]);

    const classes = useStyles();

    return (
        <>

            {/* HEADER */}
            <Box mx={3} mt={3} display="flex" flexDirection="column">
                <Typography variant='h2Mobile'>
                    Request reservation
                </Typography>
                <Typography mt={3} variant="body1" >
                    You won’t be charged yet. socialspace shares your reservation request with the host. Please refer to our <Link to="/termsofuse">Terms of use</Link> for further information.
                </Typography>
            </Box>


            {/* WORKSPACE TYPE */}
            <Box mx={3} mt={5} display="flex" flexDirection="column">
                <Typography pb={1} variant="body1" style={{ fontFamily: 'Poppins600' }}>
                    Workspace type
                </Typography>
                <Typography variant="body1">
                    {workspace.workspaceType}
                </Typography>
            </Box>

            {/* HOST */}
            <Box mx={3} mt={5} display="flex" flexDirection="column">
                <Typography pb={1} variant="body1" style={{ fontFamily: 'Poppins600' }}>
                    Host
                </Typography>
                <Typography variant="body1">
                    {workspace.host}
                </Typography>
                <Typography variant="body1">
                    {workspace.address.postalCode} {workspace.location}
                </Typography>
                <Typography variant="body1">
                    {workspace.address.street}
                </Typography>
            </Box>

            {/* REQUEST */}
            <Box mx={3} mt={5} display="flex" flexDirection="column">
                <Typography pb={1} variant="body1" style={{ fontFamily: 'Poppins600' }}>
                    Requested dates
                </Typography>
                {selectedDays?.map((option1, index1) => {
                    return (
                        <Box key={index1} display="flex" flexDirection="column">
                            {selectedDays[index1]?.days.map((option2, index2) => {
                                return (
                                    <Box key={index2} display="flex" alignItems="center" justifyContent="space-between" style={{ borderBottom: (index1 === (selectedDays.length - 1) && index2 === (selectedDays[index1].days.length - 1)) ? `1px solid ${grey600}` : '' }}>
                                        <Typography variant="body1" style={{ paddingBottom: '8px' }}>
                                            {option2.date < 10 ? '0' + option2.date : option2.date} {option1.month} 2024
                                        </Typography>
                                        <Typography variant="body1" style={{ paddingBottom: '8px', marginLeft: '8px' }}>
                                            {workspace.currency}{option2.price}
                                        </Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    )
                })}
                {selectedMonths?.map((option, index) => {
                    return (
                        <Box key={index} display="flex" alignItems="center" justifyContent="space-between" style={{ borderBottom: (index === (selectedMonths.length - 1)) ? `1px solid ${grey600}` : '' }}>
                            <Typography variant="body1" style={{ paddingBottom: '8px' }}>
                                {option} 2024
                            </Typography>
                            <Typography variant="body1" style={{ paddingBottom: '8px', marginLeft: '8px' }}>
                                {workspace.currency}{totalPrice / selectedMonths.length}
                            </Typography>
                        </Box>
                    )
                })}
                <Box pt={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body1" style={{ fontFamily: 'Poppins600' }}>
                        Total
                    </Typography>
                    <Typography ml={1} variant="body1" style={{ fontFamily: 'Poppins600' }}>
                        {workspace.currency}{totalPrice}
                    </Typography>
                </Box>
            </Box>

            <form onSubmit={sendRequest}>
                <Box mx={3} mt={5} display="flex" flexDirection="column">
                    <TextField
                        label="Name"
                        name='name'
                        placeholder='Enter your name'
                        value={name}
                        onChange={(event) => { setName(event.target.value) }}
                        error={name.length < 3}
                    />
                    {/* MAIL */}
                    <TextField
                        label="Email"
                        name='email'
                        placeholder='Enter your email address'
                        value={email}
                        onChange={(event) => { setEmail(event.target.value) }}
                        error={!validateEmail(email)}
                        style={{ marginTop: '24px' }}
                    />
                    <TextField
                        label="Comment"
                        name='comment'
                        placeholder='Leave a note if you want'
                        value={comment}
                        multiline
                        onChange={(event) => { setComment(event.target.value) }}
                        style={{ margin: '24px 0' }}
                    />
                    {/* RESERVATION DATES */}
                    <InputBase
                        name='reservationDates'
                        value={reservationDates}
                        style={{ display: 'none' }}
                    >
                    </InputBase>
                    {/* WORKSPACE ID */}
                    <InputBase
                        name='workspaceId'
                        value={workspaceId}
                        style={{ display: 'none' }}
                    >
                        {/* CHECKBOX */}
                    </InputBase>
                    <Box display="flex" alignItems="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                />
                            }
                            label=""
                        />
                        <Typography variant="body3">I have read and understood the <Link to="/privacypolicy">Privacy Policy</Link> and agree to the processing of my personal data.</Typography>
                    </Box>
                    <Box mt={3} mb={8} display="flex" width="calc(100vw - 48px)">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                height: '48px',
                                padding: '12px 24px !important',
                                width: '100% !important',
                            }}
                            disabled={name.length < 3 || !validateEmail(email) || !isChecked}
                        >
                            Request reservation
                        </Button>
                    </Box>
                </Box>

            </form>

            {/* FOOTER */}
            <Box px={3} pt={4} display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: green1000 }}>
                <Box pb={3.5} display="flex" flexDirection="column">
                    <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography className={classes.logo}>socialspace</Typography>
                        <Box display="flex" alignItems="center" height='20px'>
                            <IconButton
                                component="a"
                                href="https://instagram.com/socialspacework?igshid=YmMyMTA2M2Y="
                                target="_blank"
                                rel="noopener noreferrer"
                                color="secondary"
                                className={classes.icon_button_tertiary}
                            >
                                <Icon width={18} height={18} iconName="Instagram" />
                            </IconButton>
                            <IconButton
                                component="a"
                                href="https://twitter.com/socialspacework?s=11&t=U-yVjKdFPsf1bZ9fImbBwA"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="secondary"
                                className={classes.icon_button_tertiary}
                            >
                                <Icon width={18} height={18} iconName="Twitter" />
                            </IconButton>
                            <IconButton
                                component="a"
                                href="https://www.linkedin.com/company/socialspacework/"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="secondary"
                                className={classes.icon_button_tertiary}
                            >
                                <Icon width={18} height={18} iconName="LinkedIn" />
                            </IconButton>
                        </Box>
                    </Box>
                    <Link to={`/termsofuse`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('terms')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/imprint`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('imprint')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/privacypolicy`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('privacy')][language]}
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </>
    )
}
import makeStyles from "@mui/styles/makeStyles";
import { green100 } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    assistance_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgAssistanceIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '15vw',
        minWidth: '15vw',
    },
    benefits_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgBenefitsIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '20vw',
        minWidth: '20vw',
    },
    contact_form_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgContactFormIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '15vw',
        minWidth: '15vw',
    },
    criteria_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgCriteriaIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '20vw',
        minWidth: '20vw',
    },
    footer: {
        color: green100,
    },
    guidance_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgGuidanceIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '15vw',
        minWidth: '15vw',
    },
    icon_button_tertiary: {
        padding: '0 8px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0)',
        },
    },
    link: {
        marginLeft: '32px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },
    mac_preview: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgMacPreview})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '40vw',
        minWidth: '40vw',
    },
    paper: {
        minHeight: '100vh',
    },
    process_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgProcessIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '20vw',
        minWidth: '20vw',
    },
    photo_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgPhotoIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '15vw',
        minWidth: '15vw',
    },
    process_step_description:{
        fontFamily: 'Poppins400',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.02em',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    provision_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgProvisionIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '15vw',
        minWidth: '15vw',
    },
    review_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgReviewIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '15vw',
        minWidth: '15vw',
    },
    roadtrip_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgRoadtrip})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '20vw',
        minWidth: '20vw',
    },
    support_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgSupportIllustration})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '20vw',
        minWidth: '20vw',
    },
}));
export const TERMS_OF_USE = [
    {
        section: 'title',
        eng: 'Terms of use',
        ger: 'Nutzungsbedinungen',
    },
    {
        section: 'title_1',
        eng: 'Scope of application',
        ger: 'Geltungsbereich',
    },
    {
        section: 'text_generic',
        eng: 'By using the platform, you agree to these terms of use and undertake to comply with them.',
        ger: 'Indem Sie die Plattform nutzen, erklären Sie sich mit diesen Nutzungsbedingungen einverstanden und verpflichten sich, diese einzuhalten.',
    },
    {
        section: 'text_1',
        eng: 'These terms of use apply to all users of socialspace (hereinafter referred to as "platform") in the current prototype test phase. The platform is operated by Phillip Dürner (Am Griesfeld 2a, 82178 Puchheim) (hereinafter referred to as "provider" or "we"). By using the platform, you agree to these terms of use.',
        ger: 'Diese Nutzungsbedingungen gelten für alle Nutzer von socialspace (nachfolgend "Plattform" genannt) in der aktuellen Prototyp-Testphase. Die Plattform wird von Phillip Dürner (Am Griesfeld 2a, 82178 Puchheim) (nachfolgend "Anbieter" oder "wir" genannt) betrieben. Mit der Nutzung der Plattform erklären Sie sich mit diesen Nutzungsbedingungen einverstanden.',
    },
    {
        section: 'title_2',
        eng: 'Services and functional scope of the platform',
        ger: 'Leistungen und Funktionsumfang der Plattform',
    },
    {
        section: 'text_2_1',
        eng: 'The platform enables users to view information about hosts and workplaces and to submit reservation requests to hosts. The content and information about hosts and workplaces are published based on a cooperation agreement between the provider of SocialSpace and the host organisations.',
        ger: 'Die Plattform ermöglicht Nutzern, Informationen über Gastgeber und Arbeitsplätze einzusehen und Reservierungsanfragen bei Gastgebern zu stellen. Die Inhalte und Informationen zu Gastgebern und Arbeitsplätzen werden basierend auf einem Kooperationsvertrag zwischen dem Anbieter von socialspace und den Gastgeberorganisationen veröffentlicht.',
    },
    {
        section: 'text_2_2',
        eng: 'The provider always strives to ensure a smooth and reliable operation of the platform, but cannot guarantee 100% availability. The provider reserves the right to temporarily restrict or completely discontinue access to the platform in order to perform maintenance work, updates or other measures.',
        ger: 'Der Anbieter ist stets bemüht, einen reibungslosen und zuverlässigen Betrieb der Plattform zu gewährleisten, kann jedoch keine 100%ige Verfügbarkeit garantieren. Der Anbieter behält sich das Recht vor, den Zugang zur Plattform zeitweise einzuschränken oder ganz einzustellen, um Wartungsarbeiten, Updates oder andere Maßnahmen durchzuführen.',
    },
    {
        section: 'title_3',
        eng: 'Use of the platform',
        ger: 'Nutzung der Plattform',
    },
    {
        section: 'text_3_1',
        eng: 'Since no registration is required, you are responsible for ensuring that your use of the platform is in accordance with these terms of use, applicable law, morality and the rights of third parties (e.g. copyrights, trademark rights, personal rights).',
        ger: 'Da keine Registrierung erforderlich ist, sind Sie dafür verantwortlich, dass Ihre Nutzung der Plattform im Einklang mit diesen Nutzungsbedingungen, geltendem Recht, den guten Sitten und den Rechten Dritter (z. B. Urheberrechte, Markenrechte, Persönlichkeitsrechte) steht.',
    },
    {
        section: 'text_3_2',
        eng: 'You are responsible for the confidentiality of your personal data and are liable for all activities carried out using your personal data on the platform. You undertake to inform the provider immediately of any unauthorized access or misuse of your personal data.',
        ger: 'Sie sind verantwortlich für die Vertraulichkeit Ihrer persönlichen Daten und haften für alle Aktivitäten, die unter Verwendung Ihrer persönlichen Daten auf der Plattform durchgeführt werden. Sie verpflichten sich, den Anbieter unverzüglich über unberechtigte Zugriffe oder Missbrauch Ihrer persönlichen Daten zu informieren.',
    },
    {
        section: 'title_4',
        eng: 'Duties of the users',
        ger: 'Pflichten der Nutzer',
    },
    {
        section: 'text_4_1',
        eng: 'When using the platform, especially when making reservation requests, you must take into account the rights and legitimate interests of other users, hosts and third parties. Any behavior that violates legal provisions or morality or impairs others in their rights is prohibited.',
        ger: 'Bei der Nutzung der Plattform, insbesondere bei Reservierungsanfragen, müssen Sie die Rechte und berechtigten Interessen anderer Nutzer, der Gastgeber sowie Dritter berücksichtigen. Jegliches Verhalten, das gesetzliche Bestimmungen oder die guten Sitten verletzt oder andere in ihren Rechten beeinträchtigt, ist untersagt.',
    },
    {
        section: 'text_4_2',
        eng: 'You agree not to harass, threaten, insult or otherwise interfere with the rights of other users of the platform or hosts.',
        ger: 'Sie verpflichten sich, andere Nutzer der Plattform sowie Gastgeber nicht zu belästigen, zu bedrohen, zu beleidigen oder auf sonstige Weise in ihren Rechten zu beeinträchtigen.',
    },
    {
        section: 'title_5',
        eng: 'Limitation of liability and exclusion of liability',
        ger: 'Pflichten der Nutzer',
    },
    {
        section: 'text_5_1',
        eng: 'The provider assumes no liability for the accuracy, completeness and timeliness of the information and content provided on the platform. In particular, the provider is not liable for any damages resulting from decisions or actions based on information and content on the platform.',
        ger: 'Der Anbieter übernimmt keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der auf der Plattform bereitgestellten Informationen und Inhalte. Insbesondere haftet der Anbieter nicht für eventuelle Schäden, die aus Entscheidungen oder Handlungen aufgrund von Informationen und Inhalten auf der Plattform entstehen.',
    },
    {
        section: 'text_5_2',
        eng: 'The provider is not liable for damages caused by force majeure, riots, acts of war and natural disasters or other events for which he is not responsible (e.g. strikes, lockouts, traffic disruptions, orders of domestic or foreign authorities).',
        ger: 'Der Anbieter haftet nicht für Schäden, die durch höhere Gewalt, Aufruhr, Kriegs- und Naturereignisse oder durch sonstige von ihm nicht zu vertretende Vorkommnisse (z.B. Streik, Aussperrung, Verkehrsstörungen, Verfügungen von hoher Hand im In- oder Ausland) entstanden sind.',
    },
    {
        section: 'text_5_3',
        eng: 'The provider is not liable for the accessibility, security or quality of communication between users and hosts or for the fulfillment of reservations, contracts or other agreements made between users and hosts. The responsibility for these aspects lies with the respective users and hosts.',
        ger: 'Der Anbieter haftet nicht für die Erreichbarkeit, Sicherheit oder die Qualität der Kommunikation zwischen Nutzern und Gastgebern oder für die Erfüllung von Reservierungen, Verträgen oder anderen Vereinbarungen, die zwischen Nutzern und Gastgebern getroffen werden. Die Verantwortung für diese Aspekte liegt bei den jeweiligen Nutzern und Gastgebern.',
    },
    {
        section: 'text_5_4',
        eng: 'The provider is not liable for technical malfunctions, server failures or other problems that are beyond its control and may affect the availability or use of the platform.',
        ger: 'Der Anbieter haftet nicht für technische Störungen, Serverausfälle oder andere Probleme, die außerhalb seines Einflussbereichs liegen und die Verfügbarkeit oder Nutzung der Plattform beeinträchtigen können.',
    },
    {
        section: 'title_6',
        eng: 'Changes to the terms of use',
        ger: 'Änderungen der Nutzungsbedingungen',
    },
    {
        section: 'text_6',
        eng: 'The provider reserves the right to change these terms of use at any time and without giving reasons. The amended terms of use will be announced on the platform. By continuing to use the platform, you agree to the amended terms of use.',
        ger: 'Der Anbieter behält sich das Recht vor, diese Nutzungsbedingungen jederzeit und ohne Angabe von Gründen zu ändern. Die geänderten Nutzungsbedingungen werden auf der Plattform bekanntgegeben. Durch die weitere Nutzung der Plattform erklären Sie sich mit den geänderten Nutzungsbedingungen einverstanden.',
    },
    {
        section: 'title_7',
        eng: 'Final provisions',
        ger: 'Schlussbestimmungen',
    },
    {
        section: 'text_7_1',
        eng: 'Should individual provisions of these terms of use be or become invalid in whole or in part, this shall not affect the validity of the remaining provisions. The invalid provision shall be replaced by a valid provision that comes as close as possible to the economic purpose of the invalid provision.',
        ger: 'Sollten einzelne Bestimmungen dieser Nutzungsbedingungen ganz oder teilweise unwirksam sein oder werden, so bleibt die Wirksamkeit der übrigen Bestimmungen hiervon unberührt. Die unwirksame Regelung ist durch eine wirksame zu ersetzen, die dem wirtschaftlichen Zweck der unwirksamen Regelung am nächsten kommt.',
    },
    {
        section: 'text_7_2',
        eng: "The law of the country in which the provider has its registered office shall apply. The place of jurisdiction for all disputes arising from or in connection with these terms of use shall be the provider's registered office.",
        ger: 'Es gilt das Recht des Landes, in dem der Anbieter seinen Sitz hat. Gerichtsstand für alle Streitigkeiten, die sich aus oder im Zusammenhang mit diesen Nutzungsbedingungen ergeben, ist der Sitz des Anbieters.',
    },
    {
        section: 'text_7_3',
        eng: 'The provider reserves the right to make changes or additions to these terms of use at any time. Changes will be announced to the users in due time before they come into effect.',
        ger: 'Der Anbieter behält sich das Recht vor, jederzeit Änderungen oder Ergänzungen dieser Nutzungsbedingungen vorzunehmen. Änderungen werden den Nutzern rechtzeitig vor Inkrafttreten bekanntgegeben.',
    },
    {
        section: 'text_7_4',
        eng: 'These terms of use were last updated on 01.05.2023.',
        ger: 'Diese Nutzungsbedingungen sind zuletzt am 01.05.2023 aktualisiert worden.',
    },
    {
        section: 'title_8',
        eng: 'Contact',
        ger: 'Kontakt',
    },
    {
        section: 'text_8',
        eng: 'If you have any questions or concerns regarding these terms of use, please feel free to contact us by email. You can find our contact details in the imprint of the platform.',
        ger: 'Wenn Sie Fragen oder Anliegen bezüglich dieser Nutzungsbedingungen haben, können Sie uns gerne per E-Mail kontaktieren. Unsere Kontaktdaten finden Sie im Impressum der Plattform.',
    },
    {
        section: 'title_9',
        eng: 'Complaints and dispute resolution',
        ger: 'Beschwerden und Streitbeilegung',
    },
    {
        section: 'text_9_1',
        eng: 'Users have the option of directing complaints to the provider. The provider will endeavor to process these complaints within a reasonable period of time and offer a solution. If no agreement can be reached, users can turn to the competent conciliation body.',
        ger: 'Nutzer haben die Möglichkeit, Beschwerden direkt an den Anbieter zu richten. Der Anbieter wird sich bemühen, diese Beschwerden innerhalb einer angemessenen Frist zu bearbeiten und eine Lösung anzubieten. Sollte keine Einigung erzielt werden, können sich die Nutzer an die zuständige Schlichtungsstelle wenden.',
    },
    {
        section: 'text_9_2_1',
        eng: 'The European Commission provides a platform for online dispute resolution (OS), which can be found at ',
        ger: 'Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter ',
    },
    {
        section: 'text_9_2_2',
        eng: '. We are neither obligated nor willing to participate in a dispute resolution procedure before a consumer arbitration board.',
        ger: ' finden. Wir sind weder verpflichtet noch bereit, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.',
    },
    {
        section: 'title_10',
        eng: 'Severability clause',
        ger: 'Salvatorische Klausel',
    },
    {
        section: 'text_10',
        eng: 'If any provision of these terms of use is or becomes invalid or unenforceable, the remaining provisions shall not be affected thereby. The invalid or unenforceable provision shall be replaced by a valid and enforceable provision that comes as close as possible to the economic purpose of the invalid or unenforceable provision.',
        ger: 'Sollte eine Bestimmung dieser Nutzungsbedingungen unwirksam oder undurchsetzbar sein oder werden, so bleiben die übrigen Bestimmungen hiervon unberührt. Die unwirksame oder undurchsetzbare Bestimmung ist durch eine wirksame und durchsetzbare Bestimmung zu ersetzen, die dem wirtschaftlichen Zweck der unwirksamen oder undurchsetzbaren Bestimmung am nächsten kommt.',
    },
];
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import Slider from 'react-slick';

const ImageCarousel = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(1);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => setCurrentSlide(current + 1),
    };

    return (
        <div style={{ width: '100vw', overflow: 'hidden', position: 'relative' }}>
            <Slider {...sliderSettings}>
                {images.map((image, index) => (
                    <div key={index}>
                        <img src={image} alt={`carousel-${index}`} style={{ width: '100%', display: 'block' }} />
                    </div>
                ))}
            </Slider>
            <Box display="flex" alignItems="center" justifyContent="center"
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    width: '48px',
                    padding: '4px 0',
                    backgroundColor: 'rgba(4, 35, 37, 0.6)',
                    borderRadius: 2,
                }}
            >
                <Typography variant="body3" style={{color: '#FFF'}}>{currentSlide} / {images.length}</Typography>                
            </Box>
        </div>
    );
};

export default ImageCarousel;

import makeStyles from "@mui/styles/makeStyles";
import { green100, green800, green900, grey1000 } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    background_image_header: {
        height: 'calc(60vh)',
        backgroundImage: props => `url(${props.backgroundImgHeader})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
    containedPrimary: {
        fontSize: '16px',
        borderRadius: '8px',
        padding: '12px 16px',
        height: '40px',
        backgroundColor: green800,
        color: '#FFF',
        '&:hover': {
            backgroundColor: green900,
        },
    },
    footer: {
        color: green100,
    },
    headline: {
        color: '#FFF',
        textShadow: '0px 4px 120px rgba(4, 35, 37, 0.9)',
    },
    headline_caption: {
        fontFamily: 'Poppins600',
        color: grey1000,
        fontSize: '26px',
        lineHeight: '39px',
        letterSpacing: '0.005em',
        textAlign: 'center',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    headline_highlight: {
        marginLeft: '8px',
        color: '#FFF',
    },
    headline_text: {
        fontFamily: 'Poppins400',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.03em',
        color: grey1000,
        textAlign: 'center',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    highlight_box: {
        margin: '-80px 0 80px 0',
        width: '80vw',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 30px rgba(54, 79, 81, 0.2)',
    },
    highlight_number: {
        fontFamily: 'Poppins600',
        color: '#FFF',
        fontSize: '56px',
        lineHeight: '84px',
        letterSpacing: '0.002em',
        textAlign: 'center',
    },
    icon_button_tertiary: {
        padding: '0 8px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0)',
        },
    },
    image_content_container: {
        background: 'linear-gradient(to right, rgba(4, 35, 37, 0.4), rgba(4, 35, 37, 0) 50%)',
    },
    link: {
        marginLeft: '32px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },
    mission_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgMission})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '25vw',
        minWidth: '25vw',
    },
    paper: {
        minHeight: '100vh',
    },
    partner_box: {
        margin: '0 24px 48px 24px',
        textDecoration: 'none',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        '&:hover': {
            boxShadow: '0px 5px 25px rgba(54, 79, 81, 0.3)',
        },
    },
    socialspace_box: {
        margin: '0 24px 48px 24px',
        textDecoration: 'none',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        '&:hover': {
            boxShadow: '0px 5px 25px rgba(54, 79, 81, 0.3)',
        },
    },
    step1_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgStep1})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        maxHeight: '320px',
        minHeight: '10vw',
        minWidth: 'calc((80vw - 40px - 40px - 40px - 40px)/3)',
    },
    step2_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgStep2})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        maxHeight: '320px',
        minHeight: '10vw',
        minWidth: 'calc((80vw - 40px - 40px - 40px - 40px)/3)',
    },
    step3_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.backgroundImgStep3})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        maxHeight: '320px',
        minHeight: '10vw',
        minWidth: 'calc((80vw - 40px - 40px - 40px - 40px)/3)',
    },
    text_box: {
        marginLeft: '10vw',
        marginBottom: '7.5vh',
        textAlign: 'left',
        width: 'calc(100vw - 15vw)',
    },
}));
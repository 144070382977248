import { createTheme } from '@mui/material/styles';

//PRIMITIVES
export const white = '#FFF';

export const green1000 = '#0a6466';
export const green900 = '#0c7173';
export const green800 = '#0D7D80';
export const green600 = '#3d9799';
export const green400 = '#6eb1b3';
export const green200 = '#9ecbcc';
export const green100 = '#cfe5e6';
export const green50 = '#e7f2f2';

export const grey1000 = '#042325';
export const grey900 = '#1d393b';
export const grey800 = '#364F51';
export const grey600 = '#687B7C';
export const grey400 = '#9BA7A8';
export const grey200 = '#CDD3D3';
export const grey100 = '#E6E9E9';
export const grey50 = '#F3F4F4';

export const red800 = '#CA054D';
export const yellow800 = '#FFA91F';

//TOKENS
export const colorTextPrimary = grey1000;
export const colorTextSecondary = grey800;
export const colorTextTertiary = grey600;
export const colorTextContrast = white;
export const colorTextBrand = green800;
export const colorTextBrandStrong = green1000;
export const colorTextBrandMedium = green600;
export const colorTextBrandSubtle1 = green100;
export const colorTextBrandSubtle2 = green50;

export const colorSurfacePrimary = white;
export const colorSurfaceSecondary = grey50;
export const colorSurfaceBrandStrong = green1000;
export const colorSurfaceBrandSubtle = green50;

export const colorBorderSubtle = grey200;
export const colorBorderBrandStrong = green1000;
export const colorBorderBrand = green50;
export const colorBorderBrandSubtle1 = green200;
export const colorBorderBrandSubtle2 = green100;

//ELEVATION
export const elevation1 = '0px 5px 15px rgba(54, 79, 81, 0.2)';
export const elevation1Strong = '0px 5px 30px rgba(54, 79, 81, 0.2)';
export const elevation2 = '0px 5px 25px rgba(54, 79, 81, 0.3)';

export let theme = createTheme()

theme = createTheme(theme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins500',
                    borderRadius: '8px',
                    fontSize: '16px',
                    lineHeight: '16px',
                    height: '40px',
                    letterSpacing: '0.015em',
                    padding: '12px 16px !important',
                    textTransform: 'none',
                    boxShadow: 'none !important',
                },
                containedPrimary: {
                    backgroundColor: green800,
                    color: '#FFF',
                    '&:hover': {
                        backgroundColor: green900,
                    },
                },
                containedSecondary: {
                    backgroundColor: 'rgba(255,255,255, 1)',
                    color: green800,
                    '&:hover': {
                        backgroundColor: green50,
                    },
                },
                outlinedPrimary: {
                    border: `1px solid ${green800}`,
                    backgroundColor: 'none',
                    color: green800,
                    '&:hover': {
                        border: `1px solid ${green800}`,
                        backgroundColor: green50,
                    },
                },
                // BACK
                containedWarning: {
                    backgroundColor: grey100,
                    color: grey1000,
                    '&:hover': {
                        backgroundColor: 'rgba(230, 233, 233, 0.7)',
                    },
                },
                // BLACK
                containedInfo: {
                    backgroundColor: 'rgba(255,255,255, 1)',
                    color: grey1000,
                    '&:hover': {
                        backgroundColor: grey50
                    },
                },
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    margin: '0px',
                },
                container: {
                    backgroundColor: `rgba(4, 35, 37, 0.85)`,
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '40px',
                    msOverflowStyle: 'none', /* IE and Edge */
                    scrollbarWidth: 'none', /* Firefox */
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: grey1000,
                    fontFamily: 'Poppins400',
                    fontSize: '14px',
                    lineHeight: '21px',
                    letterSpacing: '0.03em',
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: green800,
                    '&:hover': {
                        backgroundColor: green900,
                    },
                },
                colorSecondary: {
                    '&:hover': {
                        backgroundColor: grey100,
                    },
                },
            }
        },
        MuiImageList: {
            styleOverrides: {
                root: {
                    margin: 0,
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    padding: '0px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: 'Poppins400',
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.04em',
                    '&:hover': {
                        backgroundColor: green100,
                    },
                    "&.Mui-selected": {
                        fontFamily: 'Poppins400',
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: '0.04em',
                        backgroundColor: green50,
                        color: grey1000,
                        '&:hover': {
                            backgroundColor: green100,
                        },
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: grey600,
                    "&.Mui-checked": {
                        color: green800,
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0)',
                    },
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
                }
            }
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    marginTop: '16px',
                    position: 'relative',
                    top: '0 !important',
                    left: '0 !important',
                    transform: 'none !important',
                }
            }
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(54, 79, 81, 0.10)',
                    border: `1px solid ${green800}`,
                    color: green1000,
                    fontFamily: 'Poppins500',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.03em',
                    padding: '16px 32px',
                    boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                },
                action: {
                    margin: 0,
                    padding: 0,
                },
            }
        },
        MuiSwitch: {
            styleOverrides: {
                thumb: {
                    color: green800,
                },
                track: {
                    backgroundColor: grey100,
                    opacity: 1,
                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    color: grey1000,
                },
                h1: {
                    fontFamily: 'Poppins600',
                    fontSize: '34px',
                    lineHeight: '42px',
                    letterSpacing: '0.002em',
                },
                h1Mobile: {
                    fontFamily: 'Poppins600',
                    fontSize: '30px',
                    lineHeight: '38px',
                    letterSpacing: '0.005em',
                },
                h2: {
                    fontFamily: 'Poppins600',
                    fontSize: '30px',
                    lineHeight: '38px',
                    letterSpacing: '0.005em',
                },
                h2Mobile: {
                    fontFamily: 'Poppins600',
                    fontSize: '26px',
                    lineHeight: '34px',
                    letterSpacing: '0.005em',
                },
                h3: {
                    fontFamily: 'Poppins600',
                    fontSize: '26px',
                    lineHeight: '34px',
                    letterSpacing: '0.005em',
                },
                h3Mobile: {
                    fontFamily: 'Poppins600',
                    fontSize: '22px',
                    lineHeight: '30px',
                    letterSpacing: '0.01em',
                },
                h4: {
                    fontFamily: 'Poppins600',
                    fontSize: '22px',
                    lineHeight: '30px',
                    letterSpacing: '0.01em',
                },
                h4Mobile: {
                    fontFamily: 'Poppins600',
                    fontSize: '20px',
                    lineHeight: '28px',
                    letterSpacing: '0.015em',
                },
                h5: {
                    fontFamily: 'Poppins600',
                    fontSize: '20px',
                    lineHeight: '28px',
                    letterSpacing: '0.015em',
                },
                h5Mobile: {
                    fontFamily: 'Poppins600',
                    fontSize: '18px',
                    lineHeight: '26px',
                    letterSpacing: '0.015em',
                },
                h6: {
                    fontFamily: 'Poppins600',
                    fontSize: '18px',
                    lineHeight: '26px',
                    letterSpacing: '0.015em',
                },
                h6Mobile: {
                    fontFamily: 'Poppins600',
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.015em',
                },
                body1: {
                    fontFamily: 'Poppins400',
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.02em',
                },
                body2: {
                    fontFamily: 'Poppins400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.03em',
                },
                body3: {
                    fontFamily: 'Poppins400',
                    fontSize: '12px',
                    lineHeight: '18px',
                    letterSpacing: '0.04em',
                }
            }
        }
    }
});
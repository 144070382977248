import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './HeaderDesktop.styles';
// import LanguageSwitch from '../../other/dialogs/LanguageSwitch/LanguageSwitch';

export default function HeaderDesktop() {
    const location = useLocation();

    const classes = useStyles();

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.app_header}>
                <Box pl={location.pathname.includes("workspace/") ? '5vw' : location.pathname.includes("workspaces") ? '40px' : '10vw'} display="flex" alignItems="center">
                    <Link to={`/`} style={{ textDecoration: 'none' }}>
                        <Typography className={classes.logo}>socialspace</Typography>
                    </Link>
                </Box>
                {(location.pathname === '/termsofuse' || location.pathname === '/imprint' || location.pathname === '/privacypolicy') ?
                    <></>
                    :
                    <Box display="flex" alignItems="center">
                        <Button
                            component={Link}
                            to={`/workspaces`}
                            variant="contained"
                            color="info">
                            Workspaces
                        </Button>
                        <Button
                            component={Link}
                            to={`/hosts`}
                            variant="contained"
                            color="info"
                            style={{ margin: '0 80px' }}>
                            Hosts
                        </Button>
                        <Button
                            component={Link}
                            to={`/aboutus`}
                            variant="contained"
                            color="info">
                            About us
                        </Button>
                    </Box>
                }
                <Box pr={location.pathname.includes("workspace/") ? '5vw' : location.pathname.includes("workspaces") ? '40px' : '10vw'} display="flex" alignItems="center">
                    {(location.pathname === '/termsofuse' || location.pathname === '/imprint' || location.pathname === '/privacypolicy') ?
                        <></>
                        :
                        <Button
                            // style={{ marginRight: '24px' }}
                            component={Link}
                            to={`/becomehost`}
                            variant="outlined"
                            color="primary">
                            Become a host
                        </Button>
                    }
                    {/* <LanguageSwitch /> */}
                </Box>

            </Box>
        </>


    )
}
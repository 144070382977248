import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './HostBoxMobile.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper';
import { grey800 } from '../../other/helper/MuiStyles';

export default function HostBoxMobile({ host, entryPoint }) {

    const styleProps = {
        partnerImg: IMAGE_MAP.PARTNERS[host.name]
    }

    const classes = useStyles(styleProps);

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" className={classes.host_box}>
            <Box>
                <Box py={4} px={4}>
                    <div className={classes.partner_logo} title="HostLogo"></div>
                </Box>
                <Box px={3} pb={3} display="flex" flexDirection="column">
                    <Typography variant='h6Mobile' className={classes.host_text}>
                        {host.name}
                    </Typography>
                    <Typography variant='body2' style={{ paddingTop: '24px', color: grey800 }}>
                        {host.shortDescription}
                    </Typography>
                </Box>
            </Box>
            {entryPoint === "WorkspacePage" &&
                <Box pl={4} pb={4}>
                    <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={`/host/${host.hostId}`}
                    >
                        Find out more
                    </Button>
                </Box>
            }

        </Box>
    )
}
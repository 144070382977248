import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './DayCalendarBoxMobile.styles';
import { green100, green600, green800, grey1000, grey50, grey600 } from '../../other/helper/MuiStyles';

export default function DayCalendarBoxMobile({ data, handleDayChange, checkIfSelected }) {

    const mondayDays = data.days.filter(object => object.day === "Monday");
    const tuesdayDays = data.days.filter(object => object.day === "Tuesday");
    const wednesdayDays = data.days.filter(object => object.day === "Wednesday");
    const thursdayDays = data.days.filter(object => object.day === "Thursday");
    const fridayDays = data.days.filter(object => object.day === "Friday");
    const saturdayDays = data.days.filter(object => object.day === "Saturday");
    const sundayDays = data.days.filter(object => object.day === "Sunday");

    const classes = useStyles();

    return (
        <Box mb={2} display="flex" justifyContent="space-between" flexGrow={1}>
            <Box display="flex" flexDirection="column" >
                <Typography variant="body3" className={classes.day_name_box}>
                    M
                </Typography>
                {mondayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && mondayDays[0].date > 1) ? 7 : 0} mb={index === (mondayDays.length - 1) ? 0 : 2} display="flex" alignItems="center" justifyContent="center" onClick={() => handleDayChange(option)}>
                            <Typography variant="h6Mobile" className={classes.day_box} style={{ color: checkIfSelected(option) ? grey50 : (option.status === "Available") ? grey1000 : grey600, textDecoration: option.status !== 'Available' ? 'line-through' : '', textDecorationThickness: '2px', textDecorationColor: grey600, cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : '' }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }}>
                                {option.date}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body3" className={classes.day_name_box}>
                    T
                </Typography>
                {tuesdayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && tuesdayDays[0].date > 2) ? 7 : 0} mb={index === (tuesdayDays.length - 1) ? 0 : 2} display="flex" alignItems="center" justifyContent="center" onClick={() => handleDayChange(option)}>
                            <Typography variant="h6Mobile" className={classes.day_box} style={{ color: checkIfSelected(option) ? grey50 : (option.status === "Available") ? grey1000 : grey600, textDecoration: option.status !== 'Available' ? 'line-through' : '', textDecorationThickness: '2px', textDecorationColor: grey600, cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : '' }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }}>
                                {option.date}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body3" className={classes.day_name_box}>
                    W
                </Typography>
                {wednesdayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && wednesdayDays[0].date > 3) ? 7 : 0} mb={index === (wednesdayDays.length - 1) ? 0 : 2} display="flex" alignItems="center" justifyContent="center" onClick={() => handleDayChange(option)}>
                            <Typography variant="h6Mobile" className={classes.day_box} style={{ color: checkIfSelected(option) ? grey50 : (option.status === "Available") ? grey1000 : grey600, textDecoration: option.status !== 'Available' ? 'line-through' : '', textDecorationThickness: '2px', textDecorationColor: grey600, cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : '' }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }}>
                                {option.date}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body3" className={classes.day_name_box}>
                    T
                </Typography>
                {thursdayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && thursdayDays[0].date > 4) ? 7 : 0} mb={index === (thursdayDays.length - 1) ? 0 : 2} display="flex" alignItems="center" justifyContent="center" onClick={() => handleDayChange(option)}>
                            <Typography variant="h6Mobile" className={classes.day_box} style={{ color: checkIfSelected(option) ? grey50 : (option.status === "Available") ? grey1000 : grey600, textDecoration: option.status !== 'Available' ? 'line-through' : '', textDecorationThickness: '2px', textDecorationColor: grey600, cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : '' }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }}>
                                {option.date}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body3" className={classes.day_name_box}>
                    F
                </Typography>
                {fridayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && fridayDays[0].date > 5) ? 7 : 0} mb={index === (fridayDays.length - 1) ? 0 : 2} display="flex" alignItems="center" justifyContent="center" onClick={() => handleDayChange(option)}>
                            <Typography variant="h6Mobile" className={classes.day_box} style={{ color: checkIfSelected(option) ? grey50 : (option.status === "Available") ? grey1000 : grey600, textDecoration: option.status !== 'Available' ? 'line-through' : '', textDecorationThickness: '2px', textDecorationColor: grey600, cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : '' }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }}>
                                {option.date}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body3" className={classes.day_name_box}>
                    S
                </Typography>
                {saturdayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && saturdayDays[0].date > 6) ? 7 : 0} mb={index === (saturdayDays.length - 1) ? 0 : 2} display="flex" alignItems="center" justifyContent="center" onClick={() => handleDayChange(option)}>
                            <Typography variant="h6Mobile" className={classes.day_box} style={{ color: checkIfSelected(option) ? grey50 : (option.status === "Available") ? grey1000 : grey600, textDecoration: option.status !== 'Available' ? 'line-through' : '', textDecorationThickness: '2px', textDecorationColor: grey600, cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : '' }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }}>
                                {option.date}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="body3" className={classes.day_name_box}>
                    S
                </Typography>
                {sundayDays.map((option, index) => {
                    return (
                        <Box key={index} mt={(index === 0 && sundayDays[0].date > 7) ? 7 : 0} mb={index === (sundayDays.length - 1) ? 0 : 2} display="flex" alignItems="center" justifyContent="center" onClick={() => handleDayChange(option)}>
                            <Typography variant="h6Mobile" className={classes.day_box} style={{ color: checkIfSelected(option) ? grey50 : (option.status === "Available") ? grey1000 : grey600, textDecoration: option.status !== 'Available' ? 'line-through' : '', textDecorationThickness: '2px', textDecorationColor: grey600, cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: option.status === "Available" ? (checkIfSelected(option) ? green600 : grey50) : '' }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? checkIfSelected(option) ? `${green800} !important` : `${green100} !important` : '' } }}>
                                {option.date}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}
import React, { useContext } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useStyles } from './SpacesPageMobile.styles';
import { HOMEPAGE_MOBILE } from '../../../data/translation/TranslationHomepageMobile';
import { AppContext } from '../../../components/other/contexts/AppContext';
import { Link } from 'react-router-dom';
import { green1000 } from '../../../components/other/helper/MuiStyles';
import { HOMEPAGE_WORKSPACES } from '../../../data/data';
import WorkspaceBoxMobile from '../../../components/WorkspaceBox/WorkspacesPage/mobile/WorkspaceBoxMobile';
import Icon from '../../../assets/icons/Icon';

export default function SpacesPageMobile() {

    const { language } = useContext(AppContext);

    const classes = useStyles();

    return (
        <>
            <Box mx={3} my={1.5} display="flex" flexDirection="column">
                {HOMEPAGE_WORKSPACES?.sort((a, b) => ['1', '4', '3', '2'].indexOf(a.socialspaceId) - ['1', '4', '3', '2'].indexOf(b.socialspaceId)).map((option) => {
                    return (
                        <Link to={`/workspace/${option.socialspaceId}`} key={option.socialspaceId} className={classes.socialspace_box}>
                            <WorkspaceBoxMobile key={option.socialspaceId} socialspace={option} />
                        </Link>
                    )
                })}
            </Box>

            {/* FOOTER */}
            <Box px={3} pt={4} display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: green1000 }}>
                <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography className={classes.logo}>socialspace</Typography>
                    <Box display="flex" alignItems="center" height='20px'>
                        <IconButton
                            component="a"
                            href="https://instagram.com/socialspacework?igshid=YmMyMTA2M2Y="
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="Instagram" />
                        </IconButton>
                        <IconButton
                            component="a"
                            href="https://twitter.com/socialspacework?s=11&t=U-yVjKdFPsf1bZ9fImbBwA"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="Twitter" />
                        </IconButton>
                        <IconButton
                            component="a"
                            href="https://www.linkedin.com/company/socialspacework/"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="LinkedIn" />
                        </IconButton>
                    </Box>
                </Box>
                <Box pb={3.5} display="flex" flexDirection="column">
                    <Link to={`/termsofuse`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('terms')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/imprint`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('imprint')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/privacypolicy`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('privacy')][language]}
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </>
    )
}
import makeStyles from "@mui/styles/makeStyles";
import { grey50 } from "../../other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    feature: {
        backgroundColor: grey50,
        height: '40px',
        padding: '0 16px 0 12px',
        borderRadius: '8px',
        alignSelf: 'flex-start',
        marginRight: '12px',
        marginTop: '12px',
    },
}));
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useStyles } from './HeaderMobile.styles';
import MobileMenu from '../../other/dialogs/MobileMenu/MobileMenu';
// import LanguageSwitch from '../../other/dialogs/LanguageSwitch/LanguageSwitch';

export default function HeaderMobile() {
    const location = useLocation();

    const classes = useStyles();

    return (
        <>
            {(location.pathname === '/') ?
                <></>
                :
                <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.app_header}>
                    <Box pl={3} display="flex" alignItems="center">
                        <Link to={`/`} style={{ textDecoration: 'none' }}>
                            <Typography className={classes.logo}>socialspace</Typography>
                        </Link>
                    </Box>
                    <Box display="flex" alignItems="center">
                        {/* <Box pr={1} display="flex" alignItems="center">
                            <LanguageSwitch />
                        </Box> */}
                        <Box pr={3} display="flex" alignItems="center">
                            <MobileMenu />
                        </Box>
                    </Box>
                </Box>
            }
        </>


    )
}
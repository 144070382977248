import React, { useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './PrivacyPolicyPage.styles';
import { AppContext } from '../../components/other/contexts/AppContext';
import { PRIVACY_POLICY } from '../../data/translation/TranslationPrivacyPolicy';
import FooterDesktop from '../../components/Footer/desktop/FooterDesktop';
import FooterMobile from '../../components/Footer/mobile/FooterMobile';


export default function PrivacyPolicyPage({ device }) {

    const { language } = useContext(AppContext);

    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Box px={device === "desktop" ? "10vw" : "24px"} py={8} display="flex" flexDirection="column" className={classes.container}>
                <Typography variant={device === "desktop" ? "h2" : "h2Mobile"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title')][language]}
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    1. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_1')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_1')][language]}
                </Typography>
                <Typography mt={3} variant={device === "desktop" ? "body1" : "body2"}>
                    Phillip Dürner
                </Typography>
                <Typography variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('phillip_1')][language]}
                </Typography>
                <Typography variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('phillip_2')][language]}
                </Typography>
                <Typography mt={3} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('contact')][language]}:
                </Typography>
                <Typography variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('phone')][language]}
                </Typography>
                <Typography variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('mail')][language]}<a href="mailto:socialspace.work.impact@gmail.com" className={classes.link}>socialspace.work.impact@gmail.com</a>
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    2. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_2')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_2')][language]}
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    3. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_3')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_3')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    3.1 {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_3_1')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_3_1')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    3.2 {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_3_2')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_3_2')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    3.3 {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_3_3')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_3_3')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    3.4 {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_3_4')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_3_4')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    3.5 {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_3_5')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_3_5_1')][language]}
                </Typography>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_3_5_2')][language]}
                    </Typography>
                </Box>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_3_5_3')][language]}
                    </Typography>
                </Box>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_3_5_4')][language]}
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    4. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_4')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_4_1')][language]}
                </Typography>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_4_2')][language]}
                    </Typography>
                </Box>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_4_3')][language]}
                    </Typography>
                </Box>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_4_4')][language]}
                    </Typography>
                </Box>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_4_5')][language]}
                    </Typography>
                </Box>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    5. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_5')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_5')][language]}
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    6. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_6')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_6_1')][language]}
                </Typography>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_6_2')][language]}
                    </Typography>
                </Box>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_6_3')][language]}
                    </Typography>
                </Box>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_6_4')][language]}
                    </Typography>
                </Box>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_6_5')][language]}
                    </Typography>
                </Box>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_6_6')][language]}
                    </Typography>
                </Box>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_6_7')][language]}
                    </Typography>
                </Box>
                <Box mt={1} display="flex">
                    <Typography variant={device === "desktop" ? "body1" : "body2"} className={classes.bullet_point}>
                        -
                    </Typography>
                    <Typography variant={device === "desktop" ? "body1" : "body2"}>
                        {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_6_8')][language]}
                    </Typography>
                </Box>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    7. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_7')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_7_1')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_7_2')][language]}
                    <a href="mailto:socialspace.work.impact@gmail.com" className={classes.link}>socialspace.work.impact@gmail.com</a>.
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    8. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_8')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_8_1')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_8_2')][language]}
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    9. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_9')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_9_1')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_9_2')][language]}
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    10. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_10')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_10_1')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_10_2')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_10_3')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_10_4')][language]}
                    <a href="https://www.google.com/fonts#AboutPlace:about" target="_blank" rel="noopener noreferrer" className={classes.link}> https://www.google.com/fonts#AboutPlace:about</a>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_10_5')][language]}
                    <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer" className={classes.link}> https://policies.google.com/technologies/partner-sites</a>.
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    11. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_11')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    11.1 {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_11_1')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_11_1_1')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_11_1_2')][language]}
                    <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer" className={classes.link}> https://www.linkedin.com/legal/privacy-policy</a>.
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    11.2 {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_11_2')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_11_2_1')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_11_2_2')][language]}
                    <a href="https://instagram.com/about/legal/privacy/" target="_blank" rel="noopener noreferrer" className={classes.link}> https://instagram.com/about/legal/privacy/</a>.
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    11.3 {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_11_3')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_11_3_1')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_11_3_2')][language]}
                    <a href="https://twitter.com/privacy" target="_blank" rel="noopener noreferrer" className={classes.link}> https://twitter.com/privacy</a>.
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    12. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_12')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_12')][language]}
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    13. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_13')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_13')][language]}
                </Typography>
                <Typography mt={5} variant={device === "desktop" ? "body1" : "body2"} className={classes.headline}>
                    14. {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('title_14')][language]}
                </Typography>
                <Typography mt={1} variant={device === "desktop" ? "body1" : "body2"}>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_14_1')][language]}
                    <a href="https://socialspace.work/privacypolicy" target="_blank" rel="noopener noreferrer" className={classes.link}> https://socialspace.work/privacypolicy</a>
                    {PRIVACY_POLICY[PRIVACY_POLICY.map(object => object.section).indexOf('text_14_2')][language]}
                </Typography>
            </Box>

            {device === "desktop" ? <FooterDesktop /> : <FooterMobile />}
        </>
    )
}
import Homepage_Picture from '../../../assets/Homepage_Picture.jpg';
import Homepage_Picture_Mobile from '../../../assets/Homepage_Picture_Mobile.jpg';
import Hosts_Header from '../../../assets/hosts_header.jpg';
import amandla from '../../../assets/amandla.jpg';
import sociallandscaping from '../../../assets/sociallandscaping.png';
import amandla_header from '../../../assets/amandla_header.jpg';
import sociallandscaping_header from '../../../assets/sociallandscaping_header.jpg';
import Phillip from '../../../assets/Phillip.png';
import Journey from '../../../assets/journey.svg';
import Spaces from '../../../assets/spaces.svg';
import Mission from '../../../assets/mission.svg';
import Step1 from '../../../assets/search.svg';
import Step2 from '../../../assets/book.svg';
import Step3 from '../../../assets/work.svg';
import MacPreview from '../../../assets/MacPreview.svg';
import RoadtripIllustration from '../../../assets/Roadtrip.svg';
import ProcessIllustration from '../../../assets/ProcessIllustration.svg';
import CriteriaIllustration from '../../../assets/CriteriaIllustration.svg';
import BenefitsIllustration from '../../../assets/BenefitsIllustration.svg';
import ContactFormIllustration from '../../../assets/ContactFormIllustration.svg';
import PhotoIllustration from '../../../assets/PhotoIllustration.svg';
import ReviewIllustration from '../../../assets/ReviewIllustration.svg';
import SupportIllustration from '../../../assets/SupportIllustration.svg';
import AssistanceIllustration from '../../../assets/AssistanceIllustration.svg';
import GuidanceIllustration from '../../../assets/GuidanceIllustration.svg';
import ProvisionIllustration from '../../../assets/ProvisionIllustration.svg';
import Team from '../../../assets/Team.jpg';
import Mountain from '../../../assets/Mountain.jpg';
import Amandla_Office1_Preview from '../../../assets/offices/amandla/1_7Desktop.jpg';
import Amandla_Office2_Preview from '../../../assets/offices/amandla/1_2Desktop.jpg';
import Amandla_Office3_Preview from '../../../assets/offices/amandla/1_3Desktop.jpg';
import Amandla_Office1_1Mobile from '../../../assets/offices/amandla/1_1Desktop.jpg';
import Amandla_Office1_2Mobile from '../../../assets/offices/amandla/1_2Desktop.jpg';
import Amandla_Office1_3Mobile from '../../../assets/offices/amandla/1_3Desktop.jpg';
import Amandla_Office1_4Mobile from '../../../assets/offices/amandla/1_4Desktop.jpg';
import Amandla_Office1_5Mobile from '../../../assets/offices/amandla/1_5Desktop.jpg';
import Amandla_Office1_6Mobile from '../../../assets/offices/amandla/1_6Desktop.jpg';
import Amandla_Office1_7Mobile from '../../../assets/offices/amandla/1_7Desktop.jpg';
import Amandla_Office1_1Desktop from '../../../assets/offices/amandla/1_1Desktop.jpg';
import Amandla_Office1_2Desktop from '../../../assets/offices/amandla/1_2Desktop.jpg';
import Amandla_Office1_3Desktop from '../../../assets/offices/amandla/1_3Desktop.jpg';
import Amandla_Office1_4Desktop from '../../../assets/offices/amandla/1_4Desktop.jpg';
import Amandla_Office1_5Desktop from '../../../assets/offices/amandla/1_5Desktop.jpg';
import Amandla_Office1_6Desktop from '../../../assets/offices/amandla/1_6Desktop.jpg';
import Amandla_Office1_7Desktop from '../../../assets/offices/amandla/1_7Desktop.jpg';
import Amandla_Office3_1Desktop from '../../../assets/offices/amandla/3_1Desktop.jpg';
import Amandla_Office3_2Desktop from '../../../assets/offices/amandla/3_2Desktop.jpg';
import Amandla_Office3_3Desktop from '../../../assets/offices/amandla/3_3Desktop.jpg';
import Amandla_Office3_4Desktop from '../../../assets/offices/amandla/3_4Desktop.jpg';
import Amandla_Office3_5Desktop from '../../../assets/offices/amandla/3_5Desktop.jpg';
import SocialLandscaping_Office1_Desktop from '../../../assets/offices/sociallandscaping/2_1Desktop.jpg';
import SocialLandscaping_Office2_Desktop from '../../../assets/offices/sociallandscaping/2_2Desktop.jpg';
import SocialLandscaping_Office3_Desktop from '../../../assets/offices/sociallandscaping/2_3Desktop.jpg';
import SocialLandscaping_Office1_Mobile from '../../../assets/offices/sociallandscaping/2_1Mobile.jpg';
import SocialLandscaping_Office2_Mobile from '../../../assets/offices/sociallandscaping/2_2Mobile.jpg';
import SocialLandscaping_Office3_Mobile from '../../../assets/offices/sociallandscaping/2_3Mobile.jpg';
import SocialLandscaping_Office4_Mobile from '../../../assets/offices/sociallandscaping/2_4Mobile.jpg';
import SocialLandscaping_Office5_Mobile from '../../../assets/offices/sociallandscaping/2_5Mobile.jpg';
import SocialLandscaping_Office6_Mobile from '../../../assets/offices/sociallandscaping/2_6Mobile.jpg';
import SocialLandscaping_Office7_Mobile from '../../../assets/offices/sociallandscaping/2_7Mobile.jpg';
import Jasmina from '../../../assets/Jasmina.jpg';
import Ilonka from '../../../assets/Ilonka.jpg';
import Georg from '../../../assets/Georg.png';
import HotDeskIllustration from '../../../assets/HotDeskIllustration.svg';
import PrivateDeskIllustration from '../../../assets/PrivateDeskIllustration.svg';
import PrivateOfficeIllustration from '../../../assets/PrivateOfficeIllustration.svg';
import LighthouseIllustration from '../../../assets/LighthouseIllustration.svg';
import Amandla1 from '../../../assets/hosts/amandla/Amandla1.jpg';
import Amandla2 from '../../../assets/hosts/amandla/Amandla2.jpg';
import Amandla3 from '../../../assets/hosts/amandla/Amandla3.jpg';
import Amandla4 from '../../../assets/hosts/amandla/Amandla4.jpg';
import Amandla5 from '../../../assets/hosts/amandla/Amandla5.jpg';
import Amandla6 from '../../../assets/hosts/amandla/Amandla6.jpg';
import Amandla7 from '../../../assets/hosts/amandla/Amandla7.jpg';
import SocialLandscaping1 from '../../../assets/hosts/sociallandscaping/SocialLandscaping1.jpg';
import SocialLandscaping2 from '../../../assets/hosts/sociallandscaping/SocialLandscaping2.jpg';
import SocialLandscaping3 from '../../../assets/hosts/sociallandscaping/SocialLandscaping3.jpg';
import SocialLandscaping4 from '../../../assets/hosts/sociallandscaping/SocialLandscaping4.jpg';
import SocialLandscaping5 from '../../../assets/hosts/sociallandscaping/SocialLandscaping5.jpg';

// min 700 px for office pictures
export const IMAGE_MAP = {
    HOST_PICTURES: {
        '1_1': Amandla1,
        '1_2': Amandla2,
        '1_3': Amandla3,   
        '1_4': Amandla4,
        '1_5': Amandla5,
        '1_6': Amandla6,  
        '1_7': Amandla7, 
        '2_1': SocialLandscaping1,
        '2_2': SocialLandscaping2,
        '2_3': SocialLandscaping3,   
        '2_4': SocialLandscaping4,
        '2_5': SocialLandscaping5,
    },
    ILLUSTRATIONS: {
        AssistanceIllustration: AssistanceIllustration,
        BenefitsIllustration: BenefitsIllustration,
        ContactFormIllustration: ContactFormIllustration,
        CriteriaIllustration: CriteriaIllustration,
        GuidanceIllustration: GuidanceIllustration,
        PhotoIllustration: PhotoIllustration,
        ProcessIllustration: ProcessIllustration,
        ProvisionIllustration: ProvisionIllustration,
        ReviewIllustration: ReviewIllustration,
        RoadtripIllustration: RoadtripIllustration,
        SupportIllustration: SupportIllustration,
        'Hot Desk': HotDeskIllustration,
        'Private Desk': PrivateDeskIllustration,
        'Private Office': PrivateOfficeIllustration,
        LighthouseIllustration: LighthouseIllustration,
    },
    OFFICE_PREVIEW: {
        '1': Amandla_Office1_Preview,
        '2': Amandla_Office2_Preview,
        '3': Amandla_Office3_Preview, 
        '4': SocialLandscaping_Office1_Desktop,
        '5': Amandla_Office3_1Desktop,       
    },
    OFFICE_DETAIL_MOBILE: {
        '1_1': Amandla_Office1_1Mobile,
        '1_2': Amandla_Office1_2Mobile,
        '1_3': Amandla_Office1_3Mobile,   
        '1_4': Amandla_Office1_4Mobile,
        '1_5': Amandla_Office1_5Mobile,
        '1_6': Amandla_Office1_6Mobile,  
        '1_7': Amandla_Office1_7Mobile,  
        '2_1': SocialLandscaping_Office1_Mobile,  
        '2_2': SocialLandscaping_Office2_Mobile,    
        '2_3': SocialLandscaping_Office3_Mobile,   
        '2_4': SocialLandscaping_Office4_Mobile,  
        '2_5': SocialLandscaping_Office5_Mobile, 
        '2_6': SocialLandscaping_Office6_Mobile, 
        '2_7': SocialLandscaping_Office7_Mobile, 
        '3_1': Amandla_Office3_1Desktop,
        '3_2': Amandla_Office3_2Desktop,
        '3_3': Amandla_Office3_3Desktop,
        '3_4': Amandla_Office3_4Desktop,
        '3_5': Amandla_Office3_5Desktop,
    },
    OFFICE_DETAIL_DESKTOP: {
        '1_1': Amandla_Office1_1Desktop,
        '1_2': Amandla_Office1_2Desktop,
        '1_3': Amandla_Office1_3Desktop,   
        '1_4': Amandla_Office1_4Desktop,
        '1_5': Amandla_Office1_5Desktop,
        '1_6': Amandla_Office1_6Desktop,  
        '1_7': Amandla_Office1_7Desktop,
        '2_1': SocialLandscaping_Office1_Desktop,     
        '2_2': SocialLandscaping_Office2_Desktop,   
        '2_3': SocialLandscaping_Office3_Desktop,
        '3_1': Amandla_Office3_1Desktop,
        '3_2': Amandla_Office3_2Desktop,
        '3_3': Amandla_Office3_3Desktop,

    },
    PARTNERS: {        
        'AMANDLA gGmbH': amandla,
        'Social Landscaping e.V.': sociallandscaping,
    },
    PARTNER_HEADER: {
        'AMANDLA gGmbH': amandla_header,
        'Social Landscaping e.V.': sociallandscaping_header,
    },
    GENERAL: {
        Homepage_Picture: Homepage_Picture,
        Homepage_Picture_Mobile: Homepage_Picture_Mobile,
        Phillip: Phillip,
        'Jasmina Srna': Jasmina,
        'Georg Höfer': Georg,
        'Ilonka Grützmacher': Ilonka,
        Hosts_Header: Hosts_Header,
        Journey: Journey,
        Spaces: Spaces,
        Mission: Mission,
        Step1: Step1,
        Step2: Step2,
        Step3: Step3,
        MacPreview: MacPreview,
        Team: Team,
        Mountain: Mountain,
    },
}
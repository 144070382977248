import React, { useEffect } from 'react';
import { Grid, Paper, Box, Typography, Button } from '@mui/material';
import { useStyles } from './BecomeHostPageDesktop.styles';
import { grey1000, green50, green600, green1000, grey800, green200, green100 } from '../../../components/other/helper/MuiStyles';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import Icon from '../../../assets/icons/Icon';
import { HashLink } from 'react-router-hash-link';
import FooterDesktop from '../../../components/Footer/desktop/FooterDesktop';
import EmailRegistration from '../../../components/other/dialogs/EmailRegistration/EmailRegistration';

export default function BecomeHostPageDesktop() {

    const horizontalMargin = '10vw';

    const styleProps = {
        backgroundImgRoadtrip: IMAGE_MAP.ILLUSTRATIONS['Roadtrip'],
        backgroundImgBenefitsIllustration: IMAGE_MAP.ILLUSTRATIONS['BenefitsIllustration'],
        backgroundImgProcessIllustration: IMAGE_MAP.ILLUSTRATIONS['ProcessIllustration'],
        backgroundImgCriteriaIllustration: IMAGE_MAP.ILLUSTRATIONS['CriteriaIllustration'],
        backgroundImgContactFormIllustration: IMAGE_MAP.ILLUSTRATIONS['ContactFormIllustration'],
        backgroundImgPhotoIllustration: IMAGE_MAP.ILLUSTRATIONS['PhotoIllustration'],
        backgroundImgReviewIllustration: IMAGE_MAP.ILLUSTRATIONS['ReviewIllustration'],
        backgroundImgSupportIllustration: IMAGE_MAP.ILLUSTRATIONS['SupportIllustration'],
        backgroundImgAssistanceIllustration: IMAGE_MAP.ILLUSTRATIONS['AssistanceIllustration'],
        backgroundImgGuidanceIllustration: IMAGE_MAP.ILLUSTRATIONS['GuidanceIllustration'],
        backgroundImgProvisionIllustration: IMAGE_MAP.ILLUSTRATIONS['ProvisionIllustration'],
        backgroundImgMacPreview: IMAGE_MAP.GENERAL['MacPreview'],
    };

    const classes = useStyles(styleProps);
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <Grid item xs={12} display="grid">
            <Paper className={classes.paper}>

                {/* Welcome to socialspace */}
                <Grid container style={{ minHeight: 'calc(100vh - 80px)' }}>
                    <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                        <Box ml={horizontalMargin} py={8} display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
                            <Typography pr={8} variant='h1' style={{ color: grey1000 }}>
                                Welcome to socialspace!
                            </Typography>
                            <Typography pr={8} variant='body1' style={{ marginTop: '24px', color: grey1000 }}>
                                At socialspace, our goal is to create a vibrant community of hosts and guests who are committed to fostering collaboration, innovation, and social impact. Thank you for considering joining us as a host. We're excited to have you on board and look forward to supporting you on this journey.
                            </Typography>
                            <Box mt={5} display="flex">
                                <EmailRegistration />
                            </Box>
                            <HashLink smooth to='/becomehost/#benefits' style={{ textDecoration: 'none' }}>
                                <Box mt={7} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        01
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Benefits of joining socialspace
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                            <HashLink smooth to='/becomehost/#process' style={{ textDecoration: 'none' }}>
                                <Box mt={'16px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        02
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Process of listing workspaces on socialspace
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                            <HashLink smooth to='/becomehost/#criteria' style={{ textDecoration: 'none' }}>
                                <Box mt={'16px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        03
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Host qualification criteria
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                            <HashLink smooth to='/becomehost/#support' style={{ textDecoration: 'none' }}>
                                <Box mt={'16px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        04
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Support for hosts
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                        </Box>
                    </Grid>

                    <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                        <div className={classes.mac_preview} title="MacPreview"></div>
                    </Grid>

                </Grid>

                {/* BENEFITS OF JOINING SOCIALSPACE */}
                <Grid id="benefits" container style={{ backgroundColor: green50, minHeight: 'calc(100vh - 80px)' }}>
                    <Grid item xs={7} display="flex" alignItems="center" justifyContent="center">
                        <Box ml={horizontalMargin} py={8} display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
                            <Typography pr={8} variant='h1' style={{ color: grey1000 }}>
                                Benefits of joining socialspace
                            </Typography>
                            <Typography pr={8} variant='body1' style={{ marginTop: '24px', color: grey1000 }}>
                                By joining us as a host, you're opening your doors to a world of collaboration, sustainable impact, and shared growth. Let's take a moment to explore the benefits of hosting with socialspace and how your organisation can thrive by connecting with our community of coworkers, SMMEs, and NGOs.
                            </Typography>
                            <HashLink smooth to='/becomehost/#benefits_hosting' style={{ textDecoration: 'none' }}>
                                <Box mt={'56px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        01
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Benefits of hosting with socialspace
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                            <HashLink smooth to='/becomehost/#benefits_guests' style={{ textDecoration: 'none' }}>
                                <Box mt={'16px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        02
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Benefits of connecting with guests
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                        </Box>
                    </Grid>

                    <Grid item xs={5} display="flex" alignItems="center" justifyContent="center">
                        <div className={classes.benefits_illustration} title="BenefitsIllustration"></div>
                    </Grid>

                </Grid>

                {/* 01 BENEFITS OF HOSTING WITH SOCIALSPACE */}
                <Box id="benefits_hosting" px={horizontalMargin} display="flex" flexDirection="column" style={{ backgroundColor: green50 }}>
                    <Box display="flex" alignItems="flex-start" style={{ borderTop: `2px solid ${green200}` }} >
                        <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                            01
                        </Typography>
                        <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                            Benefits of hosting with socialspace
                        </Typography>
                    </Box>
                    <Grid mt={8} container>
                        <Grid item xs={6}>
                            <Box pr={5} display="flex" flexDirection="column">
                                <Icon width={56} height={56} iconName="DollarCircle" fill={green1000} />
                                <Typography mt={2} variant="h6">Generate revenue</Typography>
                                <Typography mt={2} variant="body1" style={{ color: grey800 }}>Make the most of your unused or underutilized workspaces by offering them to our community. It's a win-win situation – you can support other organisations while generating additional income to fund your own initiatives.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box pl={5} display="flex" flexDirection="column">
                                <Icon width={56} height={56} iconName="StatusUp" fill={green1000} />
                                <Typography mt={2} variant="h6">Enhance your reputation</Typography>
                                <Typography mt={2} variant="body1" style={{ color: grey800 }}>Showcase your organisation's commitment to fostering sustainability by utilising underutilised workspaces & opening your doors to a community dedicated to empowering NGOs, not-for-profit organisations, and other purpose-driven entities.</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid mt={5} pb={12} container >
                        <Grid item xs={6}>
                            <Box pr={5} display="flex" flexDirection="column">
                                <Icon width={56} height={56} iconName="ElementPlus" fill={green1000} />
                                <Typography mt={2} variant="h6">Expand your network</Typography>
                                <Typography mt={2} variant="body1" style={{ color: grey800 }}>Hosting on socialspace enables you to meet passionate individuals and organisations, fostering new relationships and collaborations that can fuel your organisation's mission and growth.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box pl={5} display="flex" flexDirection="column">
                                <Icon width={56} height={56} iconName="Gift" fill={green1000} />
                                <Typography mt={2} variant="h6">Access valuable resources</Typography>
                                <Typography mt={2} variant="body1" style={{ color: grey800 }}>As a socialspace host, you'll have access to our tools, templates, and guidance to help you optimize your hosting experience and operations.</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {/* 02 BENEFITS OF CONNECTING WITH GUESTS */}
                <Box id="benefits_guests" px={horizontalMargin} display="flex" flexDirection="column" style={{ backgroundColor: green50 }}>
                    <Box display="flex" alignItems="flex-start" style={{ borderTop: `2px solid ${green200}` }} >
                        <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                            02
                        </Typography>
                        <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                            Benefits of connecting with guests
                        </Typography>
                    </Box>
                    <Grid mt={8} container>
                        <Grid item xs={6}>
                            <Box pr={5} display="flex" flexDirection="column">
                                <Icon width={56} height={56} iconName="GlobalSearch" fill={green1000} />
                                <Typography mt={2} variant="h6">Diverse perspectives</Typography>
                                <Typography mt={2} variant="body1" style={{ color: grey800 }}>By opening your workspace to coworkers, SMMEs, and NGOs, you'll be exposed to a wealth of fresh ideas and different perspectives, sparking innovation and creativity within your organisation.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box pl={5} display="flex" flexDirection="column">
                                <Icon width={56} height={56} iconName="Share" fill={green1000} />
                                <Typography mt={2} variant="h6">Cross-sector collaboration</Typography>
                                <Typography mt={2} variant="body1" style={{ color: grey800 }}>Connections made through socialspace can lead to new projects and partnerships across various sectors, driving social change and impact in unique and powerful ways.</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid mt={5} pb={12} container >
                        <Grid item xs={6}>
                            <Box pr={5} display="flex" flexDirection="column">
                                <Icon width={56} height={56} iconName="ConvertShape" fill={green1000} />
                                <Typography mt={2} variant="h6">Skill-sharing and learning</Typography>
                                <Typography mt={2} variant="body1" style={{ color: grey800 }}>By sharing your workspace, you also have the opportunity to exchange knowledge, expertise, and skills with other socialspace members, fostering a culture of continuous learning and development.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box pl={5} display="flex" flexDirection="column">
                                <Icon width={56} height={56} iconName="ShieldTick" fill={green1000} />
                                <Typography mt={2} variant="h6">Strengthen the social impact ecosystem</Typography>
                                <Typography mt={2} variant="body1" style={{ color: grey800 }}>By hosting on socialspace, you are actively contributing to the growth and sustainability of the social impact ecosystem, supporting the development of initiatives and organisations that make a positive difference in our world.</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>


                {/* PROCESS OF LISTING WORKSPACES */}
                <Grid id="process" container style={{ minHeight: 'calc(100vh - 80px)' }}>
                    <Grid item xs={7} display="flex" alignItems="center" justifyContent="center">
                        <Box ml={horizontalMargin} py={8} pr={8} display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
                            <Typography variant='h1' style={{ color: grey1000 }}>
                                Process of listing workspaces on socialspace
                            </Typography>
                            <Typography variant='body1' style={{ marginTop: '24px', color: grey1000 }}>
                                We understand that you're excited to share your workspace with our community, and we're here to guide you through the process step by step. By following these simple steps, you'll be on your way to becoming a successful socialspace host.
                            </Typography>
                            <Box mt={3} display="flex">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                >
                                    Contact us
                                </Button>
                            </Box>
                            <HashLink smooth to='/becomehost/#process_contact_form' style={{ textDecoration: 'none' }}>
                                <Box mt={'56px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        01
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Complete a contact form
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                            <HashLink smooth to='/becomehost/#process_photos' style={{ textDecoration: 'none' }}>
                                <Box mt={'16px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        02
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Submit workspace photos
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                            <HashLink smooth to='/becomehost/#process_review' style={{ textDecoration: 'none' }}>
                                <Box mt={'16px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        03
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Review and approval
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                            <HashLink smooth to='/becomehost/#process_disclaimer' style={{ textDecoration: 'none' }}>
                                <Box mt={'16px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        04
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Disclaimer
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                        </Box>
                    </Grid>

                    <Grid item xs={5} display="flex" alignItems="center" justifyContent="center">
                        <div className={classes.process_illustration} title="ProcessIllustration"></div>
                    </Grid>

                </Grid>

                {/* 01 COMPLETE A CONTRACT FORM */}
                <Grid id="process_contact_form" px={horizontalMargin} container >
                    <Grid item xs={8} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >

                        <Box display="flex" flexDirection="column">
                            <Box display="flex" alignItems="flex-start" >
                                <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                                    01
                                </Typography>
                                <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                                    Complete a contact form
                                </Typography>
                            </Box>
                            <Box pr={'10vw'} mb={12} display="flex" flexDirection="column">
                                <Box mt={8} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Verify" fill={green1000} />
                                    </Box>
                                    <p className={classes.process_step_description}>
                                        <span style={{ fontFamily: 'Poppins600', margin: '0 8px 0 0' }}>Provide personal and organisational information:</span>
                                        Tell us a bit about yourself and your organisation. We're keen to learn more about your mission, goals, and the kind of workspace you offer.
                                    </p>
                                </Box>
                                <Box mt={4} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Verify" fill={green1000} />
                                    </Box>
                                    <p className={classes.process_step_description}>
                                        <span style={{ fontFamily: 'Poppins600', margin: '0 8px 0 0' }}>Share information about the workspace:</span>
                                        Tell us a bit about yourself and your organisation. We're keen to learn more about your mission, goals, and the kind of workspace you offer.
                                    </p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >
                        <div className={classes.contact_form_illustration} title="ContactFormIllustration"></div>
                    </Grid>

                </Grid>

                {/* 02 SUBMIT WORKSPACE PHOTOS */}
                <Grid id="process_photos" px={horizontalMargin} container >
                    <Grid item xs={8} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >

                        <Box display="flex" flexDirection="column">
                            <Box display="flex" alignItems="flex-start">
                                <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                                    02
                                </Typography>
                                <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                                    Submit workspace photos
                                </Typography>
                            </Box>
                            <Box pr={'10vw'} mb={12} display="flex" flexDirection="column">
                                <Box mt={8} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Verify" fill={green1000} />
                                    </Box>
                                    <p className={classes.process_step_description}>
                                        <span style={{ fontFamily: 'Poppins600', margin: '0 8px 0 0' }}>Upload high-quality images showcasing the workspace:</span>
                                        A picture is worth a thousand words! Help potential guests visualize your workspace by providing clear, well-lit photos that highlight its best features and give a sense of the overall atmosphere.
                                    </p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >
                        <div className={classes.photo_illustration} title="PhotoIllustration"></div>
                    </Grid>

                </Grid>

                {/* 03 REVIEW AND APPROVAL */}
                <Grid id="process_review" px={horizontalMargin} container >
                    <Grid item xs={8} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >
                        <Box display="flex" flexDirection="column">
                            <Box display="flex" alignItems="flex-start">
                                <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                                    03
                                </Typography>
                                <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                                    Review and approval
                                </Typography>
                            </Box>
                            <Box pr={'10vw'} mb={12} display="flex" flexDirection="column">
                                <Box mt={8} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Verify" fill={green1000} />
                                    </Box>
                                    <p className={classes.process_step_description}>
                                        <span style={{ fontFamily: 'Poppins600', margin: '0 8px 0 0' }}>socialspace to review and approve the listing request based on the qualification criteria:</span>
                                        Our team will carefully review your submission, ensuring that it meets our guidelines for not-for-profit organisations, non-profit associations, and overall suitability for our community.
                                    </p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >
                        <div className={classes.review_illustration} title="ReviewIllustration"></div>
                    </Grid>

                </Grid>

                {/* 04 DISCLAIMER */}
                <Grid id="process_disclaimer" px={horizontalMargin} container >
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >
                        <Box display="flex" flexDirection="column">
                            <Box display="flex" alignItems="flex-start">
                                <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                                    04
                                </Typography>
                                <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                                    Disclaimer
                                </Typography>
                            </Box>
                            <Box pr={'15vw'} mb={12} display="flex" flexDirection="column">
                                <Box mt={8} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Information" fill={green1000} />
                                    </Box>
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="body1">As socialspace is currently in its prototype test phase, we kindly ask for your understanding if we cannot list your workspace immediately. We're working diligently to ensure a seamless experience for both hosts and guests as we grow our platform.</Typography>
                                        <Typography mt={2} variant="body1">We apologize for any inconvenience during the onboarding process and appreciate your patience. Rest assured, we are committed to improving and automating our onboarding process in the future. Your feedback is invaluable in helping us achieve this goal. Thank you for joining us on this exciting journey and for contributing to the success of the socialspace community.</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                {/* HOST QUALIFICATION CRITERIA */}
                <Grid id="criteria" container style={{ backgroundColor: green50, minHeight: 'calc(100vh - 80px)' }}>
                    <Grid item xs={7} display="flex" alignItems="center" justifyContent="center">
                        <Box ml={horizontalMargin} py={8} pr={8} display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
                            <Typography variant='h1' style={{ color: grey1000 }}>
                                Host qualification criteria
                            </Typography>
                            <Typography variant='body1' style={{ marginTop: '24px', color: grey1000 }}>
                                At socialspace, we take pride in fostering a community of like-minded organisations that share our values and vision. To maintain this high standard, we have established a set of qualification criteria for hosts. We look for hosts that demonstrate a strong commitment to our mission of fostering collaboration and sustainability. We seek organisations that value innovation, sustainability, and community-building as much as we do.
                            </Typography>
                            <HashLink smooth to='/becomehost/#criteria_npo' style={{ textDecoration: 'none' }}>
                                <Box mt={'56px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        01
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Not-for-profit organisations and non-profit associations
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                            <HashLink smooth to='/becomehost/#criteria_disclaimer' style={{ textDecoration: 'none' }}>
                                <Box mt={'16px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        02
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Disclaimer
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                        </Box>
                    </Grid>

                    <Grid item xs={5} display="flex" alignItems="center" justifyContent="center">
                        <div className={classes.criteria_illustration} title="CriteriaIllustration"></div>
                    </Grid>

                </Grid>

                {/* 01 NPO AND CHARITABLE ORGANIZATIONS */}
                <Box id="criteria_npo" px={horizontalMargin} display="flex" flexDirection="column" style={{ backgroundColor: green50 }}>
                    <Box display="flex" alignItems="flex-start" style={{ borderTop: `2px solid ${green200}` }} >
                        <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                            01
                        </Typography>
                        <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                            Not-for-profit organisations and non-profit associations
                        </Typography>
                    </Box>
                    <Grid mt={8} pb={12} container>
                        <Grid item xs={6}>
                            <Box pr={5} display="flex" flexDirection="column">
                                <Icon width={56} height={56} iconName="MedalStar" fill={green1000} />
                                <Typography mt={2} variant="h6">Not-for-profit organisations and companies</Typography>
                                <p className={classes.process_step_description} style={{ marginTop: '16px' }}>
                                    A not-for-profit limited liability company in Germany, operating with the primary purpose of pursuing public-benefit objectives rather than generating profits for its owners. This could be for example a
                                    <span style={{ fontFamily: 'Poppins600', margin: '0 0 0 8px' }}>Gemeinnützige Gesellschaft mit beschränkter Haftung (gGmbH)</span>.
                                </p>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box pl={5} display="flex" flexDirection="column">
                                <Icon width={56} height={56} iconName="HeartTick" fill={green1000} />
                                <Typography mt={2} variant="h6">Non-profit associations</Typography>
                                <p className={classes.process_step_description} style={{ marginTop: '16px' }}>
                                    A registered association in Germany that operates on a non-profit basis and primarily serves public-benefit, non-profit, or members' cultural interests. This could be for example a
                                    <span style={{ fontFamily: 'Poppins600', margin: '0 0 0 8px' }}>Eingetragener Verein (e.V.)</span>.
                                </p>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {/* 02 DISCLAIMER */}
                <Grid id="criteria_disclaimer" px={horizontalMargin} container style={{ backgroundColor: green50 }}>
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >


                        <Box display="flex" flexDirection="column">
                            <Box display="flex" alignItems="flex-start">
                                <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                                    02
                                </Typography>
                                <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                                    Disclaimer
                                </Typography>
                            </Box>
                            <Box pr={'15vw'} mb={12} display="flex" flexDirection="column">
                                <Box mt={8} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Information" fill={green1000} />
                                    </Box>
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="body1">Please note that as part of the onboarding process, you may be asked to provide evidence or proof of your non-profit status to ensure compliance with our qualification criteria. We appreciate your cooperation in maintaining the integrity of the socialspace community.</Typography>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>

                {/* SUPPORT FOR HOSTS */}
                <Grid id="support" container style={{ minHeight: 'calc(100vh - 80px)' }}>
                    <Grid item xs={7} display="flex" alignItems="center" justifyContent="center">
                        <Box ml={horizontalMargin} py={8} pr={8} display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
                            <Typography variant='h1' style={{ color: grey1000 }}>
                                Support for hosts
                            </Typography>
                            <Typography variant='body1' style={{ marginTop: '24px', color: grey1000 }}>
                                At socialspace, we're committed to helping our hosts make the most of their listing and ensuring a seamless experience for both hosts and guests. To help you get started and manage your hosting journey effectively, we offer the following support:
                            </Typography>
                            <HashLink smooth to='/becomehost/#support_assistance' style={{ textDecoration: 'none' }}>
                                <Box mt={'56px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        01
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Assistance with creating attractive profiles
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                            <HashLink smooth to='/becomehost/#support_guidance' style={{ textDecoration: 'none' }}>
                                <Box mt={'16px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        02
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Guidance on workspace operations
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                            <HashLink smooth to='/becomehost/#support_templates' style={{ textDecoration: 'none' }}>
                                <Box mt={'16px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        03
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Provision of templates and tools for effective hosting
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                            <HashLink smooth to='/becomehost/#support_disclaimer' style={{ textDecoration: 'none' }}>
                                <Box mt={'16px'} display="flex" alignItems="flex-start">
                                    <Typography variant='h6' style={{ minWidth: '30px', color: green600 }}>
                                        04
                                    </Typography>
                                    <Typography pl={1.5} variant='h6' style={{ color: green1000, marginRight: '8px' }}>
                                        Disclaimer
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '26px', minHeight: '26px' }}>
                                        <Icon width={24} height={24} iconName="ArrowRight" fill={green1000} />
                                    </Box>
                                </Box>
                            </HashLink>
                        </Box>
                    </Grid>

                    <Grid item xs={5} display="flex" alignItems="center" justifyContent="center">
                        <div className={classes.support_illustration} title="SupportIllustration"></div>
                    </Grid>

                </Grid>

                {/* 01 ASSISTANCE WITH CREATING ATTRATCIVE PROFILES */}
                <Grid id="support_assistance" px={horizontalMargin} container >
                    <Grid item xs={8} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >

                        <Box display="flex" flexDirection="column">
                            <Box display="flex" alignItems="flex-start" >
                                <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                                    01
                                </Typography>
                                <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                                    Assistance with creating attractive profiles
                                </Typography>
                            </Box>
                            <Box pr={'10vw'} mb={12} display="flex" flexDirection="column">
                                <Box mt={8} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Verify" fill={green1000} />
                                    </Box>
                                    <Typography variant="body1">We understand the importance of presenting your workspace in the best possible light. Our team is available to help you craft a compelling profile that highlights your organisation's unique features and appeals to potential guests.</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >
                        <div className={classes.assistance_illustration} title="AssistanceIllustration"></div>
                    </Grid>

                </Grid>

                {/* 02 GUIDANCE ON WORKSPACE OPERATIONS */}
                <Grid id="support_guidance" px={horizontalMargin} container >
                    <Grid item xs={8} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >

                        <Box display="flex" flexDirection="column">
                            <Box display="flex" alignItems="flex-start" >
                                <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                                    02
                                </Typography>
                                <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                                    Guidance on workspace operations
                                </Typography>
                            </Box>
                            <Box pr={'10vw'} mt={4} mb={12} display="flex" flexDirection="column">
                                <Typography variant="body1">To make the hosting experience as smooth as possible, we provide guidance on key aspects of managing your workspace, including:</Typography>
                                <Box mt={4} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Verify" fill={green1000} />
                                    </Box>
                                    <p className={classes.process_step_description}>
                                        <span style={{ fontFamily: 'Poppins600', margin: '0 8px 0 0' }}>Booking:</span>
                                        Tips on managing reservation requests and scheduling, and best practices for communication with guests.
                                    </p>
                                </Box>
                                <Box mt={4} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Verify" fill={green1000} />
                                    </Box>
                                    <p className={classes.process_step_description}>
                                        <span style={{ fontFamily: 'Poppins600', margin: '0 8px 0 0' }}>Hosting process:</span>
                                        Advice on creating a welcoming environment for guests, ensuring their comfort, and addressing any concerns that may arise.
                                    </p>
                                </Box>
                                <Box mt={4} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Verify" fill={green1000} />
                                    </Box>
                                    <p className={classes.process_step_description}>
                                        <span style={{ fontFamily: 'Poppins600', margin: '0 8px 0 0' }}>Payment:</span>
                                        Assistance with setting up and managing payment processes, including invoicing and receipts.
                                    </p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >
                        <div className={classes.guidance_illustration} title="GuidanceIllustration"></div>
                    </Grid>

                </Grid>

                {/* 03 PROVISION OF TEMPLATES AND TOOLS FOR EFFECTIVE HOSTING */}
                <Grid id="support_templates" px={horizontalMargin} container >
                    <Grid item xs={8} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >

                        <Box display="flex" flexDirection="column">
                            <Box display="flex" alignItems="flex-start" >
                                <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                                    03
                                </Typography>
                                <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                                    Provision of templates and tools for effective hosting
                                </Typography>
                            </Box>
                            <Box pr={'10vw'} mb={12} display="flex" flexDirection="column">
                                <Box mt={8} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Verify" fill={green1000} />
                                    </Box>
                                    <Typography variant="body1">To further support your hosting journey, we offer access to a range of templates and tools designed to streamline the management of your workspace and enhance the guest experience.</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >
                        <div className={classes.provision_illustration} title="ProvisionIllustration"></div>
                    </Grid>
                </Grid>

                {/* 04 DISCLAIMER */}
                <Grid id="support_disclaimer" px={horizontalMargin} container >
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" style={{ borderTop: `2px solid ${green100}` }} >
                        <Box display="flex" flexDirection="column">
                            <Box display="flex" alignItems="flex-start">
                                <Typography mt={12} variant='h3' style={{ minWidth: '30px', color: green600 }}>
                                    04
                                </Typography>
                                <Typography mt={12} pl={1.5} variant='h3' style={{ color: green1000 }}>
                                    Disclaimer
                                </Typography>
                            </Box>
                            <Box pr={'15vw'} mb={12} display="flex" flexDirection="column">
                                <Box mt={8} display="flex">
                                    <Box mr={4} display="flex">
                                        <Icon width={48} height={48} iconName="Information" fill={green1000} />
                                    </Box>
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="body1">Please be aware that host organisations are responsible for managing their booking and payment processes independently. While socialspace provides guidance and support in these areas, the actual responsibility for hosting lies with the host organisation. For more information, please refer to our usage terms, which outline our exclusion of liability regarding the hosting process.</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
              
                 {/* FOOTER */}
                 <FooterDesktop />
            </Paper >
        </Grid >
    )
}
export const ABOUTUS_MOBILE = [
    {
        section: 'header',
        eng: 'This is socialspace!',
        ger: 'Willkommen bei socialspace!',
    },
    {
        section: 'header_text',
        eng: 'socialspace is a unique coworking platform fostering collaboration and sustainability, by connecting individuals, businesses, and non-profit organisations, enabling positive impact.',
        ger: 'socialspace ist eine innovative Plattform, die  Coworker, soziale Unternehmen und gemeinnützige Organisationen mit dem Ziel verbindet, Kollaboration zu ermöglichen, Resourcen zu sparen und postiven gesellschaftlichen Wandel zu bewirken.',
    },
    {
        section: 'phrase1',
        eng: 'socialspace enables social impact, collaboration and sustainability.',
        ger: 'socialspace ermöglicht soziale Einflussnahme, Zusammenarbeit und Nachhaltigkeit.',
    },
    {
        section: 'phrase2',
        eng: 'socialspace envisions a vibrant and inclusive community where everyone thrives together.',
        ger: 'socialspace strebt eine lebendige und integrative Gemeinschaft an, in der sich alle gemeinsam entfalten.',
    },
    
    {
        section: 'vision_header',
        eng: 'Our mission is to help non-profit organisations utilise resources efficiently by enabling them to generate income through office letting, fostering collaboration and sustainable impact.',
        ger: 'Unsere Vision ist es, die Abhängigkeit von wohltätigen Organisationen zu verringern und sie zu befähigen ihre Vision zu verfolgen und ihre Wirkung zu maximieren.',
    },
    {
        section: 'vision_text',
        eng: 'Our vision is to create a network of sustainable workspaces that nurture innovation and collaboration, enabling non-profit organisations to diversify income streams and drive positive change.',
        ger: 'socialspace ermöglicht sozialen Unternehmen und gemeinnützigen Organisationen nachhaltig Einkommen zu generieren und ihre Resourcen effektiv zu nutzen.',
    },
    {
        section: 'title_section4',
        eng: 'Bridging the gap',
        ger: 'Alternativen schaffen',
    },
    {
        section: 'text_section4',
        eng: 'socialspace is seeking to bridge the gap between coworkers in search of unique locations and NGOs wanting to make use of unused workspaces. Our core offering empowers freelancers, remote workers and organisations to find workspaces that foster meaningful connections, promote collaboration and enable impact.',
        ger: 'socialspace möchte nachhaltige Alternativen zu herkömmlichen Coworking Angeboten bieten. Wir geben Coworker, sozialen Organisationen und Unternehmen, die auf der Suche nach einzigartigen Arbeitsplätzen sind, die Möglichkeit, diese einfach und bequem zu finden und gleichzeitig gemeinnützige Zwecke zu unterstützen.',
    },
    {
        section: 'title_section5',
        eng: 'We are testing',
        ger: 'Wir sind in der Test Phase',
    },
    {
        section: 'text1_section5',
        eng: 'Since May 2023 we are in our prototype testing phase, which runs until 31. August 2023 in Berlin. During this time, we seek to better understand the demand from hosts and coworkers, and to evaluate the overall feasibility of our unique coworking concept. Your participation and feedback will play a crucial role in helping us refine and improve our offering!',
        ger: 'Im Mai 2023 haben wir unsere Prototypen-Testphase, die bis zum 31. August 2023 in Berlin läuft, gestartet. In dieser Zeit versuchen wir, die Nachfrage von Hosts und Gästen besser zu verstehen und die allgemeine Machbarkeit unseres einzigartigen Coworking-Konzepts zu beurteilen. Euer Feedback wird uns entscheidend dabei helfen, unser Konzept zu verfeinern und zu verbessern!',
    },
    {
        section: 'text2_section5',
        eng: "And here's the cherry on top: during our prototype testing phase, socialspace is not charging any fees or compensation.",
        ger: 'Und das Beste daran: Während der Testphase des Prototyps erhebt socialspace keine Gebühren. Wir freuen uns über eure Teilnahme und Feedback!',
    },
    {
        section: 'text_section6',
        eng: "While we start as a private project, our ambition is to evolve socialspace into a purpose-driven company, potentially operating as a not-for-profit social enterprise. Our commitment is to make a positive impact and drive innovation in the coworking world.",
        ger: 'Was als privates Projekt beginnt, soll sich zu einem zweckorientierten Unternehmen weiterentwickeln, das möglicherweise als gemeinnütziges Sozialunternehmen tätig wird. Unser Ziel ist es, zu positivem sozialen Wandel beizutragen und die Coworking-Welt nachhaltig zu revolutionieren.',
    },
    {
        section: 'title_section6',
        eng: 'Let our journey begin!',
        ger: 'Let our journey begin!',
    },
];
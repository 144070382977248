import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from '../CalendarBox.styles';
import { green100, green600, green800, grey400, grey50, grey800 } from '../../../other/helper/MuiStyles';

export default function MonthCalendarBox({ currency, data, handleMonthChange, selectedMonths }) {

    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1} className={classes.calendar_box}>
            <Box display="flex" justifyContent="space-between" flexGrow={1}>
                {data.months.slice(0, 6).map((option, index) => {
                    return (
                        <Box key={index} mr={index === 5 ? 0 : 3} display="flex" flexDirection="column" alignItems="center" justifyContent="center" onClick={() => handleMonthChange(option.month, option.status, option.price)} className={classes.month_box} style={{ cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: selectedMonths.includes(option.month) ? green600 : option.status === 'Available' ? grey50 : grey400 }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? selectedMonths.includes(option.month) ? `${green800} !important` : `${green100} !important` : '' } }}>
                            <Typography variant="body1" style={{ color: (option.status !== 'Available' || selectedMonths.includes(option.month)) ? grey50 : grey800, height: '42px' }}>
                                {option.month}
                            </Typography>
                            <Typography variant="h4" style={{ color: (option.status !== 'Available' || selectedMonths.includes(option.month)) ? '#FFF' : '', opacity: option.status === 'Available' ? 1 : 0, padding: '24px 0' }}>
                                {currency}{option.price}
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: 'center', color: (option.status !== 'Available' || selectedMonths.includes(option.month)) ? grey50 : grey800, height: '21px' }}>
                                {option.status === 'Closed' ? 'Closed' : option.status === 'Occupied' ? 'Fully booked' : `${selectedMonths.includes(option.month) ? (option.available - 1) : option.available} / ${(option.booked + option.available)}`}
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: 'center', color: (option.status !== 'Available' || selectedMonths.includes(option.month)) ? grey50 : grey800, height: '21px' }}>
                                {option.status !== 'Available' ? ' ' : 'available'}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
            <Box mt={3} display="flex" justifyContent="space-between" flexGrow={1}>
                {data.months.slice(6, 12).map((option, index) => {
                    return (
                        <Box key={index} mr={index === 5 ? 0 : 3} display="flex" flexDirection="column" alignItems="center" justifyContent="center" onClick={() => handleMonthChange(option.month, option.status, option.price)} className={classes.month_box} style={{ cursor: option.status === 'Available' ? 'pointer' : '', backgroundColor: selectedMonths.includes(option.month) ? green600 : option.status === 'Available' ? grey50 : grey400 }} sx={{ '&:hover': { backgroundColor: option.status === 'Available' ? selectedMonths.includes(option.month) ? `${green800} !important` : `${green100} !important` : '' } }}>
                            <Typography variant="body1" style={{ color: (option.status !== 'Available' || selectedMonths.includes(option.month)) ? grey50 : grey800, height: '42px' }}>
                                {option.month}
                            </Typography>
                            <Typography variant="h3" style={{ color: (option.status !== 'Available' || selectedMonths.includes(option.month)) ? grey50 : grey800, opacity: option.status === 'Available' ? 1 : 0, padding: '24px 0' }}>
                                {currency}{option.price}
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: 'center', color: (option.status !== 'Available' || selectedMonths.includes(option.month)) ? grey50 : grey800, height: '21px' }}>
                                {option.status === 'Closed' ? 'Closed' : option.status === 'Occupied' ? 'Fully booked' : `${selectedMonths.includes(option.month) ? (option.available - 1) : option.available} / ${(option.booked + option.available)}`}
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: 'center', color: (option.status !== 'Available' || selectedMonths.includes(option.month)) ? grey50 : grey800, height: '21px' }}>
                                {option.status !== 'Available' ? '' : 'available'}
                            </Typography>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}
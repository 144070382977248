export const data = " To reduce resource dependencies, non-profit organisations constantly try to find strategies to become less resource dependent. In particular, underutilized office space generates high costs for organisations. To cover costs, organisations are forced to find solutions to make use of underutilized office spaces.";

export const WORKSPACETYPE = [
    {
        workspaceTypeId: '1',
        name: 'Hot Desk',
        description: 'Long table that will be shared with others.',
    },
    {
        workspaceTypeId: '2',
        name: 'Private Desk',
        description: 'Private desk for personal usage during your stay.',
    },
    {
        workspaceTypeId: '3',
        name: 'Private Office',
        description: 'Private office for teams of multiple persons.',
    },
];

export const HOSTS = [
    {
        hostId: '1',
        name: 'AMANDLA gGmbH',
        pictures: ['1_3', '1_4', '1_5', '1_6', '1_7', '1_1', '1_2'],
        shortDescription: 'AMANDLA gGmbH creates safe spaces that bring together the power of sport and education to empower youth and change lives. At the heart of the organization’s work lies its award-winning approach to youth development: the Safe-Hub Precinct.',
        longDescription: "AMANDLA gGmbH creates safe spaces that bring together the power of sport and education to empower youth and change lives. At the heart of the organisation’s work lies its award-winning approach to youth  and community development: the Safe-Hub Precinct.\n\nSafe-Hubs serve as collective impact hubs   in communities characterised by poverty, unemployment and inequality. A Safe-Hub is a physically and emotionally safe space for young people to access support from strong role models through holistic after-school programmes  with a special focus on health, (digital) education, and employability. Its infrastructure provides a campus for local NGOs, government and service providers, making each Safe-Hub an innovative collaboration place with only one main focus: holistic support for young people, their families and the community.\n\nThe Safe-Hub model, currently operational at-scale in South Africa, has been endorsed by the United Nations Office on Sport for Development and Peace as a global best-practice model for youth development. Safe-Hub has won numerous international awards and endorsements, including the Beyond Sport Award as the best global social impact project in ‘Conflict Resolution’ and the White House Letter of Endorsement from Michelle Obama . Apart from 14 operational Safe-Hubs in South Africa, AMANDLA has also established Safe-Hub Projects in the US (Philadelphia), India (New-Delhi), Ivory Coast (Abidjan) and Germany (Berlin).",
        headquarter: 'Cape Town, South Africa',
        founded: '2007',
        sector: ['Eductaion', 'Sport', 'Community Development'],
        region: ['South Africa', 'Ivory Coast', 'India', 'United States', 'Germany'],
        website: 'https://www.amandla.net',
    },
    {
        hostId: '2',
        name: 'Social Landscaping e.V.',
        pictures: ['2_1', '2_2', '2_3', '2_4', '2_5'],
        shortDescription: "Social Landscaping e.V. is a non-profit organisation dedicated to creating social gardens as community places. Founded in 2018 by landscape gardener Georg Höfer, we use the winter break to support and improve disadvantaged communities around the world.",
        longDescription: "Social Landscaping e.V. is a non-profit organisation dedicated to creating social gardens as community places. Founded in 2018 by landscape gardener Georg Höfer, we use the winter break to support and improve disadvantaged communities around the world. Since 2021, we have coordinated our projects independently and transparently, with members from a wide variety of backgrounds. So far, we have successfully implemented projects in South Africa, Sri Lanka, Colombia and Germany, always with the goal of creating social gathering places and promoting civil engagement, collaboration and sustainability.\n\nSocial Landscaping e.V. designs and implements landscape garden projects in disadvantaged regions. In cooperation with local communities, we create places for encounters and shared learning. The impact stretches from South Africa, through Sri Lanka and Colombia, to Germany. In addition to creating space, we promote the personal development of those involved and see our projects as cooperation at eye level. Volunteering is a central aspect of our work.",
        headquarter: 'Eggstätt, Germany',
        founded: '2018',
        sector: ['Environment', 'Education', 'Community Development'],
        region: ['South Africa', 'Sri Lanka', 'Columbia', 'Germany'],
        website: 'https://social-landscaping.org/',
    },
];


export const HOMEPAGE_WORKSPACES = [
    {
        socialspaceId: '1',
        host: 'AMANDLA gGmbH',
        location: 'Berlin, Germany',
        workspaceType: 'Hot Desk',
        currency: '€',
        price: 20.00,
        rating: '5.0',
        address: {
            street: 'Reinickendorfer Strasse 67',
            postalCode: '13347',
        },
        pictures: ['1_1', '1_2', '1_5', '1_6', '1_3', '1_4', '1_7'],
    },
    {
        socialspaceId: '5',
        host: 'AMANDLA gGmbH',
        location: 'Cape Town, South Africa',
        workspaceType: 'Private Desk',        
        currency: 'R',
        price: 250.00,
        rating: 0,
        address: {
            street: '19 Kent Street',
            postalCode: '7915',
        },
        pictures: ['1_3', '1_4', '1_7', '1_1', '1_2', '1_5', '1_6'],
    },
    {
        socialspaceId: '4',
        host: 'Social Landscaping e.V.',
        location: 'Eggstätt, Germany',
        workspaceType: 'Private Office',
        currency: '€',
        price: 25.00,
        rating: '5.0',
        address: {
            street: 'Seeoner Straße 35',
            postalCode: '83125',
        },
        pictures: ['2_1', '1_4', '1_7', '1_1', '1_2', '1_5', '1_6'],
    },
];

export const WORKSPACES = [
    {
        socialspaceId: '1',
        host: 'AMANDLA gGmbH',
        location: 'Berlin',
        workspaceType: 'Hot Desk',
        workspaceDescription: 'Any workspace with guaranteed seating. No reserved desk.',        
        currency: '€',
        dailyPrice: 20.00,
        monthlyPrice: 150.00,
        rating: 4.7,
        address: {
            street: 'Reinickendorfer Strasse 67',
            postalCode: '13347',
            lat: 52.552789438695825,
            lng: 13.366616038220496,
        },
        pictures: ['1_7', '1_4', '1_3', '1_6', '1_5', '1_2'],
        officeDescription: 'Welcome to our lovely office nestled between the trendy districts of Wedding and Gesundbrunnen. Our sun-drenched, ground-level office boasts a spacious open-plan layout with eight modern workstations, designed to inspire collaboration and creativity. Take a meeting in one of our two boardrooms or fuel your day with a fresh brew in our cosy kitchen area. Once you step outside, you can explore a mix of local grocery markets, food stores and a charming coffee shop just around the corner.',
        officeTime: {
            hours: '08:00 am - 06:00 pm',
            days: 'Monday - Sunday',
        },
        officeManager: 'Jasmina Srna',
        // officeFeatures: ['Pet friendly', 'Printer', 'Parking', 'Coffee', 'Friuts', 'Utensils', 'Air Conditioning'],
        availability: {
            workspaceType: 'Hot Desk',
            day: {
                "year": 2024,
                "months": [
                    {
                        "month": "January",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 31,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "February",
                        "days": [
                            {
                                "day": "Thursday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "March",
                        "days": [
                            {
                                "day": "Friday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 30,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 31,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "April",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "May",
                        "days": [
                            {
                                "day": "Wednesday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 30,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 31,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "June",
                        "days": [
                            {
                                "day": "Saturday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 30,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "July",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 31,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "August",
                        "days": [
                            {
                                "day": "Thursday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 30,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 31,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "September",
                        "days": [
                            {
                                "day": "Sunday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 30,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "October",
                        "days": [
                            {
                                "day": "Tuesday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 30,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 31,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "November",
                        "days": [
                            {
                                "day": "Friday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 30,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "December",
                        "days": [
                            {
                                "day": "Sunday",
                                "date": 1,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 2,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 3,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 4,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 5,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 6,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 7,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 8,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 9,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 10,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 11,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 12,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 13,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 14,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 15,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 16,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 17,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 18,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 19,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 20,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 21,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 22,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 23,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 24,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 25,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 26,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 27,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 28,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 29,
                                "price": 20.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 30,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 31,
                                "price": 20.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    }
                ]
            },
            month: {
                year: 2024,
                months: [
                    {
                        month: 'January',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'February',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'March',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'April',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'May',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'June',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'July',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'August',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'September',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'October',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'Novemeber',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'December',
                        price: 150.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                ],
            },
        },
    },
    {
        socialspaceId: '2',
        host: 'AMANDLA gGmbH',
        location: 'Berlin',
        workspaceType: 'Private Desk',
        workspaceDescription: 'Reserved desk with dedicated equipment for entire booking.',
        currency: '€',
        dailyPrice: 25.00,
        monthlyPrice: 200.00,
        rating: 4.8,
        address: {
            street: 'Reinickendorfer Strasse 67',
            postalCode: '13347',
            lat: 52.552789438695825,
            lng: 13.366616038220496,
        },
        pictures: ['1_2', '1_1', '1_5', '1_6', '1_3', '1_4', '1_7'],
        officeDescription: 'Welcome to our lovely office nestled between the trendy districts of Wedding and Gesundbrunnen. Our sun-drenched, ground-level office boasts a spacious open-plan layout with eight modern workstations, designed to inspire collaboration and creativity. Take a meeting in one of our two boardrooms or fuel your day with a fresh brew in our cosy kitchen area. Once you step outside, you can explore a mix of local grocery markets, food stores and a charming coffee shop just around the corner.',
        officeTime: {
            hours: '08:00 am - 06:00 pm',
            days: 'Monday - Sunday',
        },
        officeManager: 'Jasmina Srna',
        // officeFeatures: ['Pet friendly', 'Printer', 'Parking', 'Coffee', 'Friuts', 'Utensils', 'Air Conditioning'],
        availability: {
            workspaceType: 'Private Desk',
            day: {
                "year": 2024,
                "months": [
                    {
                        "month": "January",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "February",
                        "days": [
                            {
                                "day": "Thursday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "March",
                        "days": [
                            {
                                "day": "Friday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "April",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "May",
                        "days": [
                            {
                                "day": "Wednesday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "June",
                        "days": [
                            {
                                "day": "Saturday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "July",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "August",
                        "days": [
                            {
                                "day": "Thursday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "September",
                        "days": [
                            {
                                "day": "Sunday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "October",
                        "days": [
                            {
                                "day": "Tuesday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "November",
                        "days": [
                            {
                                "day": "Friday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "December",
                        "days": [
                            {
                                "day": "Sunday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    }
                ]
            },
            month: {
                year: 2024,
                months: [
                    {
                        month: 'January',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'February',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'March',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'April',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'May',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'June',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'July',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'August',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'September',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'October',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'Novemeber',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'December',
                        price: 200.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                ],
            },
        },
    },
    {
        socialspaceId: '3',
        host: 'AMANDLA gGmbH',
        location: 'Berlin',
        workspaceType: 'Private Office',
        workspaceDescription: 'Entire office, incl. 8 private desks, 2 meeting rooms and communal space with kitchen.',
        currency: '€',
        dailyPrice: 150.00,
        monthlyPrice: 1000.00,
        rating: 0,
        address: {
            street: 'Reinickendorfer Strasse 67',
            postalCode: '13347',
            lat: 52.552789438695825,
            lng: 13.366616038220496,
        },
        pictures: ['1_3', '1_4', '1_7', '1_1', '1_2', '1_5', '1_6'],
        officeDescription: 'Welcome to our lovely office nestled between the trendy districts of Wedding and Gesundbrunnen. Our sun-drenched, ground-level office boasts a spacious open-plan layout with eight modern workstations, designed to inspire collaboration and creativity. Take a meeting in one of our two boardrooms or fuel your day with a fresh brew in our cosy kitchen area. Once you step outside, you can explore a mix of local grocery markets, food stores and a charming coffee shop just around the corner.',
        officeTime: {
            hours: '08:00 am - 06:00 pm',
            days: 'Saturday - Sunday',
        },
        officeManager: 'Jasmina Srna',
        // officeFeatures: ['Pet friendly', 'Printer', 'Parking', 'Coffee', 'Friuts', 'Utensils', 'Air Conditioning'],
        availability: {
            workspaceType: 'Private Office',
            day: {
                "year": 2024,
                "months": [
                    {
                        "month": "January",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 31,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "February",
                        "days": [
                            {
                                "day": "Thursday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "March",
                        "days": [
                            {
                                "day": "Friday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 30,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 31,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "April",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "May",
                        "days": [
                            {
                                "day": "Wednesday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 30,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 31,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "June",
                        "days": [
                            {
                                "day": "Saturday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 30,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "July",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 31,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "August",
                        "days": [
                            {
                                "day": "Thursday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 30,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 31,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "September",
                        "days": [
                            {
                                "day": "Sunday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 30,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "October",
                        "days": [
                            {
                                "day": "Tuesday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 30,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 31,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "November",
                        "days": [
                            {
                                "day": "Friday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 30,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "December",
                        "days": [
                            {
                                "day": "Sunday",
                                "date": 1,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 2,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 3,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 4,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 5,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 6,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 7,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 8,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 9,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 10,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 11,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 12,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 13,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 14,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 15,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 16,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 17,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 18,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 19,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 20,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 21,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 22,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 23,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 24,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 25,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 26,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 27,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 28,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 29,
                                "price": 150.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 30,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 31,
                                "price": 150.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    }
                ]
            },
            month: {
                year: 2024,
                months: [
                    {
                        month: 'January',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'February',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'March',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'April',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'May',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'June',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'July',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'August',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'September',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'October',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'Novemeber',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'December',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                ],
            },
        },
    },
    {
        socialspaceId: '4',
        host: 'Social Landscaping e.V.',
        location: 'Eggstätt',
        workspaceType: 'Private Office',
        workspaceDescription: 'Entire tiny house office, incl. 4 seats and fast WiFi.',
        currency: '€',
        dailyPrice: 25.00,
        monthlyPrice: 200.00,
        rating: 0,
        address: {
            street: 'Seeoner Straße 35',
            postalCode: '83125',
            lat: 47.930220,
            lng: 12.386320,
        },
        pictures: ['2_1', '2_2', '2_3', '2_4', '2_5', '2_6', '2_7'],
        officeDescription: 'The office of Social Landscaping e.V. is located in idyllic Eggstätt at the Chiemsee, on the premises of Höfer Kraftorte. Surrounded by picturesque nature, it offers a unique workspace and promotes a harmonious balance between work and leisure. Easy to reach, it combines working with a variety of leisure activities in the immediate vicinity. Special feature: Georg has a few bikes that guests can rent during their stay.\n\n The "Tiny House" office, nestled within trees and plants, seats 4, offers fast WiFi and a truly unique and cozy coworking environment. Amenities, such as a shower and kitchen, or a second screen are just steps away in the main office building. Enjoy work sessions or break on the private terrace in cool shade. Ideal for city escapees and startups seeking an inspiring off-site location.',
        officeTime: {
            hours: '06:00 am - 11:00 pm',
            days: 'Monday - Sunday',
        },
        officeManager: 'Georg Höfer',
        // officeFeatures: ['Pet friendly', 'Printer', 'Parking', 'Coffee', 'Friuts', 'Utensils', 'Air Conditioning'],
        availability: {
            workspaceType: 'Private Office',
            "day": {
                "year": 2024,
                "months": [
                    {
                        "month": "January",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "February",
                        "days": [
                            {
                                "day": "Thursday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "March",
                        "days": [
                            {
                                "day": "Friday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "April",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "May",
                        "days": [
                            {
                                "day": "Wednesday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "June",
                        "days": [
                            {
                                "day": "Saturday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "July",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "August",
                        "days": [
                            {
                                "day": "Thursday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "September",
                        "days": [
                            {
                                "day": "Sunday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "October",
                        "days": [
                            {
                                "day": "Tuesday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "November",
                        "days": [
                            {
                                "day": "Friday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "December",
                        "days": [
                            {
                                "day": "Sunday",
                                "date": 1,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 2,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 3,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 4,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 5,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 6,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 7,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 8,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 9,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 10,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 11,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 12,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 13,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 14,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 15,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 16,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 17,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 18,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 19,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 20,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 21,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 22,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 23,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 24,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 25,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 26,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 27,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 28,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 29,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 30,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 31,
                                "price": 25.0,
                                "status": "Available",
                                "available": 1,
                                "booked": 0
                            }
                        ]
                    }
                ]
            },
            month: {
                year: 2024,
                months: [
                    {
                        month: 'January',
                        price: 200.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'February',
                        price: 200.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: '200',
                        price: 1000.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'April',
                        price: 200.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'May',
                        price: 200.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'June',
                        price: 200.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'July',
                        price: 200.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'August',
                        price: 200.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'September',
                        price: 200.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'October',
                        price: 200.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'Novemeber',
                        price: 200.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                    {
                        month: 'December',
                        price: 200.00,
                        status: 'Available',
                        available: 1,
                        booked: 0,
                    },
                ],
            },
        },
    },
    {
        socialspaceId: '5',
        host: 'AMANDLA gGmbH',
        location: 'Cape Town',
        workspaceType: 'Private Desk',
        workspaceDescription: 'Reserved desk with dedicated equipment for entire booking.',
        currency: 'R',
        dailyPrice: 250.00,
        monthlyPrice: 2000.00,
        rating: 0,
        address: {
            street: '19 Kent Street',
            postalCode: '7915',
            lat: -33.928495,
            lng: 18.458335,
        },
        pictures: ['3_1', '3_2','3_3', '3_4', '3_5'],
        officeDescription: "Discover a workspace where creativity and innovation thrive at AMANDLA's Cape Town office, located in the vibrant district of Salt River. Just a stone's throw away from the famous Old Biscuit Mill, our office is surrounded by plenty restaurants, cafes, and lively spots, offering you a perfect blend of work and leisure. The trendy location ensures you are moving against the city's traffic, making your morning commute quick and seamless. Housed in a cool, industrial-style multi-party complex, our office features its own coffee shop and a protected courtyard, providing a serene space for lunch breaks and casual coffee chats. Ample parking available.",
        officeTime: {
            hours: '08:00 am - 06:00 pm',
            days: 'Monday - Sunday',
        },
        officeManager: 'Ilonka Grützmacher',
        // officeFeatures: ['Pet friendly', 'Printer', 'Parking', 'Coffee', 'Friuts', 'Utensils', 'Air Conditioning'],
        availability: {
            workspaceType: 'Hot Desk',
            day: {
                "year": 2024,
                "months": [
                    {
                        "month": "January",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 31,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "February",
                        "days": [
                            {
                                "day": "Thursday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "March",
                        "days": [
                            {
                                "day": "Friday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 30,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 31,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "April",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "May",
                        "days": [
                            {
                                "day": "Wednesday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 30,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 31,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "June",
                        "days": [
                            {
                                "day": "Saturday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 30,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "July",
                        "days": [
                            {
                                "day": "Monday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 30,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 31,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "August",
                        "days": [
                            {
                                "day": "Thursday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 30,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 31,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "September",
                        "days": [
                            {
                                "day": "Sunday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 30,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "October",
                        "days": [
                            {
                                "day": "Tuesday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 30,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 31,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "November",
                        "days": [
                            {
                                "day": "Friday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 30,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    },
                    {
                        "month": "December",
                        "days": [
                            {
                                "day": "Sunday",
                                "date": 1,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 2,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 3,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 4,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 5,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 6,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 7,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 8,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 9,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 10,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 11,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 12,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 13,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 14,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 15,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 16,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 17,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 18,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 19,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 20,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 21,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 22,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 23,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 24,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Wednesday",
                                "date": 25,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Thursday",
                                "date": 26,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Friday",
                                "date": 27,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Saturday",
                                "date": 28,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Sunday",
                                "date": 29,
                                "price": 250.0,
                                "status": "Closed",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Monday",
                                "date": 30,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            },
                            {
                                "day": "Tuesday",
                                "date": 31,
                                "price": 250.0,
                                "status": "Available",
                                "available": 2,
                                "booked": 0
                            }
                        ]
                    }
                ]
            },
            month: {
                year: 2024,
                months: [
                    {
                        month: 'January',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'February',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'March',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'April',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'May',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'June',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'July',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'August',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'September',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'October',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'Novemeber',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                    {
                        month: 'December',
                        price: 2000.00,
                        status: 'Available',
                        available: 2,
                        booked: 0,
                    },
                ],
            },
        },
    },
];


export const MONTHS = ['January', 'February', 'March', 'April', 'Mai', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const countWorkers = [];
for (let i = 1; i <= 10; i++) {
    countWorkers.push({ id: i, name: i });
}

export { countWorkers }
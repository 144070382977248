import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Grid, Paper, Box, Typography, Switch, Snackbar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useStyles } from './WorkspacePage.styles';
import { WORKSPACES, HOSTS, WORKSPACETYPE } from '../../data/data';
import { IMAGE_MAP } from '../other/helper/ImgMapper';
import ContactBox from './ContactBox/ContactBox';
import FeatureBox from './FeatureBox/FeatureBox';
import HostBox from '../other/HostBox/HostBox';
import PriceTimeBox from './PriceTimeBox/PriceTimeBox';
import MonthCalendarBox from './CalendarBox/MonthCalendarBox/MonthCalendarBox';
import DayCalendarBox from './CalendarBox/DayCalendarBox/DayCalendarBox';
import { green1000, green800, grey100, grey800 } from '../other/helper/MuiStyles';
import { DayMonthContext } from '../other/contexts/DayMonthContext';
import Icon from '../../assets/icons/Icon';
import WorkspaceTypeBoxDesktop from '../WorkspaceTypepBox/desktop/WorkspaceTypeBoxDesktop';
import FooterDesktop from '../Footer/desktop/FooterDesktop';
import { AppContext } from '../other/contexts/AppContext';

const CustomSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: green800,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: grey100,
        opacity: 1,
    },
}));

export default function WorkspacePage() {
    let { id } = useParams();
    const ref = useRef(null);
    const refWorkspaceType = useRef(null);
    const { isMonthSet, setIsMonthSet } = useContext(DayMonthContext);
    const { snackbarMessage } = useContext(AppContext);

    const workspace = WORKSPACES.find(object => {
        return object.socialspaceId === id;
    });

    const hostDetail = HOSTS.find(object => {
        return object.name === workspace.host;
    });
    const hostOtherWorkspaces = WORKSPACES.filter(object => object.host === hostDetail.name && object.location === workspace.location);

    // DAY PROPS
    const [month, setMonth] = useState('June');
    const [selectedDays, setSelectedDays] = useState([]);
    const [amountOfDays, setAmountOfDays] = useState(0);
    const [currentMonthIndex, setCurrentMonthIndex] = useState(5);
    const allAvailableMonths = [...new Set(workspace.availability.day.months.map(object => object.month))];

    // MONTH PROPS
    const [selectedMonths, setSelectedMonths] = useState([]);

    // GENERAL    
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackbarOpen(false);
    };

    const handleTimeChange = (event) => {
        setIsMonthSet(event.target.checked);
        setSelectedDays([]);
        setSelectedMonths([]);
        setTotalPrice(0);
        setAmountOfDays(0)
    };

    const handlePrevMonth = () => {
        if (currentMonthIndex > 0) {
            setMonth(allAvailableMonths[currentMonthIndex - 1]);
            setCurrentMonthIndex(currentMonthIndex - 1);
        }
    };

    const handleNextMonth = () => {
        if (currentMonthIndex < allAvailableMonths.length - 1) {
            setMonth(allAvailableMonths[currentMonthIndex + 1]);
            setCurrentMonthIndex(currentMonthIndex + 1);
        }
    };

    const handleMonthChange = (month, status, price) => {
        if (status === 'Available') {
            let priceTemp = totalPrice === undefined ? 0 : JSON.parse(JSON.stringify(totalPrice));
            let selectedMonthsTemp = selectedMonths === undefined ? [] : JSON.parse(JSON.stringify(selectedMonths));
            if (!selectedMonthsTemp.includes(month)) {
                selectedMonthsTemp.push(month);
                priceTemp += price;
            } else {
                selectedMonthsTemp.splice(selectedMonthsTemp.indexOf(month), 1);
                priceTemp -= price;
            }
            setSelectedMonths(selectedMonthsTemp);
            setTotalPrice(priceTemp);
        }
    };

    const handleDayChange = (day) => {
        if (day.status === 'Available') {
            let selectedDaysTemp = JSON.parse(JSON.stringify(selectedDays));
            // check if month already selected
            if (selectedDaysTemp.some(object => object.month === month)) {
                // check if day already selected
                if (selectedDaysTemp[selectedDaysTemp.map(object => object.month).indexOf(month)]?.days.some(object => object.date === day.date)) {
                    // check if only one entry per month
                    if (selectedDaysTemp[selectedDaysTemp.map(object => object.month).indexOf(month)]?.days.length === 1) {
                        selectedDaysTemp.splice(selectedDaysTemp.map(object => object.month).indexOf(month), 1);
                    } else {
                        selectedDaysTemp[selectedDaysTemp.map(object => object.month).indexOf(month)]?.days.splice(selectedDaysTemp[selectedDaysTemp.map(object => object.month).indexOf(month)]?.days.map(object => object.date).indexOf(day.date), 1);
                    }
                } else {
                    selectedDaysTemp[selectedDaysTemp.map(object => object.month).indexOf(month)]?.days.push({ day: day.day, date: day.date, price: day.price });
                }
            } else {
                selectedDaysTemp.push({ month: month, days: [{ day: day.day, date: day.date, price: day.price }] });
            }
            setSelectedDays(selectedDaysTemp);
            // calculate sum of day prices
            let priceSum = 0;
            let daySum = 0;
            for (let i = 0; i < selectedDaysTemp.length; i++) {
                priceSum += selectedDaysTemp[i].days.reduce((acc, object) => acc + object.price, 0);
                daySum += selectedDaysTemp[i].days.length;
            }
            setTotalPrice(priceSum);
            setAmountOfDays(daySum);
        }
    };

    const checkIfSelected = (day) => {
        if (selectedDays.some(object => object.month === month)) {
            if (selectedDays[selectedDays.map(object => object.month).indexOf(month)]?.days.some(object => object.date === day.date)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    };

    useEffect(() => {
        setMonth('June');
        setSelectedDays([]);
        setSelectedMonths([]);
        setTotalPrice(0);
        setAmountOfDays(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setMonth('June');
        setSelectedDays([]);
        setSelectedMonths([]);
        setTotalPrice(0);
        setAmountOfDays(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSnackbarOpen]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const marginProp = '5vw';

    const styleProps = {
        hostImg: IMAGE_MAP.PARTNERS[hostDetail.name],
        officePicture1: IMAGE_MAP.OFFICE_DETAIL_DESKTOP[workspace.pictures[0]],
        officePicture2: IMAGE_MAP.OFFICE_DETAIL_DESKTOP[workspace.pictures[1]],
        officePicture3: IMAGE_MAP.OFFICE_DETAIL_DESKTOP[workspace.pictures[2]],
        marginBottomWorkspaceTypeBox: WORKSPACETYPE.length - 1 ? '40px' : '0px',
        smallPictureHeight: `calc((${ref.current?.offsetHeight}px - 24px) / 2)`,
    };

    const classes = useStyles(styleProps);

    const snackbarAction = (
        <React.Fragment>
            <Box ml={5} display="flex" alignItems="center">
                <IconButton
                    onClick={handleSnackbarClose}
                >
                    <Icon width={20} height={20} iconName="CloseIcon" fill={green1000} />
                </IconButton>
            </Box>
        </React.Fragment>
    );

    return (
        <Grid item xs={12} display="grid">
            <Paper className={classes.paper}>

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={isSnackbarOpen}
                    message={snackbarMessage}
                    action={snackbarAction}
                />

                {/* HEADLINE */}
                <Box mt={5} mb={3} mx={marginProp} display="flex" alignItems="baseline">
                    <Typography variant='h2'>
                        {workspace.workspaceType}
                    </Typography>
                    <Typography ml={3} variant='body1'>
                        {workspace.address.street}, {workspace.address.postalCode} {workspace.location}
                    </Typography>
                </Box>

                {/* PICTURES & HOST BOX  */}
                <Box mx={marginProp} display="flex" justifyContent="space-between" style={{ maxWidth: '90vw' }}>
                    <Box display="flex" justifyContent="space-between" flexGrow={1}>
                        <div ref={ref} className={classes.office_picture1} title="OfficePicture1"></div>
                        <Box display="flex" flexDirection="column" justifyContent="space-between">
                            <div className={classes.office_picture2} title="OfficePicture2"></div>
                            <div className={classes.office_picture3} title="OfficePicture3"></div>
                        </Box>
                    </Box>
                    <Box ml={5} display="flex" className={classes.host_box}>
                        <HostBox host={hostDetail} entryPoint={'WorkspacePage'} />
                    </Box>
                </Box>

                {/* WORKSPACE TYPES */}
                <Box mt={10} mx={marginProp} display="flex" alignItems="center" flexGrow={1}>
                    <Typography variant='h4' style={{ color: green1000 }}>
                        Select your workspace type
                    </Typography>
                </Box>
                <Typography my={2} mx={marginProp} variant='body1' style={{ color: grey800 }}>
                    {hostDetail.name} offers {hostOtherWorkspaces.length > 1 ? `${hostOtherWorkspaces.length} different workspace types. Select your favorite workspace type:` : 'one workspace type:'}
                </Typography>
                <Box ref={refWorkspaceType} mt={3} mx={marginProp} display="flex" justifyContent="space-between">
                    {hostOtherWorkspaces?.map((option, index) => {
                        return (
                            <Link to={`/workspace/${option.socialspaceId}`} key={option.socialspaceId} style={{ textDecoration: 'none', flexGrow: 1, marginRight: index !== (hostOtherWorkspaces.length - 1) ? '40px' : '' }}>
                                <WorkspaceTypeBoxDesktop workspaceType={option.workspaceType} workspaceDescription={option.workspaceDescription} selectedWorkspaceType={workspace.workspaceType} refWorkspaceType={refWorkspaceType} />
                            </Link>
                        )
                    })}
                </Box>

                {/* AVAILABILITY */}
                <Box mt={10} mx={marginProp} display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1}>
                        <Typography variant='h4' style={{ color: green1000 }}>
                            Select your workspace date
                        </Typography>

                    </Box>
                    <Typography ml={5} variant='h4' style={{ width: '328px', color: green1000 }}>                    </Typography>
                </Box>
                <Box my={2} mx={marginProp} display="flex" alignItems="flex-end" justifyContent="space-between">
                    <Typography variant='body1' style={{ color: grey800 }}>
                        First, choose whether you want to book your workspace on a daily or monthly basis. <br /> Then, select the {isMonthSet ? 'months' : 'days'} on which you would like to book a workspace.
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <Typography variant='h6' style={{ fontFamily: !isMonthSet ? 'Poppins600' : 'Poppins400', color: isMonthSet ? grey800 : '', marginRight: '4px' }}>Daily</Typography>
                        <CustomSwitch checked={isMonthSet || false} onChange={handleTimeChange} />
                        <Typography variant='h6' style={{ fontFamily: isMonthSet ? 'Poppins600' : 'Poppins400', color: !isMonthSet ? grey800 : '', marginLeft: '4px' }}>Monthly</Typography>
                    </Box>
                </Box>
                <Box mt={3} mx={marginProp} display="flex" justifyContent="space-between">
                    <Box pr={5} display="flex" flexGrow={1}>
                        {isMonthSet ?
                            <MonthCalendarBox currency={workspace.currency} data={workspace.availability.month} handleMonthChange={handleMonthChange} selectedMonths={selectedMonths} />
                            :
                            <DayCalendarBox currency={workspace.currency} data={workspace.availability.day.months[workspace.availability.day.months.map(object => object.month).indexOf(month)]} handleDayChange={handleDayChange} checkIfSelected={checkIfSelected} currentMonthIndex={currentMonthIndex} handlePrevMonth={handlePrevMonth} allAvailableMonths={allAvailableMonths} handleNextMonth={handleNextMonth} />
                        }
                    </Box>
                    <Box display="flex">
                        <PriceTimeBox workspace={workspace} amountOfDays={amountOfDays} totalPrice={totalPrice} selectedMonths={selectedMonths} selectedDays={selectedDays} setIsSnackbarOpen={setIsSnackbarOpen} />
                    </Box>
                </Box>

                {/* FEATURES & LOCATION */}
                <Box mt={10} mx={marginProp} display="flex" justifyContent="space-between">
                    <Typography variant='h4' style={{ color: green1000 }}>
                        What your host offers
                    </Typography>
                    <Typography variant='h4' style={{ width: '328px', color: green1000 }}>
                        Your host contact
                    </Typography>
                </Box>

                <Box mx={marginProp} mb={10} display="flex" justifyContent="space-between">
                    <Box pr={5} mt={3} display="flex">
                        <FeatureBox workspaceId={id} officeTime={workspace.officeTime} officeDescription={workspace.officeDescription} features={workspace.officeFeatures} />
                    </Box>
                    <Box mt={3} display="flex">
                        <ContactBox host={workspace.host} officeManager={workspace.officeManager}/>
                    </Box>
                </Box>

                {/* FOOTER */}
                <FooterDesktop specialWidth={'5vw'} />

            </Paper>
        </Grid >
    )
}
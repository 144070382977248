import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Grid, Paper, Box, Typography, Snackbar, IconButton, Radio } from '@mui/material';
import { useStyles } from './WorkspacePageMobile.styles';
import { WORKSPACES, HOSTS, WORKSPACETYPE } from '../../../data/data';
import Icon from '../../../assets/icons/Icon';
import { DayMonthContext } from '../../../components/other/contexts/DayMonthContext';
import { green1000, grey100, grey1000, grey200, grey600, grey800 } from '../../../components/other/helper/MuiStyles';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import { HOMEPAGE_MOBILE } from '../../../data/translation/TranslationHomepageMobile';
import { AppContext } from '../../../components/other/contexts/AppContext';
import WorkspaceTypeBoxMobile from '../../../components/WorkspaceTypepBox/mobile/WorkspaceTypeBoxMobile';
import ImageCarousel from '../../../components/ImageCarousel/ImageCarousel';
import MonthCalendarBoxMobile from '../../../components/MonthCalendarBox/mobile/MonthCalendarBoxMobile';
import BookBarMobile from '../../../components/BookBarMobile/BookBarMobile';
import DayCalendarBoxMobile from '../../../components/DayCalendarBox/mobile/DayCalendarBoxMobile';

export default function WorkspacePageMobile() {

    const { language, snackbarMessage, isSnackbarOpen, setIsSnackbarOpen, totalPrice, setTotalPrice, selectedDays, setSelectedDays, selectedMonths, setSelectedMonths } = useContext(AppContext);

    let { id } = useParams();
    const ref = useRef(null);

    const { isMonthSet, setIsMonthSet } = useContext(DayMonthContext);

    const workspace = WORKSPACES.find(object => {
        return object.socialspaceId === id;
    });

    const hostDetail = HOSTS.find(object => {
        return object.name === workspace.host;
    });

    const hostOtherWorkspaces = WORKSPACES.filter(object => object.host === hostDetail.name && object.location === workspace.location);

    // DAY PROPS
    const [month, setMonth] = useState('June');
    const [currentMonthIndex, setCurrentMonthIndex] = useState(5);
    const allAvailableMonths = [...new Set(workspace.availability.day.months.map(object => object.month))];

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackbarOpen(false);
    };

    const handleTimeChange = () => {
        setIsMonthSet(!isMonthSet);
        setSelectedDays([]);
        setSelectedMonths([]);
        setTotalPrice(0);
    };

    const handlePrevMonth = () => {
        if (currentMonthIndex > 0) {
            setMonth(allAvailableMonths[currentMonthIndex - 1]);
            setCurrentMonthIndex(currentMonthIndex - 1);
        }
    };

    const handleNextMonth = () => {
        if (currentMonthIndex < allAvailableMonths.length - 1) {
            setMonth(allAvailableMonths[currentMonthIndex + 1]);
            setCurrentMonthIndex(currentMonthIndex + 1);
        }
    };

    const handleMonthChange = (month, status, price) => {
        if (status === 'Available') {
            let priceTemp = totalPrice === undefined ? 0 : JSON.parse(JSON.stringify(totalPrice));
            let selectedMonthsTemp = selectedMonths === undefined ? [] : JSON.parse(JSON.stringify(selectedMonths));
            if (!selectedMonthsTemp.includes(month)) {
                selectedMonthsTemp.push(month);
                priceTemp += price;
            } else {
                selectedMonthsTemp.splice(selectedMonthsTemp.indexOf(month), 1);
                priceTemp -= price;
            }
            setSelectedMonths(selectedMonthsTemp);
            setTotalPrice(priceTemp);
        }
    };

    const handleDayChange = (day) => {
        if (day.status === 'Available') {
            let selectedDaysTemp = JSON.parse(JSON.stringify(selectedDays));
            // check if month already selected
            if (selectedDaysTemp.some(object => object.month === month)) {
                // check if day already selected
                if (selectedDaysTemp[selectedDaysTemp.map(object => object.month).indexOf(month)]?.days.some(object => object.date === day.date)) {
                    // check if only one entry per month
                    if (selectedDaysTemp[selectedDaysTemp.map(object => object.month).indexOf(month)]?.days.length === 1) {
                        selectedDaysTemp.splice(selectedDaysTemp.map(object => object.month).indexOf(month), 1);
                    } else {
                        selectedDaysTemp[selectedDaysTemp.map(object => object.month).indexOf(month)]?.days.splice(selectedDaysTemp[selectedDaysTemp.map(object => object.month).indexOf(month)]?.days.map(object => object.date).indexOf(day.date), 1);
                    }
                } else {
                    selectedDaysTemp[selectedDaysTemp.map(object => object.month).indexOf(month)]?.days.push({ day: day.day, date: day.date, price: day.price });
                }
            } else {
                selectedDaysTemp.push({ month: month, days: [{ day: day.day, date: day.date, price: day.price }] });
            }
            setSelectedDays(selectedDaysTemp);
            // calculate sum of day prices
            let priceSum = 0;
            for (let i = 0; i < selectedDaysTemp.length; i++) {
                priceSum += selectedDaysTemp[i].days.reduce((acc, object) => acc + object.price, 0);
            }
            setTotalPrice(priceSum);
        }
    };

    const checkIfSelected = (day) => {
        if (selectedDays.some(object => object.month === month)) {
            if (selectedDays[selectedDays.map(object => object.month).indexOf(month)]?.days.some(object => object.date === day.date)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    };

    useEffect(() => {
        setMonth('June');
        setSelectedDays([]);
        setSelectedMonths([]);
        setTotalPrice(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSnackbarOpen]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const styleProps = {
        hostImg: IMAGE_MAP.PARTNERS[hostDetail.name],
        contactImg: IMAGE_MAP.GENERAL[workspace.officeManager],
        marginBottomWorkspaceTypeBox: WORKSPACETYPE.length - 1 ? '40px' : '0px',
        smallPictureHeight: `calc((${ref.current?.offsetHeight}px - 24px) / 2)`,
    };

    const getImageUrls = (id) => {
        const selectedWorkspace = WORKSPACES.find(
            (workspace) => workspace.socialspaceId === id
        );

        if (!selectedWorkspace) {
            return [];
        }

        const { pictures } = selectedWorkspace;
        const imageUrls = pictures.map(
            (picture) => IMAGE_MAP.OFFICE_DETAIL_MOBILE[picture]
        );

        return imageUrls;
    };

    const images = getImageUrls(id);

    const classes = useStyles(styleProps);

    const snackbarAction = (
        <React.Fragment>
            <Box ml={1} display="flex" alignItems="center">
                <IconButton
                    onClick={handleSnackbarClose}
                >
                    <Icon width={20} height={20} iconName="CloseIcon" fill={green1000} />
                </IconButton>
            </Box>
        </React.Fragment>
    );

    return (
        <Grid item xs={12} display="grid">
            <Paper className={classes.paper}>

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={isSnackbarOpen}
                    message={snackbarMessage}
                    action={snackbarAction}
                    style={{ margin: '16px 24px 24px' }}
                />

                <ImageCarousel images={images} />

                <Box mx={3} pt={3} pb={5} display="flex" flexDirection="column">
                    <Typography variant='h2Mobile'>
                        {workspace.workspaceType}
                    </Typography>
                    <Typography mt={1} variant='body1'>
                        {workspace.address.street}, {workspace.address.postalCode} {workspace.location}
                    </Typography>
                </Box>


                {/* WORKSPACE TYPES */}
                <Box mx={3} pt={5} pb={3} display="flex" flexDirection="column" style={{ borderTop: `1px solid ${grey100}` }}>
                    <Typography variant='h4Mobile' style={{ color: green1000 }}>
                        Select your workspace type
                    </Typography>
                    <Typography my={3} variant='body1' style={{ color: grey800 }}>
                        {hostDetail.name} offers {hostOtherWorkspaces.length > 1 ? `${hostOtherWorkspaces.length} different workspace types. Select your favorite workspace type:` : 'one workspace type:'}
                    </Typography>
                    <Box display="flex" flexDirection="column">
                        {hostOtherWorkspaces?.map((option, index) => {
                            return (
                                <Link to={`/workspace/${option.socialspaceId}`} key={option.socialspaceId} style={{ textDecoration: 'none' }}>
                                    <WorkspaceTypeBoxMobile workspaceType={option.workspaceType} workspaceDescription={option.workspaceDescription} selectedWorkspaceType={workspace.workspaceType} />
                                </Link>
                            )
                        })}
                    </Box>
                </Box>

                {/* WORKSPACE AVAILABILITY */}
                <Box mx={3} pt={5} pb={3} display="flex" flexDirection="column" style={{ borderTop: `1px solid ${grey100}` }}>
                    <Typography variant='h4Mobile' style={{ color: green1000 }}>
                        Select your workspace date
                    </Typography>
                    <Typography my={3} variant='body1' style={{ color: grey800 }}>
                        First, choose whether you want to book your workspace on a daily or monthly basis:
                    </Typography>
                    <Box display="flex" flexDirection="column">
                        <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.day_month_box} onClick={handleTimeChange} style={{ border: !isMonthSet ? `2px solid ${green1000}` : `1px solid ${grey200}`, }}>
                            <Box display="flex" alignItems="center" >
                                <Radio
                                    disableRipple
                                    checked={!isMonthSet}
                                    value="selectDay"
                                />
                                <Typography ml={2} variant='h5Mobile' style={{ width: '-webkit-fill-available', }}>
                                    Daily
                                </Typography>
                            </Box>
                            <Typography variant='h5Mobile' style={{ color: green1000 }}>
                                {workspace.currency}{workspace.dailyPrice}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.day_month_box} onClick={handleTimeChange} style={{ border: isMonthSet ? `2px solid ${green1000}` : `1px solid ${grey200}`, }}>
                            <Box display="flex" alignItems="center" >
                                <Radio
                                    disableRipple
                                    checked={isMonthSet}
                                    value="selectDay"
                                />
                                <Typography ml={2} variant='h5Mobile' style={{ width: '-webkit-fill-available', }}>
                                    Monthly
                                </Typography>
                            </Box>
                            <Typography variant='h5Mobile' style={{ color: green1000 }}>
                                {workspace.currency}{workspace.monthlyPrice}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography mt={1} mb={3} variant='body1' style={{ color: grey800 }}>
                        Now select the {isMonthSet ? 'months' : 'days'} on which you would like to book a workspace:
                    </Typography>
                    <Box display="flex" flexDirection="column">
                        {isMonthSet ?
                            <MonthCalendarBoxMobile currency={workspace.currency} data={workspace.availability.month} handleMonthChange={handleMonthChange} selectedMonths={selectedMonths} />
                            :
                            <>
                                <Box mb={3} display="flex" alignItems="center" justifyContent="center">
                                    {currentMonthIndex > 0 ?
                                        <IconButton
                                            color="secondary"
                                            onClick={handlePrevMonth}
                                        >
                                            <Icon width={20} height={20} iconName="ArrowLeft" fill={grey1000} />
                                        </IconButton>
                                        :
                                        <Box width='36px'> </Box>
                                    }
                                    <Typography mx={2} variant="body1" style={{ width: '120px', textAlign: 'center' }}>{allAvailableMonths[currentMonthIndex]}</Typography>
                                    {currentMonthIndex < allAvailableMonths.length - 1 ?
                                        <IconButton
                                            color="secondary"
                                            onClick={handleNextMonth}
                                        >
                                            <Icon width={20} height={20} iconName="ArrowRight" fill={grey1000} />
                                        </IconButton>
                                        :
                                        <Box width='36px'> </Box>
                                    }
                                </Box>
                                <DayCalendarBoxMobile data={workspace.availability.day.months[workspace.availability.day.months.map(object => object.month).indexOf(month)]} handleDayChange={handleDayChange} checkIfSelected={checkIfSelected} />
                            </>
                        }
                    </Box>
                </Box>

                {/* OFFICE FEATURES */}
                <Box mx={3} py={5} display="flex" flexDirection="column" style={{ borderTop: `1px solid ${grey100}` }}>
                    <Typography variant='h4Mobile' style={{ color: green1000 }}>
                        What your host offers
                    </Typography>
                    <Typography py={3} variant='body1' style={{ color: grey800, whiteSpace: 'pre-line' }}>
                        {workspace.officeDescription}
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <Box display="flex" alignItems="center" style={{ minWidth: '24px', minHeight: '24px' }}>
                            <Icon width={20} height={20} iconName="Clock" fill={grey800} />
                        </Box>
                        <Typography pl={1} variant='body1' style={{ color: grey800, width: '112px' }}>
                            Office hours:
                        </Typography>
                        <Typography variant='body1'>
                            {workspace.officeTime.hours}
                        </Typography>
                    </Box>
                    <Box mt={2} display="flex" alignItems="center">
                        <Box display="flex" alignItems="center" style={{ minWidth: '24px', minHeight: '24px' }}>
                            <Icon width={20} height={20} iconName="Calendar" fill={grey800} />
                        </Box>
                        <Typography pl={1} variant='body1' style={{ color: grey800, width: '112px' }}>
                            Office days:
                        </Typography>
                        <Typography variant='body1'>
                            {workspace.officeTime.days}
                        </Typography>
                    </Box>
                    <Box mt={3} display="flex" flexWrap="wrap">
                        <Box display="flex" alignItems="center" className={classes.feature}>
                            <Icon width={20} height={20} iconName={'Wifi'} fill={grey1000} />
                            <Typography ml={1} variant="body1">Wifi</Typography>
                        </Box>
                        {workspace.socialspaceId === ('2' || '3') &&
                            <Box display="flex" alignItems="center" className={classes.feature}>
                                <Icon width={20} height={20} iconName={'Monitor'} fill={grey1000} />
                                <Typography ml={1} variant="body1">Monitor</Typography>
                            </Box>
                        }
                        {workspace.socialspaceId !== '4' &&
                            <>
                                <Box display="flex" alignItems="center" className={classes.feature}>
                                    <Icon width={20} height={20} iconName={'Printer'} fill={grey1000} />
                                    <Typography ml={1} variant="body1">Printer</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.feature}>
                                    <Icon width={20} height={20} iconName={'People'} fill={grey1000} />
                                    <Typography ml={1} variant="body1">Meeting room</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.feature}>
                                    <Icon width={20} height={20} iconName={'Coffee'} fill={grey1000} />
                                    <Typography ml={1} variant="body1">Coffee</Typography>
                                </Box>
                            </>
                        }
                        {workspace.socialspaceId === '4' &&
                            <>
                                <Box display="flex" alignItems="center" className={classes.feature}>
                                    <Icon width={20} height={20} iconName={'Coffee'} fill={grey1000} />
                                    <Typography ml={1} variant="body1">Shower</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.feature}>
                                    <Icon width={20} height={20} iconName={'Coffee'} fill={grey1000} />
                                    <Typography ml={1} variant="body1">Bike</Typography>
                                </Box>
                            </>
                        }
                        <Box display="flex" alignItems="center" className={classes.feature}>
                            <Icon width={20} height={20} iconName={'Coffee'} fill={grey1000} />
                            <Typography ml={1} variant="body1">Kitchen</Typography>
                        </Box>
                    </Box>
                </Box>


                {/* CONTACT */}
                <Box mx={3} py={5} display="flex" flexDirection="column" style={{ borderTop: `1px solid ${grey100}` }}>
                    <Typography variant='h4Mobile' style={{ color: green1000 }}>
                        Your host contact
                    </Typography>
                    <Box pt={3} display="flex">
                        <Box >
                            <div className={classes.contact_picture} title="ContactPicture"></div>
                        </Box>
                        <Box ml={3} display="flex" flexDirection="column" justifyContent="center">
                            <Typography variant='h6Mobile'>
                                {workspace.officeManager}
                            </Typography>
                            <Typography mt={0.5} variant='body2' style={{ color: grey600 }}>
                                {workspace.host}
                            </Typography>
                        </Box>
                    </Box>

                </Box>

                {/* HOST */}
                <Box mx={3} py={5} display="flex" flexDirection="column" style={{ borderTop: `1px solid ${grey100}` }}>
                    <Typography variant='h4Mobile' style={{ color: green1000 }}>
                        Your host
                    </Typography>
                    <Box py={3}>
                        <div className={classes.host_logo} title="HostLogo"></div>
                    </Box>
                    <Typography variant='h5Mobile'>
                        {hostDetail.name}
                    </Typography>
                    <Typography mt={2} mb={3} variant='body1' style={{ color: grey800 }}>
                        {hostDetail.shortDescription}
                    </Typography>
                    <Link to={`/host/${hostDetail.hostId}`} className={classes.general_link}>
                        <Box display="flex" alignItems="center">
                            <Typography mr={1} variant='h6Mobile' style={{ color: green1000 }}>
                                Find out more
                            </Typography>
                            <Box display="flex" alignItems="center" justifyContent="center" style={{ minWidth: '24px', minHeight: '24px' }}>
                                <Icon width={20} height={20} iconName="ArrowRight" fill={green1000} />
                            </Box>
                        </Box>
                    </Link>
                </Box>

                {/* Reviews */}
                {/* <Box mx={3} py={5} display="flex" flexDirection="column" style={{ borderTop: `1px solid ${grey100}` }}>
                    <Typography variant='h4Mobile' style={{ color: green1000 }}>
                        Reviews -
                    </Typography>
                </Box> */}

                {/* FOOTER */}
                <Box px={3} pt={4} display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: green1000 }}>
                    <Box pb={3.5} display="flex" flexDirection="column">
                        <Link to={`/termsofuse`} className={classes.link}>
                            <Typography variant="body3" className={classes.footer}>
                                {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('terms')][language]}
                            </Typography>
                        </Link>
                        <Link to={`/imprint`} className={classes.link}>
                            <Typography variant="body3" className={classes.footer}>
                                {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('imprint')][language]}
                            </Typography>
                        </Link>
                        <Link to={`/privacypolicy`} className={classes.link}>
                            <Typography variant="body3" className={classes.footer}>
                                {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('privacy')][language]}
                            </Typography>
                        </Link>
                    </Box>
                </Box>

                {totalPrice > 0 &&
                    <BookBarMobile workspace={workspace} totalPrice={totalPrice} />
                }

            </Paper>
        </Grid >
    )
}
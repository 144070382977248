import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    join_box: {
        width: '328px',
        minHeight: '440px',
    },
    join_illustration: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.join_illustration_img})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '100px',
        width: 'auto',
    },
}));
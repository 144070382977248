import makeStyles from "@mui/styles/makeStyles";
import { green800 } from "../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    container: {
        minHeight: 'calc(100vh - 80px - 40px)',
    },
    headline: {
        fontFamily: 'Poppins600',
    },
    link: {
        color: green800,
        fontFamily: 'Poppins600',
        textDecoration: `underline ${green800}`,
        wordWrap: 'break-word',
    },
}));
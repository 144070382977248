import makeStyles from "@mui/styles/makeStyles";
import { green100, green1000, green800, grey50, grey800 } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    bullet_point: {
        width: '20px',
        flexShrink: 0,
        color: grey800,
    },
    paper: {
        minHeight: '100vh',
        backgroundColor: '#FFF',
    },
    partner_logo: {
        display: 'flex',
        backgroundPosition: 'left',
        backgroundImage: props => `url(${props.partnerImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '80px',
        width: 'auto',
    },
    workspace_container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginBottom: '40px',
    },
    image_list: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    host_box: {
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
    },
    feature: {
        backgroundColor: grey50,
        height: '40px',
        padding: '0 16px 0 12px',
        borderRadius: '8px',
        alignSelf: 'flex-start',
        marginRight: '12px',
        marginTop: '12px',
    },
    footer: {
        color: green100,
    },
    general_link: {
        textDecoration: 'none',
    },
    link: {
        textDecoration: 'none',
        paddingBottom: '4px',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    },
    link_extern: {
        color: green800,
        fontFamily: 'Poppins600',
        textDecoration: `underline ${green800}`,
        wordWrap: 'break-word',
    },
    phone_number: {
        fontFamily: 'Poppins600',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.03em',
        color: green1000,
        textDecoration: `underline ${green1000}`,
    },
    host_logo: {
        display: 'flex',
        backgroundPosition: 'left',
        backgroundImage: props => `url(${props.hostImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '64px',
        width: 'auto',
    },
    contact_picture: {
        display: 'flex',
        backgroundPosition: 'left',
        backgroundImage: props => `url(${props.contactImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '72px',
        width: '72px',
    },  
    logo: {
        fontFamily: 'Poppins600',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.03em',
        color: '#FFF',
    }, 
    day_month_box: {
        height: 'auto',
        boxSizing: 'border-box',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: props => props.hoverColor,
        },
    },
    socialspace_box: {
        width: '70vw',
        textDecoration: 'none',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        '&:hover': {
            boxShadow: '0px 5px 25px rgba(54, 79, 81, 0.3)',
        },
        margin: '24px 24px 24px 0',
        height: props => props.heightWorkspaceBox,
    },
}));
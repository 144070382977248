import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './ContactBox.styles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper';
import { grey600 } from '../../other/helper/MuiStyles';

export default function ContactBox({ host, officeManager }) {

    const styleProps = {
        contactImg: IMAGE_MAP.GENERAL[officeManager]
    }

    const classes = useStyles(styleProps);

    return (
        <Box display="flex" flexDirection="column" className={classes.contact_box}>
            <div className={classes.contact_photo} title="PhillipDuerner"></div>
            <Box pt={3} display="flex" flexDirection="column" justifyContent="center">
                <Typography variant='h6'>
                    {officeManager}
                </Typography>
                <Typography pt={0.5} variant='body2' style={{ color: grey600 }}>
                    {host}
                </Typography>
            </Box>
        </Box>
    )
}
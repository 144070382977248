import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, ImageList, ImageListItem, IconButton } from '@mui/material';
import { useStyles } from './HomepageMobile.styles';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../components/other/contexts/AppContext';
import { IMAGE_MAP } from '../../../components/other/helper/ImgMapper';
import { green100, green1000, green200, green50, green600, green800, grey800 } from '../../../components/other/helper/MuiStyles';
import { HOMEPAGE_WORKSPACES, HOSTS } from '../../../data/data';
import Icon from '../../../assets/icons/Icon';
import HostBoxMobile from '../../../components/HostBox/mobile/HostBoxMobile';
import WorkspaceBoxMobile from '../../../components/WorkspaceBox/Homepage/mobile/WorkspaceBoxMobile';
import JoinHostBoxMobile from '../../../components/JoinHostBox/mobile/JoinHostBoxMobile';
import { HOMEPAGE_MOBILE } from '../../../data/translation/TranslationHomepageMobile';
import MobileMenu from '../../../components/other/dialogs/MobileMenu/MobileMenu';
import EmailRegistration from '../../../components/other/dialogs/EmailRegistration/EmailRegistration';
// import LanguageSwitch from '../../../components/other/dialogs/LanguageSwitch/LanguageSwitch';

export default function HomepageMobile() {

    const { language } = useContext(AppContext);
    const refWorkspaces = useRef(null);
    const refHosts = useRef(null);

    const [showStep1, setShowStep1] = useState(false);
    const [showStep2, setShowStep2] = useState(false);
    const [showStep3, setShowStep3] = useState(false);

    const styleProps = {
        backgroundImgHeader: IMAGE_MAP.GENERAL['Homepage_Picture_Mobile'],
        backgroundImgMission: IMAGE_MAP.GENERAL['Mission'],
        heightWorkspaceBox: `calc(${refWorkspaces.current?.offsetHeight}px - 48px)`,
        heightHostBox: `calc(${refHosts.current?.offsetHeight}px - 48px)`,
    };

    const classes = useStyles(styleProps);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <>
            {/* HEADER */}
            <Box display="flex" className={classes.background_image_header}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" flexGrow={1} className={classes.image_content_container}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.app_header}>
                        <Box pl={3} display="flex" alignItems="center">
                            <Link to={`/`} style={{ textDecoration: 'none' }}>
                                <Typography className={classes.logo_header}>socialspace</Typography>
                            </Link>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box pr={3} display="flex" alignItems="center">
                                <MobileMenu entry={'special'} />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center" className={classes.text_box}>
                        <Typography mx={'10vw'} variant="h2Mobile" className={classes.headline}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('caption_1')][language]}
                        </Typography>

                        <Box mt={'30vh'} mb={'17.5vh'} display="flex" width="calc(100vw - 48px)">
                            <Button
                                sx={{
                                    height: '48px',
                                    padding: '12px 24px !important',
                                    width: '100% !important',
                                }}
                                component={Link}
                                to={`/workspaces`}
                                variant="contained"
                                color="primary">
                                {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('header_button')][language]}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* SOCIALSPACE - CLIENT */}
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Box display="flex" alignItems="center" justifyContent="center" style={{ margin: '64px 24px 24px 24px' }}>
                    <Typography variant='h3Mobile'>
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('caption_section1')][language]}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" style={{ margin: '0 24px 24px 24px' }}>
                    <Typography variant='body1' style={{ color: grey800 }}>
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('text_section1')][language]}
                    </Typography>
                </Box>
            </Box>

            {/* WORKSPACES */}
            <div className={classes.workspace_container}>
                <ImageList ref={refWorkspaces} className={classes.image_list} cols={HOMEPAGE_WORKSPACES.length + 1}>
                    {HOMEPAGE_WORKSPACES
                    .filter(option => ['1', '4', '5'].includes(option.socialspaceId))
                    .sort((a, b) => ['4', '1', '5'].indexOf(a.socialspaceId) - ['4', '1', '5'].indexOf(b.socialspaceId))
                    .map((option, index) => (
                            <ImageListItem key={option.socialspaceId}>
                                <Link to={`/workspace/${option.socialspaceId}`} key={option.socialspaceId} className={classes.socialspace_box} style={{ marginLeft: index === 0 ? '24px' : '' }}>
                                    <WorkspaceBoxMobile key={option.socialspaceId} socialspace={option} />
                                </Link>
                            </ImageListItem>
                        ))
                    }
                    <Box className={classes.partner_box}>
                        <EmailRegistration entry={'mobile-homepage'} />
                    </Box>
                </ImageList>
            </div>

            {/* WHY SOCIAL SPACE */}
            <Box pt={8} px={'24px'} display="flex" flexDirection="column" style={{ backgroundColor: green50 }}>
                <Typography variant='h3Mobile'>
                    {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('caption_section2')][language]}
                </Typography>
                <Typography mt={'24px'} variant='body1' style={{ color: grey800 }}>
                    {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('text_section2')][language]}
                </Typography>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box mt={6} display="flex" flexDirection="column" alignItems="center">
                        <Icon width={64} height={64} iconName="Lovely" fill={green1000} />
                        <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('title_benefit1_section2')][language]}
                        </Typography>
                    </Box>
                    <Box mt={6} display="flex" flexDirection="column" alignItems="center">
                        <Icon width={64} height={64} iconName="Blur" fill={green1000} />
                        <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('title_benefit2_section2')][language]}
                        </Typography>
                    </Box>
                    <Box mt={6} mb={8} display="flex" flexDirection="column" alignItems="center">
                        <Icon width={64} height={64} iconName="Award" fill={green1000} />
                        <Typography mt={1} variant='h5Mobile' style={{ color: green1000, textAlign: 'center' }}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('title_benefit3_section2')][language]}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* BOOK */}
            <Box display="flex" style={{ backgroundColor: green50 }}>
                <Box py={8} mx={'24px'} display="flex" flexDirection="column" style={{ borderTop: `2px solid ${green200}` }}>
                    <Typography variant='h3Mobile'>
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('caption_section3')][language]}
                    </Typography>
                    <Typography mt={'24px'} variant='body1' style={{ color: grey800 }}>
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('text_section3')][language]}
                    </Typography>
                    <Box mt={5} display="flex" flexDirection="column" style={{ borderTop: `1px solid ${green100}` }}>
                        <Box py={3} display="flex" flexDirection="column" style={{ borderBottom: `1px solid ${green100}` }}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => { setShowStep1(!showStep1) }}>
                                <Box display="flex">
                                    <Typography variant='h5Mobile' style={{ width: '24px', color: green600 }}>
                                        01
                                    </Typography>
                                    <Typography pl={1.5} variant='h5Mobile' style={{ color: green1000, marginRight: '16px' }}>
                                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('title_step1_section3')][language]}
                                    </Typography>
                                </Box>
                                <Box display="flex" style={{ transform: showStep1 ? '' : 'rotate(-180deg)', transition: '.6s ease' }}>
                                    <Icon width={24} height={24} iconName="ArrowUp" fill={green1000} />
                                </Box>
                            </Box>
                            {showStep1 &&
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('text_step1_section3')][language]}
                                </Typography>
                            }
                        </Box>
                        <Box py={3} display="flex" flexDirection="column" style={{ borderBottom: `1px solid ${green100}` }}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => { setShowStep2(!showStep2) }}>
                                <Box display="flex">
                                    <Typography variant='h5Mobile' style={{ width: '24px', color: green600 }}>
                                        02
                                    </Typography>
                                    <Typography pl={1.5} variant='h5Mobile' style={{ color: green1000, marginRight: '16px' }}>
                                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('title_step2_section3')][language]}
                                    </Typography>
                                </Box>
                                <Box display="flex" style={{ transform: showStep2 ? '' : 'rotate(-180deg)', transition: '.6s ease' }}>
                                    <Icon width={24} height={24} iconName="ArrowUp" fill={green1000} />
                                </Box>
                            </Box>
                            {showStep2 &&
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('text_step2_section3')][language]}
                                </Typography>
                            }
                        </Box>
                        <Box py={3} display="flex" flexDirection="column" style={{ borderBottom: `1px solid ${green100}` }}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" onClick={() => { setShowStep3(!showStep3) }}>
                                <Box display="flex">
                                    <Typography variant='h5Mobile' style={{ width: '24px', color: green600 }}>
                                        03
                                    </Typography>
                                    <Typography pl={1.5} variant='h5Mobile' style={{ color: green1000, marginRight: '16px' }}>
                                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('title_step3_section3')][language]}
                                    </Typography>
                                </Box>
                                <Box display="flex" style={{ transform: showStep3 ? '' : 'rotate(-180deg)', transition: '.6s ease' }}>
                                    <Icon width={24} height={24} iconName="ArrowUp" fill={green1000} />
                                </Box>
                            </Box>
                            {showStep3 &&
                                <Typography mt={2} ml={4.5} pr={2} variant='body1' style={{ color: grey800 }}>
                                    {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('text_step3_section3')][language]}
                                </Typography>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box >


            {/* VISION */}
            <Box pt={8} px={'24px'} display="flex" flexDirection="column">
                <Typography mb={5} variant='h3Mobile' style={{ color: green1000 }}>
                    {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('vision_header')][language]}
                </Typography>
                <div className={classes.mission_illustration} title="MissionIllustration"></div>
                <Typography mt={5} variant='body1' style={{ color: grey800 }}>
                    {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('vision_text')][language]}
                </Typography>
                <Box pt={4} pb={8} display="flex" width="calc(100vw - 48px)">
                    <Button
                        sx={{
                            height: '48px',
                            padding: '12px 24px !important',
                            width: '100% !important',
                        }}
                        component={Link}
                        to={`/aboutus`}
                        variant="contained"
                        color="primary"
                    >
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('vision_button')][language]}
                    </Button>
                </Box>
            </Box>

            {/* STATS */}
            <Box py={8} px={'24px'} display="flex" flexDirection="column" justifyContent="space-between" style={{ backgroundColor: green1000 }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography className={classes.highlight_number}>
                        2
                    </Typography>
                    <Typography variant="body1" style={{ color: green100, textAlign: 'center' }}>
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('stats2')][language]}
                    </Typography>
                </Box>
                <Box mt={5} display="flex" flexDirection="column" alignItems="center">
                    <Typography className={classes.highlight_number}>
                        3
                    </Typography>
                    <Typography variant="body1" style={{ color: green100, textAlign: 'center' }}>
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('stats3')][language]}
                    </Typography>
                </Box>
            </Box>

            {/* SOCIALSPACE - CLIENT */}
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box display="flex" alignItems="center" justifyContent="center" style={{ margin: '64px 24px 24px 24px' }}>
                    <p className={classes.headline_caption}>
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('caption1_section4')][language]}<br />
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('caption2_section4')][language]}
                    </p>
                </Box>
                <Box display="flex" flexDirection="column" style={{ margin: '0 24px 24px 24px' }}>
                    <Typography variant='body1' style={{ color: grey800 }}>
                        {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('text_section4')][language]}
                    </Typography>
                </Box>
            </Box>

            {/* HOSTS */}
            <div className={classes.hosts_container}>
                <ImageList ref={refHosts} className={classes.image_list} cols={HOSTS.length + 1}>
                    {HOSTS?.map((option, index) => {
                        return (
                            <ImageListItem key={option.hostId}>
                                <Link to={`/host/${option.hostId}`} key={option.hostId} className={classes.host_box} style={{ marginLeft: index === 0 ? '24px' : '' }}>
                                    <HostBoxMobile host={option} />
                                </Link>
                            </ImageListItem>
                        )
                    })}
                    <Link to={`/becomehost`} className={classes.host_box}>
                        <JoinHostBoxMobile />
                    </Link>
                </ImageList>
            </div>


            {/* FOOTER */}
            <Box px={3} pt={8} display="flex" flexDirection="column" justifyContent="center" style={{ backgroundColor: green1000 }}>
                <Typography variant='body2' style={{ color: '#FFF' }}>
                    {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('footer_text')][language]}
                </Typography>
                <Box my={4} display="flex" style={{ backgroundColor: green800, height: '1px', width: '-webkit-fill-available' }}></Box>
                <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography className={classes.logo}>socialspace</Typography>
                    <Box display="flex" alignItems="center" height='20px'>
                        <IconButton
                            component="a"
                            href="https://instagram.com/socialspacework?igshid=YmMyMTA2M2Y="
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="Instagram" />
                        </IconButton>
                        <IconButton
                            component="a"
                            href="https://twitter.com/socialspacework?s=11&t=U-yVjKdFPsf1bZ9fImbBwA"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="Twitter" />
                        </IconButton>
                        <IconButton
                            component="a"
                            href="https://www.linkedin.com/company/socialspacework/"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="secondary"
                            className={classes.icon_button_tertiary}
                        >
                            <Icon width={18} height={18} iconName="LinkedIn" />
                        </IconButton>
                    </Box>
                </Box>
                <Box pb={3.5} display="flex" flexDirection="column">
                    <Link to={`/termsofuse`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('terms')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/imprint`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('imprint')][language]}
                        </Typography>
                    </Link>
                    <Link to={`/privacypolicy`} className={classes.link}>
                        <Typography variant="body3" className={classes.footer}>
                            {HOMEPAGE_MOBILE[HOMEPAGE_MOBILE.map(object => object.section).indexOf('privacy')][language]}
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </>
    )
}
import { makeStyles } from '@mui/styles';
import { green800, grey50, grey800 } from '../../other/helper/MuiStyles';

export const useStyles = makeStyles(() => ({
    area_of_action: {
        backgroundColor: grey50,
        height: '40px',
        padding: '0 16px',
        borderRadius: '8px',
    },
    bullet_point: {
        width: '20px',
        flexShrink: 0,
        color: grey800,
    },
    host_box: {
        width: '400px',   
        },
        link_extern: {
            color: green800,
            fontFamily: 'Poppins600',
            textDecoration: `underline ${green800}`,
            wordWrap: 'break-word',
        },
    partner_logo: {
        display: 'flex',
        backgroundPosition: 'center',
        backgroundImage: props => `url(${props.partnerImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '100px',
        width: 'auto',
    },
    un_goal: {
        height: '120px',
        paddingRight: '24px',
        paddingBottom: '24px',
    },
}));
import React from 'react';
import { Box, Radio, Typography } from '@mui/material';
import { useStyles } from './WorkspaceTypeBoxMobile.styles';
import { green100, green1000, green50, grey200, grey800 } from '../../other/helper/MuiStyles';
import { IMAGE_MAP } from '../../other/helper/ImgMapper';

export default function WorkspaceTypeBoxMobile({ workspaceType, workspaceDescription, selectedWorkspaceType, fake, refWorkspaceType }) {

    const styleProps = {
        backgroundColorSelected: selectedWorkspaceType === workspaceType ? green100 : '',
        hoverColor: fake ? '' : selectedWorkspaceType === workspaceType ? '' : green50,
        cursor: fake ? '' : 'pointer',
        imgWorkspace: IMAGE_MAP.ILLUSTRATIONS[workspaceType],
        borderSelected: selectedWorkspaceType === workspaceType ? `2px solid ${green1000}` : `1px solid ${grey200}`,
        maxHeightBox: refWorkspaceType ? `${refWorkspaceType.current?.offsetHeight}px` : 'auto',
    };

    const classes = useStyles(styleProps);

    return (
        <Box mb={2} display="flex" alignItems="center" className={classes.workspace_box} >
            <Radio
                disableRipple
                checked={selectedWorkspaceType === workspaceType}
                value="selectedWorkspaceType"
            />
            <Box mx={2} display="flex" flexDirection="column">
                <Typography variant='h5Mobile'>
                    {workspaceType}
                </Typography>
                <Typography pt={1} variant='body2' style={{ color: grey800 }}>
                    {workspaceDescription}
                </Typography>
            </Box>
        </Box>
    )
}
import makeStyles from "@mui/styles/makeStyles";
import { green100 } from "../../../components/other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    footer: {
        color: green100,
    },
    icon_button_tertiary: {
        padding: '0 8px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0)',
        },
    },  
    link: {
        textDecoration: 'none',
        paddingBottom: '4px',
        '&:hover': {
            textDecoration: `underline ${green100}`,
        },
    }, 
    logo: {
        fontFamily: 'Poppins600',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.03em',
        color: '#FFF',
    }, 
    socialspace_box: {
        width: 'calc(100vw - 48px)',
        textDecoration: 'none',
        backgroundColor: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        '&:hover': {
            boxShadow: '0px 5px 25px rgba(54, 79, 81, 0.3)',
        },
        marginTop: '12px',
        marginBottom: '12px',
    },
}));
export const HEADER = [
    {
        section: 'workspaces',
        eng: 'Workspaces',
        ger: 'Arbeitsplätze',
    },
    {
        section: 'hosts',
        eng: 'Hosts',
        ger: 'Gastgeber',
    },
    {
        section: 'about',
        eng: 'About us',
        ger: 'Über uns',
    },
    {
        section: 'become',
        eng: 'Become a host',
        ger: 'Gastgeber werden',
    },
];
import makeStyles from "@mui/styles/makeStyles";
import { grey600 } from "../../other/helper/MuiStyles";

export const useStyles = makeStyles(() => ({
    calendar_box: {
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        padding: '24px',
    },
    day_box: {
        width: '80px', 
        height: '80px',
        textAlign: 'center',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
    },
    day_name_box: {
        color: grey600, 
        width: '80px', 
        textAlign: 'center',
        marginBottom: '24px'
    },
    month_box: {
        minWidth: '100px',
        width: '6vw',
        minHeight: '200px',
        borderRadius: '8px',
        boxShadow: '0px 5px 15px rgba(54, 79, 81, 0.2)',
        padding: '16px',
    },
}));